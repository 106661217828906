import { contact, PRODUCT_NAME } from '@/constants';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('products', ['isHousingLoanProduct']),
    ...mapGetters('processStep', ['productName']),

    contactEmail() {
      return (this.isHousingLoanProduct || this.productName === PRODUCT_NAME.HOUSING_LOAN)
        ? contact.emailHL
        : contact.email;
    },
  },
};
