<template>
  <bb-offer-thank-you-page
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="submit"
  >
    <template slot="header">
      <bb-hero
        :title="$t('views.submitBankAccountNumber.title')"
        class="w-100"
        bleed="all"
      >
        <steps-overview />
      </bb-hero>
    </template>
    <iban-input v-model="bankAccountNumber" />
  </bb-offer-thank-you-page>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { BbHero, BbOfferThankYouPage } from '@bigbank/interface-components';
  import IbanInput from '@/components/IbanInput';
  import StepsOverview from '@/components/StepsOverview';

  export default {
    name: 'submit-bank-account-number',

    components: {
      BbHero,
      BbOfferThankYouPage,
      IbanInput,
      StepsOverview,
    },

    data() {
      return { bankAccountNumber: this.$store.getters['processStep/submitBankAccountNumberData'].bankAccountNumber };
    },

    computed: { ...mapGetters('processStep', ['isLoading']) },

    methods: {
      ...mapActions('processStep', ['updateProcessStep']),

      submit() {
        this.updateProcessStep({ application: { applicant: { bankAccountNumber: this.bankAccountNumber.replace(/ /g, '') }}});
      },
    },
  };
</script>
