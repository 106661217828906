import { Rules } from 'vee-validate';

const GMAIL_DOMAIN_NAME = 'gmail';
const ALLOWED_TOP_LEVEL_DOMAIN = 'com';

const isDomainPartWithoutTypos = email => {
  const domainPart = email.split('@').slice(-1)[0].split('.');
  const domainName = domainPart[0];

  if (domainName === GMAIL_DOMAIN_NAME) {
    if (domainPart.length !== 2) {
      return false;
    }

    const topLevelDomain = domainPart[1];

    return topLevelDomain === ALLOWED_TOP_LEVEL_DOMAIN;
  }

  return true;
};

/** RegExp rule used deliberately to filter out non-latin characters (allowed by RFC)
 *  to satisfy DWH email requirements, which are stricter than RFC 5322 standard.
 *
 *  NOTE: Don't try to cut into multiple lines, it will break the RegExp
 *  - time needed to escape backlashes (and " vs. ”) is not the effort.
 **/
/* eslint-disable-next-line */
const DWH_EMAIL_REGEXP = /^(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"((?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])|\\")*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/;

export default {
  validate: value =>
    Rules.email.validate(value) &&
    isDomainPartWithoutTypos(value) &&
    DWH_EMAIL_REGEXP.test(value),
};
