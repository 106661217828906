<template>
  <bb-calculator-modal
    visible
    :amount-step="50"
    :value="calculatorValues"
    :list-items="calculatorValues.listItems"
    :heading="$t('components.calculator.heading')"
    :submit-label="$t('components.calculator.submit')"
    @close="$emit('close')"
    @submit="submit"
    @input="onChange"
  >
    <p slot="disclaimer">
      {{ $t('components.calculator.disclaimer', disclaimerValues) }}
    </p>
  </bb-calculator-modal>
</template>

<script>
  import { BbCalculatorModal } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';
  import { DEFAULT_MONTHLY_PAYMENT_DAY_UNTIL_PROVIDED } from '@/constants';

  export default {
    name: 'calculator',
    components: { BbCalculatorModal },
    data() {
      return {
        calculatorData: {
          amount: null,
          period: null,
          interestRate: null,
        },
        disclaimerValues: this.getDisclaimerValues({
          amount: 10000,
          period: 120,
          interestRate: 6.6,
          administrationFee: 9.5,
          conclusionFeeRate: 7,
          monthlyPayment: 129.39,
          aprc: 9.92,
          totalRepayableAmount: 15526.89,
        }),
      };
    },
    computed: {
      ...mapGetters('products', [
        'getProductData',
        'isLoading',
        'productType',
      ]),
      ...mapGetters('loan', [
        'loanPaymentDetails',
        'loanLoading',
      ]),
      monthlyPayment() {
        return this.loanPaymentDetails[0]?.monthlyPayment;
      },
      calculatorValues() {
        return {
          name: 'calculator',
          id: 'header-calculator',
          loader: this.isLoading || this.loanLoading,
          amountStep: 50,
          periodStep: 1,
          payment: this.monthlyPayment,
          locale: this.$i18n.locale,
          ...this.getProductData,
        };
      },
    },
    watch: {
      loanPaymentDetails(newValue) {
        const requiredValues = {
          amount: this.calculatorData.amount,
          period: this.calculatorData.period,
          interestRate: this.getProductData.interestRate,
          administrationFee: this.getProductData.maintenanceFee,
          conclusionFeeRate: this.getProductData.contractConclusionFeeRate,
          monthlyPayment: newValue[0]?.monthlyPayment,
          aprc: newValue[0]?.apr,
          totalRepayableAmount: newValue[0]?.totalRepayableAmount,
        };

        if (Object.values(requiredValues).some(val => typeof val !== 'number')) return;

        this.disclaimerValues = this.getDisclaimerValues(requiredValues);
      },
    },
    created() {
      this.onChange(this.getProductData);
    },
    methods: {
      ...mapActions('products', [
        'setProductData',
        'getProducts',
      ]),
      ...mapActions('loan', ['debouncedCalculateLoanPaymentDetails']),
      calculateLoanDetails() {
        const conclusionFee = this.calculatorData.amount * (this.getProductData.contractConclusionFeeRate / 100);

        const body = {
          maturity: this.calculatorData.period,
          productType: this.productType,
          amount: this.calculatorData.amount,
          monthlyPaymentDay: DEFAULT_MONTHLY_PAYMENT_DAY_UNTIL_PROVIDED,
          interestRate: this.calculatorData.interestRate,
          conclusionFee,
          administrationFee: this.getProductData.maintenanceFee,
        };

        if (body.amount !== 0 && body.maturity !== 0) {
          this.debouncedCalculateLoanPaymentDetails(body);
        }
      },
      getDisclaimerValues({ amount, period, interestRate, administrationFee, conclusionFeeRate, monthlyPayment, aprc, totalRepayableAmount }) {
        return {
          amount: this.$n(amount, 'currency'),
          period: this.$n(period),
          interestRate: this.$n(interestRate / 100, 'percent'),
          administrationFee: this.$n(administrationFee, 'currency-decimal'),
          conclusionFeeRate: this.$n(conclusionFeeRate / 100, 'percent'),
          monthlyPayment: this.$n(monthlyPayment, 'currency-decimal'),
          aprc: this.$n(aprc / 100, 'percent'),
          totalRepayableAmount: this.$n(totalRepayableAmount, 'currency-decimal'),
        };
      },
      onChange(calculatorData) {
        this.calculatorData = calculatorData;
        this.calculateLoanDetails();
      },
      submit() {
        this.setProductData(this.calculatorData);
        this.$emit('close');
      },
    },
  };
</script>
