import { SOURCE,
  SEGMENT,
  INTEREST_TYPE,
  LOAN_PURPOSE,
  LOAN_PURPOSES_FOR_RENOVATION,
  LOAN_PURPOSES_FOR_REAL_ESTATE,
  LOAN_PURPOSES_FOR_HOUSING_LOAN,
  PRODUCT_NAME,
  PRODUCT_REGISTRY,
  PRODUCT_TYPE } from '@/constants';

export const APP_MAP_CAR_LOAN = 'APP_MAP_CAR_LOAN';
export const APP_MAP_CAR_LOAN_MAX_PERIOD = 'APP_MAP_CAR_LOAN_MAX_PERIOD';
export const APP_MAP_CAR_LOAN_SPECIAL_OFFER = 'APP_MAP_CAR_LOAN_SPECIAL_OFFER';
export const APP_MAP_RENOVATION_LOAN = 'APP_MAP_RENOVATION_LOAN';
export const APP_MAP_REAL_ESTATE_LOAN = 'APP_MAP_REAL_ESTATE_LOAN';
export const APP_MAP_SMALL_LOAN = 'APP_MAP_SMALL_LOAN';
export const APP_MAP_MEDICAL_LOAN = 'APP_MAP_MEDICAL_LOAN';
export const APP_MAP_SMALL_LOAN_PREMIUM = 'APP_MAP_SMALL_LOAN_PREMIUM';
export const APP_MAP_RENOVATION_LOAN_PREMIUM = 'APP_MAP_RENOVATION_LOAN_PREMIUM';
export const APP_MAP_REFINANCING_LOAN = 'APP_MAP_REFINANCING_LOAN';
export const APP_MAP_REFINANCING_LOAN_PREMIUM = 'APP_MAP_REFINANCING_LOAN_PREMIUM';
export const APP_MAP_REG_HIRE_PURCHASE = 'APP_MAP_REG_HIRE_PURCHASE';
export const APP_MAP_CAR_HIRE_PURCHASE = 'APP_MAP_CAR_HIRE_PURCHASE';
export const APP_MAP_COMP_HIRE_PURCHASE = 'APP_MAP_COMP_HIRE_PURCHASE';
export const APP_MAP_CAR_FINANCE_LEASING = 'APP_MAP_CAR_FINANCE_LEASING';
export const APP_MAP_HOUSING_LOAN = 'APP_MAP_HOUSING_LOAN';

class ApplicationMapping {
  constructor (id, productName, segmentName, loanPurposes, calculatorProductName, productType) {
    this.id = id;
    this.productName = productName;
    this.segmentName = segmentName;
    this.loanPurposes = loanPurposes;
    this.calculatorProductName = calculatorProductName;
    this.productType = productType;
  }
}

export const APP_MAPS = {
  [APP_MAP_CAR_LOAN]: new ApplicationMapping(APP_MAP_CAR_LOAN, PRODUCT_NAME.CAR_LOAN, null, [LOAN_PURPOSE.PURCHASING_MOTOR_VEHICLES], PRODUCT_REGISTRY.CAR_LOAN, PRODUCT_TYPE.CAR_LOAN_LT01),
  [APP_MAP_CAR_LOAN_MAX_PERIOD]: new ApplicationMapping(APP_MAP_CAR_LOAN_MAX_PERIOD, PRODUCT_NAME.CAR_LOAN, null, [LOAN_PURPOSE.PURCHASING_MOTOR_VEHICLES], PRODUCT_REGISTRY.CAR_LOAN, PRODUCT_TYPE.CAR_LOAN_LT01),
  [APP_MAP_CAR_LOAN_SPECIAL_OFFER]: new ApplicationMapping(APP_MAP_CAR_LOAN_SPECIAL_OFFER, PRODUCT_NAME.CAR_LOAN, SEGMENT.PREMIUM, [LOAN_PURPOSE.PURCHASING_MOTOR_VEHICLES], PRODUCT_REGISTRY.CAR_LOAN, PRODUCT_TYPE.CAR_LOAN_LT01),
  [APP_MAP_RENOVATION_LOAN]:
    new ApplicationMapping(
      APP_MAP_RENOVATION_LOAN,
      PRODUCT_NAME.RENOVATION_LOAN,
      null,
      LOAN_PURPOSES_FOR_RENOVATION,
      PRODUCT_REGISTRY.RENOVATION_LOAN,
      PRODUCT_TYPE.RENOVATION_LOAN_LT01,
    ),
  [APP_MAP_RENOVATION_LOAN_PREMIUM]:
    new ApplicationMapping(
      APP_MAP_RENOVATION_LOAN_PREMIUM,
      PRODUCT_NAME.RENOVATION_LOAN,
      SEGMENT.PREMIUM,
      LOAN_PURPOSES_FOR_RENOVATION,
      PRODUCT_REGISTRY.RENOVATION_LOAN,
      PRODUCT_TYPE.RENOVATION_LOAN_LT01,
    ),
  [APP_MAP_REAL_ESTATE_LOAN]: new ApplicationMapping(APP_MAP_REAL_ESTATE_LOAN, PRODUCT_NAME.RENOVATION_LOAN, null, LOAN_PURPOSES_FOR_REAL_ESTATE, PRODUCT_REGISTRY.RENOVATION_LOAN, PRODUCT_TYPE.RENOVATION_LOAN_LT01),
  [APP_MAP_SMALL_LOAN]: new ApplicationMapping(APP_MAP_SMALL_LOAN, PRODUCT_NAME.SMALL_LOAN, null, [LOAN_PURPOSE.DAILY_SETTLEMENTS], PRODUCT_REGISTRY.SMALL_LOAN, PRODUCT_TYPE.SMALL_LOAN_LT01),
  [APP_MAP_SMALL_LOAN_PREMIUM]: new ApplicationMapping(APP_MAP_SMALL_LOAN_PREMIUM, PRODUCT_NAME.SMALL_LOAN, SEGMENT.PREMIUM, [LOAN_PURPOSE.DAILY_SETTLEMENTS], PRODUCT_REGISTRY.SMALL_LOAN, PRODUCT_TYPE.SMALL_LOAN_LT01),
  [APP_MAP_MEDICAL_LOAN]: new ApplicationMapping(APP_MAP_MEDICAL_LOAN, PRODUCT_NAME.MEDICAL_LOAN, null, [LOAN_PURPOSE.MEDICAL_SERVICES], PRODUCT_REGISTRY.MEDICAL_LOAN, PRODUCT_TYPE.MEDICAL_LOAN_LT01),
  [APP_MAP_REFINANCING_LOAN]: new ApplicationMapping(APP_MAP_REFINANCING_LOAN, PRODUCT_NAME.REFINANCING_LOAN, null, [LOAN_PURPOSE.COMBINING_LOANS], PRODUCT_REGISTRY.REFINANCING_LOAN, PRODUCT_TYPE.REFINANCING_LOAN_LT01),
  [APP_MAP_REFINANCING_LOAN_PREMIUM]: new ApplicationMapping(APP_MAP_REFINANCING_LOAN_PREMIUM, PRODUCT_NAME.REFINANCING_LOAN, SEGMENT.PREMIUM, [LOAN_PURPOSE.COMBINING_LOANS], PRODUCT_REGISTRY.REFINANCING_LOAN, PRODUCT_TYPE.REFINANCING_LOAN_LT01),
  [APP_MAP_REG_HIRE_PURCHASE]: new ApplicationMapping(APP_MAP_REG_HIRE_PURCHASE, PRODUCT_NAME.REG_HIRE_PURCHASE, null, [LOAN_PURPOSE.DAILY_SETTLEMENTS], PRODUCT_REGISTRY.REGULAR_HIRE_PURCHASE, PRODUCT_TYPE.REG_HIRE_PURCHASE_LT01),
  [APP_MAP_CAR_HIRE_PURCHASE]: new ApplicationMapping(APP_MAP_CAR_HIRE_PURCHASE, PRODUCT_NAME.CAR_HIRE_PURCHASE, null, [LOAN_PURPOSE.PURCHASING_MOTOR_VEHICLES], PRODUCT_REGISTRY.CAR_HIRE_PURCHASE, PRODUCT_TYPE.CAR_HIRE_PURCHASE_LT01),
  [APP_MAP_COMP_HIRE_PURCHASE]: new ApplicationMapping(APP_MAP_COMP_HIRE_PURCHASE, PRODUCT_NAME.COMP_HIRE_PURCHASE, null, [LOAN_PURPOSE.DAILY_SETTLEMENTS], PRODUCT_REGISTRY.PARTNERS_COMPENSATED_HIRE_PURCHASE, PRODUCT_TYPE.COMP_HIRE_PURCHASE_LT01),
  [APP_MAP_CAR_FINANCE_LEASING]: new ApplicationMapping(APP_MAP_CAR_FINANCE_LEASING, PRODUCT_NAME.CAR_FINANCE_LEASE, null, [LOAN_PURPOSE.PURCHASING_MOTOR_VEHICLES], PRODUCT_REGISTRY.CAR_FINANCE_LEASE, PRODUCT_TYPE.CAR_LEASE_LT04),
  [APP_MAP_HOUSING_LOAN]:
    new ApplicationMapping(
      APP_MAP_HOUSING_LOAN,
      PRODUCT_NAME.HOUSING_LOAN,
      null,
      LOAN_PURPOSES_FOR_HOUSING_LOAN,
      PRODUCT_REGISTRY.HOUSING_LOAN,
      PRODUCT_TYPE.HOUSING_LOAN_LT01,
    ),
};

export const DEFAULT_APP_MAP = new ApplicationMapping(APP_MAP_SMALL_LOAN, PRODUCT_NAME.SMALL_LOAN, null, [LOAN_PURPOSE.DAILY_SETTLEMENTS], PRODUCT_REGISTRY.SMALL_LOAN, PRODUCT_TYPE.SMALL_LOAN_LT01);

export const APP_MAP_BY_PRODUCT_URL_NAME = {
  'paskola-automobiliui': APP_MAPS[APP_MAP_CAR_LOAN],
  'specialus-pasiulymas-paskola-automobiliui': APP_MAPS[APP_MAP_CAR_LOAN_SPECIAL_OFFER],
  'paskola-remontui': APP_MAPS[APP_MAP_RENOVATION_LOAN],
  'mazesnes-palukanos-paskolai-namu-remontui':  APP_MAPS[APP_MAP_RENOVATION_LOAN_PREMIUM],
  'nt-paskola': APP_MAPS[APP_MAP_REAL_ESTATE_LOAN],
  'vartojimo-paskola': APP_MAPS[APP_MAP_SMALL_LOAN],
  'specialus-pasiulymas': APP_MAPS[APP_MAP_SMALL_LOAN_PREMIUM],
  'paskola-medicinos-paslaugoms': APP_MAPS[APP_MAP_MEDICAL_LOAN],
  'refinansavimas': APP_MAPS[APP_MAP_REFINANCING_LOAN],
  'refinansavimas-nuo-10-000': APP_MAPS[APP_MAP_REFINANCING_LOAN_PREMIUM],
  'pirkimas-issimoketinai': APP_MAPS[APP_MAP_REG_HIRE_PURCHASE],
  'paskola-automobiliui-su-ismokejimu-partneriui': APP_MAPS[APP_MAP_CAR_HIRE_PURCHASE],
  'pirkimas-issimoketinai-su-kompensacija': APP_MAPS[APP_MAP_COMP_HIRE_PURCHASE],
  'automobiliu-lizingas': APP_MAPS[APP_MAP_CAR_FINANCE_LEASING],
  'busto-paskola': APP_MAPS[APP_MAP_HOUSING_LOAN],
};

export const APP_MAP_BY_PRODUCT_URL_NAME_FOR_SOURCE_AUTOPLIUS = {
  'paskola-automobiliui': APP_MAPS[APP_MAP_CAR_LOAN_MAX_PERIOD],
  'specialus-pasiulymas-paskola-automobiliui': APP_MAPS[APP_MAP_CAR_LOAN_SPECIAL_OFFER],
};

export const APP_MAP_BY_PRODUCT_URL_NAME_FOR_SOURCE_DIGINET = {
  'vartojimo-paskola': APP_MAPS[APP_MAP_SMALL_LOAN],
  'paskola-remontui': APP_MAPS[APP_MAP_RENOVATION_LOAN],
  'mazesnes-palukanos-paskolai-namu-remontui':  APP_MAPS[APP_MAP_RENOVATION_LOAN_PREMIUM],
  'nt-paskola': APP_MAPS[APP_MAP_REAL_ESTATE_LOAN],
};

export const getApplicationMappingByRoute = ({ source, productName }) => {
  let mapping;

  switch (source) {
    case SOURCE.AUTOPLIUS:
      mapping = APP_MAP_BY_PRODUCT_URL_NAME_FOR_SOURCE_AUTOPLIUS[productName]
        ? APP_MAP_BY_PRODUCT_URL_NAME_FOR_SOURCE_AUTOPLIUS[productName]
        : APP_MAPS[APP_MAP_CAR_LOAN_MAX_PERIOD];
      break;

    case SOURCE.DIGINET:
      mapping = APP_MAP_BY_PRODUCT_URL_NAME_FOR_SOURCE_DIGINET[productName]
        ? APP_MAP_BY_PRODUCT_URL_NAME_FOR_SOURCE_DIGINET[productName]
        : APP_MAPS[APP_MAP_RENOVATION_LOAN];
      break;

    default:
      mapping = APP_MAP_BY_PRODUCT_URL_NAME[productName];
  }

  return mapping ? mapping : DEFAULT_APP_MAP;
};

export const getApplicationMappingByProduct = ({ productName, segmentName = null, loanPurpose }) => {
  const mapping = Object.values(APP_MAPS)
    .filter(appMap => appMap.productName === productName)
    .filter(appMap => SEGMENT[segmentName]
      ? appMap.segmentName === segmentName
      : appMap.segmentName === null)
    .filter(appMap => loanPurpose
      ? (Array.isArray(appMap.loanPurposes) ? appMap.loanPurposes.includes(loanPurpose) : appMap.loanPurposes === loanPurpose)
      : true);

  return mapping ? mapping[0] : DEFAULT_APP_MAP;
};

export const getAdjustedApplicationMappingIdByLoanPurpose = ({ applicationMappingId: currentApplicationMappingId, loanPurpose: loanPurpose }) => {
  switch (loanPurpose) {
    case LOAN_PURPOSE.RENOVATING_HOME:
      return currentApplicationMappingId === APP_MAP_RENOVATION_LOAN_PREMIUM
        ? APP_MAP_RENOVATION_LOAN_PREMIUM
        : APP_MAP_RENOVATION_LOAN;

    case LOAN_PURPOSE.PURCHASING_HOME:
    case LOAN_PURPOSE.PURCHASING_RENTAL_PROPERTY:
    case LOAN_PURPOSE.PURCHASING_OTHER_REAL_ESTATE:
    case LOAN_PURPOSE.BUILDING_HOME:
    case LOAN_PURPOSE.BUILDING_RENTAL_PROPERTY:
    case LOAN_PURPOSE.BUILDING_OTHER_REAL_ESTATE:
      return APP_MAP_REAL_ESTATE_LOAN;

    default:
      return currentApplicationMappingId;
  }
};

export const getOptionalProductValuesFromUrlByProductType = ({ productType, query: { amount, renovationCost, goodsPrice, assetCondition, interestType }}) => {
  switch (productType) {
    case PRODUCT_TYPE.CAR_LEASE_LT04:
      return {
        interestType,
        assetCondition,
        goodsPrice: Number(goodsPrice),
        selfFinancingAmount: goodsPrice - amount,
      };
    case PRODUCT_TYPE.HOUSING_LOAN_LT01:
      return {
        goodsPrice: Number(goodsPrice),
        selfFinancingAmount: (+goodsPrice) + (+renovationCost || 0) - (+amount),
        interestType: interestType || INTEREST_TYPE.EURIBOR_6_MONTHS,
      };

    default:
      return {};
  }
};
