import personalCodeValidator from './personalCodeValidator';
import coApplicantFullNameValidator from './CoApplicantFullNameValidator';
import PhoneNumberValidator, { phoneNumberRequiredValidator } from './PhoneNumberValidator';
import IBANValidator from './IBANValidator';
import amountValidator from './AmountValidator';
import EmailValidator from './EmailValidator';
import { default as CommonSymbolsValidator } from './CommonSymbolsValidator';
import LtLoanOriginationClient from '../../api/LtLoanOriginationClient';

export const name = {
  name: 'name',
  validator: {
    validate: (value) => {
      const rule = /^([a-zA-ZąčęėįšųūžĄČĘĖĮŠŲŪŽ]+)([\s-]?)([a-zA-ZąčęėįšųūžĄČĘĖĮŠŲŪŽ]+)?$/;

      return rule.exec(value) !== null;
    },
  },
};

export const alphaNum = {
  name: 'alphaNum',
  validator: { validate: value => /^[a-zA-ZąčęėįšųūžĄČĘĖĮŠŲŪŽ\d]*$/i.test(value) },
};

const campaignCodeCache = {};

export const campaignCode = {
  name: 'campaignCode',
  validator: {
    validate: async (value, params) => {
      const productName = params?.[0];

      if (!value || !productName) {
        return false;
      }

      if (campaignCodeCache[value] === undefined) {
        const response = await LtLoanOriginationClient.validateCampaignCode(value, productName);

        campaignCodeCache[value] = response?.data?.valid;
      }

      return campaignCodeCache[value];
    },
  },
};

export const email = {
  name: 'email',
  validator: EmailValidator,
};

export const postCode = {
  name: 'postCode',
  validator: {
    validate: (value) => {
      const rule = /^LT-\d{5}$/;

      return rule.exec(value) !== null;
    },
  },
};

export const personalCode = {
  name: 'personalCode',
  validator: personalCodeValidator,
};

export const phone = {
  name: 'phone',
  validator: PhoneNumberValidator,
};

export const phoneRequired = {
  name: 'phoneRequired',
  validator: phoneNumberRequiredValidator,
};

export const iban = {
  name: 'IBAN',
  validator: IBANValidator,
};

export const houseOrFlatNumber = {
  name: 'houseOrFlatNumber',
  validator: { validate: value => /^[\da-zA-Z][\da-zA-Z.,-/]*$/.test(value) },
};

export const uploadedFilesAreValid = {
  name: 'uploadedFilesAreValid',
  validator: { validate: files => !files.some(file => file.errorFileName) },
};

export const doesNotMatchCoApplicant = {
  name: 'doesNotMatchCoApplicant',
  validator: coApplicantFullNameValidator,
};

export const commonSymbols = {
  name: 'commonSymbols',
  validator: CommonSymbolsValidator,
};

const rules = [
  name,
  alphaNum,
  email,
  campaignCode,
  personalCode,
  phone,
  phoneRequired,
  iban,
  postCode,
  houseOrFlatNumber,
  uploadedFilesAreValid,
  amountValidator,
  doesNotMatchCoApplicant,
  commonSymbols,
];

export default function extend (veeValidate) {
  rules.forEach(rule => veeValidate.extend(rule.name, rule.validator, rule.opts));
}
