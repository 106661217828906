import { SET_PRODUCT_DATA } from '@bigbank/web-loco/src/store/modules/products/types';
import router from '../../../router';
import { SET_APPLICATION_MAPPING } from './types';
import { pages, SOURCE } from '@/constants';
import { SET_INITIAL_SOURCE } from '../processStep/mutations';
import { APP_MAPS,
  getApplicationMappingByRoute,
  getAdjustedApplicationMappingIdByLoanPurpose,
  getApplicationMappingByProduct } from '@/util/applicationMapping';

export const adjustProductDataAndApplicationMappingByLoanPurpose = ({ commit, state }, loanPurpose) => {
  const adjustedAppMapId = getAdjustedApplicationMappingIdByLoanPurpose({
    applicationMappingId: state.applicationMapping.id,
    loanPurpose,
  });
  commit(SET_PRODUCT_DATA, { productName: APP_MAPS[adjustedAppMapId].calculatorProductName });
  commit(SET_APPLICATION_MAPPING, APP_MAPS[adjustedAppMapId]);
};

export const initializeFromUrl = async ({ dispatch, commit }) => {
  const source = (() => {
    if ([
      SOURCE.BIGBANK,
      SOURCE.AUTOPLIUS,
      SOURCE.DIGINET,
      SOURCE.ARUODAS,
    ].includes(router.currentRoute.query.source)) {
      // Map synonymous source "Aruodas" to "Diginet".
      return router.currentRoute.query.source === SOURCE.ARUODAS ? SOURCE.DIGINET : router.currentRoute.query.source;
    }

    return [
      pages.autoplius,
      pages.diginet,
    ].includes(router.currentRoute?.name)
      ? (router.currentRoute?.name === pages.diginet ? SOURCE.DIGINET : SOURCE.AUTOPLIUS)
      : SOURCE.BIGBANK;
  })();
  commit(`processStep/${SET_INITIAL_SOURCE}`, source, { root: true });

  const applicationMapping = getApplicationMappingByRoute({
    source,
    productName: router.currentRoute?.query?.productName || router.currentRoute?.params?.productName,
  });
  commit(SET_APPLICATION_MAPPING, applicationMapping);

  const body = {
    source,
    product: applicationMapping.calculatorProductName,
    segmentName: router.currentRoute?.query?.segmentName || applicationMapping.segmentName || '',
  };

  await dispatch('getProducts', body);

  const currentRouteQueryWithoutSource = { ...router.currentRoute.query };
  delete currentRouteQueryWithoutSource.source;
  commit(SET_PRODUCT_DATA, {
    ...currentRouteQueryWithoutSource,
    productName: applicationMapping.calculatorProductName,
  });
};

export const initializeFromApplication = async ({ dispatch, getters, rootGetters, commit }) => {
  const initialCalculator = rootGetters['processStep/initialCalculator'];
  const loanPurpose = rootGetters['processStep/personalDetails']?.loanPurpose;
  const customerSegment = rootGetters['processStep/customerSegment'];
  const applicationMapping = getApplicationMappingByProduct({
    ...initialCalculator,
    loanPurpose,
  });
  commit(SET_APPLICATION_MAPPING, applicationMapping);

  if (getters.products.length === 0) {
    await dispatch('getProducts', {
      product: applicationMapping.calculatorProductName,
      segmentName: initialCalculator.segmentName || applicationMapping.segmentName || '',
      ...customerSegment && { customerSegment },
      source: rootGetters['processStep/source'],
    });
  }
  commit(SET_PRODUCT_DATA, {
    ...initialCalculator,
    productName: applicationMapping.calculatorProductName,
  });
};

export default {
  initializeFromUrl,
  initializeFromApplication,
  adjustProductDataAndApplicationMappingByLoanPurpose,
};
