<template>
  <loading v-if="isLoading" />
  <bb-container
    v-else
    class="select-product"
  >
    <bb-row
      justify="center"
      class="p-t-45"
    >
      <bb-col
        sm="24"
        md="12"
        xl="10"
      >
        <bb-row>
          <bb-col col="23">
            <bb-product-cards
              v-if="productCards.length > 0"
              id="productCards"
              v-model="productName"
              :cards="productCards"
              :title="$t('views.productSelection.cardsTitle')"
              class="title--hyphens"
            />
            <product-card-content
              v-if="selectedProductCard"
              id="productCardContent"
              :sale-arguments="selectedProductCard.saleArguments"
            />
          </bb-col>
        </bb-row>
      </bb-col>
      <bb-col
        sm="24"
        md="12"
        xl="10"
        class="p-x-0"
      >
        <bb-card>
          <bb-calculator
            v-if="productCards.length > 0"
            id="calculator"
            name="choose-product-calculator"
            class="m-b-20"
            sliders
            v-bind="calculatorData"
            :locale="$i18n.locale"
            :amount-step="50"
            :min-amount="selectedProduct.minAmount"
            :max-amount="selectedProduct.maxAmount"
            :min-period="selectedProduct.minPeriod"
            :max-period="selectedProduct.maxPeriod"
            :payment="loanPaymentData.monthlyPayment"
            :loader="loanLoading"
            @input="onChange"
          />
        </bb-card>
      </bb-col>
      <bb-col
        class="m-t-30"
        md="24"
        xl="20"
      >
        <p class="f-smallest">
          {{ $t('components.calculator.disclaimer', disclaimerValues) }}
        </p>
      </bb-col>

      <bb-col md="10">
        <bb-form
          id="formSubmitButton"
          :block-submit="false"
          :submit-text="$t('form.continue')"
          :error-text="$t('form.errorText')"
          :disabled="processStepLoading"
          :loading="processStepLoading"
          @submit="submit"
        />
      </bb-col>
    </bb-row>
  </bb-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Loading from '../components/Loading';
  import { BbCalculator,
           BbCard,
           BbCol,
           BbContainer,
           BbForm,
           BbProductCards,
           BbRow } from '@bigbank/interface-components';
  import ProductCardContent from '../components/ProductCardContent';
  import { DEFAULT_MONTHLY_PAYMENT_DAY_UNTIL_PROVIDED, PRODUCT_NAME } from '@/constants';
  import { getApplicationMappingByProduct } from '@/util/applicationMapping';

  const icons = {
    [PRODUCT_NAME.SMALL_LOAN]: 'ui-cash',
    [PRODUCT_NAME.CAR_LOAN]: 'ui-car-side',
    [PRODUCT_NAME.RENOVATION_LOAN]: 'ui-paint',
    [PRODUCT_NAME.REFINANCING_LOAN]: 'ui-chart-line',
  };

  export default {
    name: 'product-selection',
    components: {
      BbRow,
      BbCol,
      BbContainer,
      BbForm,
      BbProductCards,
      BbCalculator,
      BbCard,
      ProductCardContent,
      Loading,
    },
    data() {
      return {
        productName: null,
        calculatorData: {
          amount: null,
          period: null,
          interestRate: null,
        },
      };
    },
    computed: {
      ...mapGetters('products', [
        'isLoading',
        'products',
        'customerSegment',
      ]),
      ...mapGetters('processStep', {
        processStepLoading: 'isLoading',
        source: 'source',
        customerSegment: 'customerSegment',
      }),
      ...mapGetters('loan', [
        'loanPaymentDetails',
        'loanLoading',
      ]),

      productCards() {
        return this.availableProducts
          .map(product => ({
            id: product.productName,
            label: this.$t(`views.productSelection.${product.productName}.label`),
            icon: icons[product.productName],
            maxAmount: product.maxAmount,
            saleArguments: this.$t(`views.productSelection.${product.productName}.saleArguments`),
          }));
      },
      availableProducts() {
        const productsList = [
          PRODUCT_NAME.SMALL_LOAN,
          PRODUCT_NAME.CAR_LOAN,
          PRODUCT_NAME.RENOVATION_LOAN,
          PRODUCT_NAME.REFINANCING_LOAN,
        ];

        const filtered = this.products
          .filter(product => productsList.includes(product.productName));

        if (filtered.length === 0) {
          return [];
        }

        return productsList
          .map(productName => filtered.find(f => f.productName === productName));
      },
      selectedProduct() {
        return this.availableProducts.find(p => p.productName === this.productName) ?? {
          maxAmount: null,
          minAmount: null,
          maxPeriod: null,
          minPeriod: null,
        };
      },
      selectedProductCard() {
        return this.productCards.find(p => p.id === this.productName);
      },
      loanPaymentData() {
        return this.loanPaymentDetails[0] ?? {
          totalRepayableAmount: 0,
          monthlyPayment: 0,
          apr: 0,
        };
      },
      disclaimerValues() {
        return {
          amount: this.$n(this.calculatorData.amount, 'currency'),
          period: this.$n(this.calculatorData.period),
          interestRate: this.$n(this.calculatorData.interestRate / 100, 'percent'),
          administrationFee: this.$n(this.selectedProduct.maintenanceFee, 'currency-decimal'),
          conclusionFeeRate: this.$n(this.selectedProduct.contractConclusionFeeRate / 100, 'percent'),
          monthlyPayment: this.$n(this.loanPaymentData.monthlyPayment, 'currency-decimal'),
          aprc: this.$n(this.loanPaymentData.apr / 100, 'percent'),
          totalRepayableAmount: this.$n(this.loanPaymentData.totalRepayableAmount, 'currency-decimal'),
        };
      },
    },
    watch: {
      productName() {
        this.onChange({
          amount: this.selectedProduct.baseAmount,
          period: this.selectedProduct.basePeriod,
          interestRate: this.selectedProduct.interestRate,
        });
      },
    },
    async created() {
      await this.getProducts({
        source: this.source,
        ...this.customerSegment && { customerSegment: this.customerSegment },
      });
      this.productName = PRODUCT_NAME.SMALL_LOAN;
    },
    methods: {
      ...mapActions('products', ['getProducts']),
      ...mapActions('processStep', ['updateProcessStep']),
      ...mapActions('loan', ['debouncedCalculateLoanPaymentDetails']),

      onChange(calculatorData) {
        this.calculatorData = calculatorData;
        this.calculateLoanDetails();
      },
      calculateLoanDetails() {
        const conclusionFee = this.calculatorData.amount * (this.selectedProduct.contractConclusionFeeRate / 100);

        const body = {
          maturity: this.calculatorData.period,
          productType: this.selectedProduct.productType,
          amount: this.calculatorData.amount,
          monthlyPaymentDay: DEFAULT_MONTHLY_PAYMENT_DAY_UNTIL_PROVIDED,
          interestRate: this.selectedProduct.interestRate,
          conclusionFee,
          administrationFee: this.selectedProduct.maintenanceFee,
        };

        this.debouncedCalculateLoanPaymentDetails(body);
      },
      submit() {
        this.updateProcessStep({
          application: {
            productName: this.productName,
            loanPurpose: getApplicationMappingByProduct({ productName: this.productName }).loanPurposes[0],
            initialLoanAmount: this.calculatorData.amount,
            initialLoanPeriod: this.calculatorData.period,
          },
        });
      },
    },
  };
</script>
