<template>
  <section class="offer-details">
    <bb-list-group v-if="details.length > 0">
      <bb-list-item
        v-for="(detail, index) in details"
        :key="index"
        condensed
        :title-uppercase="false"
        :title="detail.text"
      >
        {{ detail.value }}
      </bb-list-item>
    </bb-list-group>
  </section>
</template>

<script>
  import { BbListGroup, BbListItem } from '@bigbank/interface-components';
  import { INTEREST_TYPE, PRODUCT_NAME } from '@/constants';
  import administrationFeeMixin from '@/mixins/administrationFeeMixin';
  import { mapGetters } from 'vuex';

  export default {
    name: 'offer-details',

    components: {
      BbListGroup,
      BbListItem,
    },

    mixins: [administrationFeeMixin],

    props: {
      option: {
        type: Object,
        default: () => ({}),
      },
      goodsPrice: Number,
      selfFinancingAmount: Number,
      hideSelfFinancingAmount: Boolean,
    },

    computed: {
      ...mapGetters('processStep', ['leasingProductDetails']),

      details() {
        const productDetails = [
          {
            text: INTEREST_TYPE.EURIBOR_6_MONTHS === this.leasingProductDetails.interestType
              ? this.$t('views.offer.6mEuriborInterestRate')
              : this.$t('views.offer.fixedInterestRate'),
            value: this.$n(this.option.interestRate * 0.01, 'percent-decimal'),
          },
          {
            text: this.$t('views.offer.conclusionFee'),
            value: this.$n(this.option.conclusionFee, 'currency-decimal'),
          },
          {
            text: this.$t('views.offer.administrationFee'),
            value: this.administrationFee({
              amount: this.option?.amount,
              administrationFee: this.option?.administrationFee,
            }),
          },
          {
            text: this.$t('views.offer.aprc'),
            value: this.$n(this.option.aprc * 0.01, 'percent-decimal'),
          },
        ];

        if (PRODUCT_NAME.CAR_FINANCE_LEASE === this.leasingProductDetails.productName) {
          if (!this.hideSelfFinancingAmount) {
            productDetails.unshift(
              {
                text: this.$t('views.offer.downpaymentAmount'),
                value: this.$n(this.selfFinancingAmount, 'currency-decimal'),
              },
            );
          }
          productDetails.unshift(
            {
              text: this.$t('views.offer.vehiclePrice'),
              value: this.$n(this.goodsPrice, 'currency-decimal'),
            },
          );
        }

        return productDetails.filter(({ value }) => !value.includes('NaN'));
      },
    },
  };
</script>
