<template>
  <bb-offer-thank-you-page
    :form-props="formProps"
    @submit="submit"
  >
    <template slot="header">
      <bb-hero
        :title="$t('views.bankDetails.title')"
        class="w-100"
        bleed="all"
      >
        <steps-overview />
      </bb-hero>
    </template>

    <bb-radio
      v-validate="'required'"
      label-center
      equal-width
      type="number"
      :value="monthlyPaymentDay"
      name="monthly-payment-day"
      :data-vv-as="$t('application.monthlyPaymentDay.label')"
      :label="$t('application.monthlyPaymentDay.label')"
      :options="paymentDayOptions"
      @change="setPaymentDay"
    />

    <address-details @update="updateAddressAndMarketingConsent" />
  </bb-offer-thank-you-page>
</template>

<script>
  import AddressDetails from '@/components/AddressDetails';
  import bankDetailsMixin from '@/mixins/bankDetailsMixin';
  import formMixin from '@/mixins/formMixin';

  export default {
    name: 'submit-bank-details-and-address',

    components: { AddressDetails },

    mixins: [
      bankDetailsMixin,
      formMixin,
    ],

    data() {
      return {
        address: undefined,
        marketingConsent: undefined,
      };
    },

    methods: {
      updateAddressAndMarketingConsent({ address, marketingConsent }) {
        this.address = address;
        this.marketingConsent = marketingConsent;
      },

      submit() {
        this.updateProcessStep({
          application: {
            applicant: { contactAddress: { ...this.address }},
            marketingConsent: this.marketingConsent,
            monthlyPaymentDay: this.monthlyPaymentDay,
          },
        });
      },
    },
  };
</script>
