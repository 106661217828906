import i18n from '@/locales/i18n';
import { TRANSLATION_KEYS } from '@/constants/translationKeys';

export default function ({ firstName, surname, personalIdentityCode } = {}) {
  return `${i18n.t(`${TRANSLATION_KEYS.AGREE_WITH_CONSENT_TITLE}`)}
${firstName} ${surname}

${i18n.t('application.applicant.personalIdentityCode.label')}: ${personalIdentityCode}

${Object.values(i18n.t(`${TRANSLATION_KEYS.AGREE_WITH_CONSENT_PARAGRAPHS}`)).join('\n')}`;
};
