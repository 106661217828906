export const FIXED_SECTIONS = {
  campaign: 'CAMPAIGN',
  employment: 'EMPLOYMENT',
  leasing: 'LEASING',
  realEstate: 'REAL_ESTATE',
  default: 'DEFAULT',
};

export const FILE_UPLOAD_SETTINGS = {
  allowedTypes: [
    'application/pdf',
    'adoc',
    'image/jpg',
    'image/png',
    'image/jpeg',
  ],
  sizeLimit: 30,
  fileCountLimitInSection: 10,
};
