import { BbApplicationPage,
  BbPublicHeader,
  BbInput,
  BbFormRepeater,
  BbSelect, BbCheckbox, unlocalizeNumber } from '@bigbank/interface-components';
import { LIABILITY } from '@/constants';
import { ADDITIONAL_INCOME_TYPES,
  BENEFITS_INCOME_TYPES,
  BUSINESS_INCOME_TYPES,
  SALARY_INCOME_TYPES } from '@/constants/application';
import { mapGetters } from 'vuex';
import isFinite from 'lodash/isFinite';

/**
 * Unlocalizes a Lithuanian-formatted number string or numeric value by converting it to a numeric value.
 * Underneath, this conversion is based on the IC exposed "unlocalizeNumber" function.
 * IC function handles NaN & null internally, however the coverage is not 100% complete.
 *
 * @param {string|number} val - The Lithuanian-formatted number string or numeric value to unlocalize.
 * @returns {number} The unlocalized positive numeric value. If unlocalization fails, returns 0.
 *
 * @example
 * const localizedNumber = "1 234,56"; // Other correct sample is "1 234.56".
 * const unlocalizedValue = unlocalizeLtNumber(localizedNumber);
 * console.log(unlocalizedValue); // Output: 1234.56
 * Logs an error (for Kibana APM tracker) if there is an issue unlocalizing the number.
 */
export const unlocalizeLtNumber = val => {
  const numberValue = unlocalizeNumber(val, { locale: 'lt' });

  if (!isFinite(numberValue) || numberValue !== Math.abs(numberValue)) {
    console.error(`Error unlocalizing LT number from value: ${val}!`);

    return 0;
  }

  return numberValue;
};

export default {
  components: {
    BbApplicationPage,
    BbFormRepeater,
    BbPublicHeader,
    BbInput,
    BbSelect,
    BbCheckbox,
  },
  data() {
    return {
      incomes: [],
      liabilities: [],
      probableDecreasedIncome: null,
      incomeMayDecrease: null,
      noLiabilities: null,
      employment: { companyName: null },
      incomeReceivedToBankInBusinessUnitCountry: null,
    };
  },
  computed: {
    ...mapGetters('processStep', [
      'isLoading',
      'initialCalculator',
    ]),
    incomeTypeGroups() {
      return [
        {
          name: this.$t('application.income.typeInForm.groups.salary'),
          options: this.enumsToOptions(SALARY_INCOME_TYPES, 'application.income.typeInForm'),
        },
        {
          name: this.$t('application.income.typeInForm.groups.business'),
          options: this.enumsToOptions(BUSINESS_INCOME_TYPES, 'application.income.typeInForm'),
        },
        {
          name: this.$t('application.income.typeInForm.groups.benefits'),
          options: this.enumsToOptions(BENEFITS_INCOME_TYPES, 'application.income.typeInForm'),
        },
        {
          name: this.$t('application.income.typeInForm.groups.additional'),
          options: this.enumsToOptions(ADDITIONAL_INCOME_TYPES, 'application.income.typeInForm'),
        },
      ];
    },
    liabilityTypeOptions() {
      return this.enumsToOptions(LIABILITY, 'application.liability.type');
    },
  },
  methods: {
    unlocalizeLtNumber,

    formatAmount(amount) {
      return amount ? this.$n(this.unlocalizeLtNumber(amount), 'currency' ) : null;
    },

    enumToText(typeEnum) {
      if (!typeEnum) return;

      return [
        ...this.liabilityTypeOptions,
        ...this.incomeTypeGroups
          .map(group => group.options)
          .flat(),
      ].find(o => o.value === typeEnum).text;
    },
  },
  watch: {
    noLiabilities(newValue) {
      if (newValue) {
        this.liabilities = [];
      }
    },
    incomeMayDecrease(newValue) {
      if (newValue === null) {
        this.probableDecreasedIncome = null;
      }
    },
  },
};
