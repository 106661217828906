export const DSM_ERROR = {
  SMART_ID_NO_ACCOUNT: 'SMART_ID_API_404',
  SMART_ID_BASIC: 'SMART_ID_API_471',
  SMART_ID_VIEW_APP_OR_SELF_SERVICE: 'SMART_ID_API_472',
  SMART_ID_UNSUPPORTED_CLIENT: 'SMART_ID_API_480',
  SMART_ID_MAINTENANCE: 'SMART_ID_API_580',
};

export const DSS_ERROR = {
  SMART_ID_NOT_CLIENT: 'NOT_CLIENT',
  SMART_ID_INVALID_CERTIFICATE_LEVEL: 'INVALID_CERTIFICATE_LEVEL',
  SMART_ID_INVALID_CERTIFICATE: 'INVALID_CERTIFICATE',
  SMART_ID_TIMEOUT: 'TIMEOUT',
  SMART_ID_USER_CANCELLED: 'USER_CANCELLED',
  BANKLINK_NAME_MISMATCH: 'BANKLINK_NAME_MISMATCH',
  BANKLINK_PAYMENT_FAILED: 'BANKLINK_PAYMENT_FAILED',
};

export const SK_ERROR = {
  SK_301: 'SK_301',
  SK_302: 'SK_302',
  SK_303: 'SK_303',
  SK_304: 'SK_304',
  SK_305: 'SK_305',
  SK_AUTH_REVOKED_CERTIFICATE: 'SK_AUTH_REVOKED_CERTIFICATE',
  SK_SIGN_REVOKED_CERTIFICATE: 'SK_SIGN_REVOKED_CERTIFICATE',
  SK_AUTH_USER_CANCEL: 'SK_AUTH_USER_CANCEL',
  SK_SIGN_USER_CANCEL: 'SK_SIGN_USER_CANCEL',
  SK_AUTH_PHONE_ABSENT: 'SK_AUTH_PHONE_ABSENT',
  SK_SIGN_PHONE_ABSENT: 'SK_SIGN_PHONE_ABSENT',
  SK_AUTH_SENDING_ERROR: 'SK_AUTH_SENDING_ERROR',
  SK_SIGN_SENDING_ERROR: 'SK_SIGN_SENDING_ERROR',
  SK_AUTH_SIM_ERROR: 'SK_AUTH_SIM_ERROR',
  SK_SIGN_SIM_ERROR: 'SK_SIGN_SIM_ERROR',
  NOT_CLIENT: 'NOT_CLIENT',
  USER_CANCELLED: 'USER_CANCELLED',
  INVALID_CERTIFICATE_LEVEL: 'INVALID_CERTIFICATE_LEVEL',
  INACTIVE_CERTIFICATE: 'INACTIVE_CERTIFICATE',
  INVALID_CERTIFICATE: 'INVALID_CERTIFICATE',
  CERTIFICATE_PERSONAL_IDENTITY_CODE_MISMATCHING: 'CERTIFICATE_PERSONAL_IDENTITY_CODE_MISMATCHING',
  DEVICE_ERROR: 'DEVICE_ERROR',
  SIGNER_CERTIFICATE_REVOKED: 'SIGNER_CERTIFICATE_REVOKED',
  SIGNER_CERTIFICATE_UNKNOWN: 'SIGNER_CERTIFICATE_UNKNOWN',
};
