export const SET_INITIAL_SOURCE = 'SET_INITIAL_SOURCE 👀';
export const SET_TASK_VARIABLE_VALUE = 'SET_TASK_VARIABLE_VALUE 🌟';

export default {
  [SET_INITIAL_SOURCE]: (state, payload) => {
    state.initialSource = payload;
  },

  [SET_TASK_VARIABLE_VALUE]: (state, { variableName, variableValue }) => {
    state.task.variables[variableName] = variableValue;
  },
};
