import { headerFlavors, footerFlavors } from './state';
import i18n from '../../../locales/i18n';
import { SOURCE, pages, THEME } from '../../../constants';

const themedSources = { [SOURCE.AUTOPLIUS]: THEME.AUTOPLIUS };
const themedRoutes = { [pages.autoplius]: THEME.AUTOPLIUS };

export const getters = {
  backgroundColor: state => state.backgroundColor,
  currentPage: state => state.progress.page.current,
  maxPage: state => state.progress.page.max,
  isIFrameFlavor: state => state.headerFlavor === headerFlavors.NO_HEADER && state.footerFlavor === footerFlavors.NO_FOOTER,
  isProgressFlavor: state => [
    headerFlavors.PROGRESS,
    headerFlavors.PROGRESS_CALCULATOR,
  ].includes(state.headerFlavor),
  isCalculatorFlavor: state => state.headerFlavor === headerFlavors.PROGRESS_CALCULATOR,
  currentStep: state => state.progress.step.current,
  stepsFlavor: state => state.stepsFlavor,
  theme: (_state, _getters, rootState, rootGetters) => themedRoutes[rootState.route?.name] ?? themedSources[rootGetters['processStep/source'] ?? rootGetters['processStep/initialSource']],
  submitDisclaimer: (_state, getters) => getters.theme && i18n.t('views.personalDetails.disclaimer'),
};
