<template>
  <bb-offer-thank-you-page
    ref="bank-details"
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="submit"
  >
    <template slot="header">
      <bb-hero
        :title="$t('views.bankDetails.title')"
        class="w-100"
        bleed="all"
      >
        <steps-overview />
      </bb-hero>
    </template>

    <iban-input
      v-if="!isIBANHidden"
      v-model="bankAccountNumber"
    />

    <bb-radio
      v-validate="'required'"
      label-center
      equal-width
      type="number"
      :value="monthlyPaymentDay"
      name="monthly-payment-day"
      :data-vv-as="$t('application.monthlyPaymentDay.label')"
      :label="$t('application.monthlyPaymentDay.label')"
      :options="paymentDayOptions"
      @change="setPaymentDay"
    />
  </bb-offer-thank-you-page>
</template>

<script>
  import { mapGetters } from 'vuex';
  import IbanInput from '@/components/IbanInput';
  import bankDetailsMixin from '@/mixins/bankDetailsMixin';
  import partnerApiMixin from '@/mixins/partnerApiMixin';
  import { PRODUCT_NAME } from '@/constants';

  export default {
    name: 'bank-details',

    components: { IbanInput },

    mixins: [
      bankDetailsMixin,
      partnerApiMixin,
    ],

    computed: {
      ...mapGetters('processStep', [
        'productName',
        'variables',
      ]),

      isIBANHidden() {
        return this.variables?.ibanBeforeSigningEnabled ||
          this.isPartnerApiHpFlow ||
          [
            PRODUCT_NAME.CAR_FINANCE_LEASE,
            PRODUCT_NAME.HOUSING_LOAN,
          ].includes(this.productName);
      },
    },

    methods: {
      submit() {
        this.updateProcessStep({
          application: {
            ...(
              this.isIBANHidden
                ? {}
                : { applicant: { bankAccountNumber: this.bankAccountNumber.replace(/ /g, '') }}
            ),
            monthlyPaymentDay: this.monthlyPaymentDay,
          },
        });
      },
    },
  };
</script>
