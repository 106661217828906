<template>
  <bb-container
    fluid
    class="no-gutters"
  >
    <bb-row class="align-items-center m-t-5 p-y-15">
      <img
        class="d-block bank-logo border-radius-rounded"
        :src="institution.logo"
        :alt="`${institution.name}_logo`"
      >
      <bb-col>
        <p class="f-gotham-medium f-color-black">
          {{ institution.name }}
        </p>
        <p
          v-if="enrolled"
          class="f-gotham-medium f-smallest f-color-black f-color-gray-90"
        >
          {{ institution.iban }}
        </p>
      </bb-col>
      <bb-button
        v-if="!enrolled"
        :id="`link${institution.name}Button`"
        color="mint"
        inverted
        size="md"
        :label="$t('views.provideBasViaOpenBanking.link')"
        :disabled="disabled"
        :loading="loading"
        @click="$emit('select-institution', institution.id)"
      />
    </bb-row>
    <bb-row
      v-if="institution.error"
      class="m-t-sm-15"
    >
      <bb-col
        col="24"
      >
        <p class="f-gotham-medium f-small f-color-red">
          {{ $t('views.provideBasViaOpenBanking.errors.ssn_error') }}
        </p>
      </bb-col>
    </bb-row>
  </bb-container>
</template>

<script>
  import { BbContainer, BbButton, BbRow, BbCol } from '@bigbank/interface-components';

  export default {
    name: 'bank-link-row',

    components: {
      BbContainer,
      BbButton,
      BbRow,
      BbCol,
    },

    props: {
      institution: Object,
      enrolled: Boolean,
      loading: Boolean,
      disabled: Boolean,
    },
  };
</script>

<style lang="scss" scoped>
  .bank-logo {
    width: 40px;
    height: 40px;
  }
</style>
