<template>
  <loading />
</template>

<script>
  import Loading from '../components/Loading';

  export default {
    name: 'start',
    components: { Loading },
  };
</script>
