import { mapGetters } from 'vuex';

const _getFee = ({
  amount,
  administrationFee,
  administrationFeePercent,
  offerIncludesRefinance,
  formatPercent = 'percent-decimal',
  formatCurrency = 'currency-decimal',
}) => {
  if (offerIncludesRefinance) {
    return ({
      value: Number.isFinite(administrationFeePercent)
        ? administrationFeePercent * 0.01
        : Math.round((administrationFee * 100 / amount + Number.EPSILON) * 100) / 10000,
      format: formatPercent,
    });
  }

  return ({
    value: administrationFee,
    format: formatCurrency,
  });
};

export default {
  computed: { ...mapGetters('processStep', ['offer']) },

  methods: {
    administrationFee({ amount, administrationFee, formatPercent, formatCurrency }) {
      const { value, format } = _getFee({
        amount,
        administrationFee,
        formatPercent,
        formatCurrency,
        administrationFeePercent: this.offer?.administrationFeePercent,
        offerIncludesRefinance: this.offer?.offerIncludesRefinance,
      });

      return this.$n(Number.isFinite(value) ? value : NaN, format);
    },
  },
};
