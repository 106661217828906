import clamp from 'lodash/clamp';
import isNumber from 'lodash/isNumber';
import round from 'lodash/round';
import { mapGetters } from 'vuex';
import { MAX_NUM_VALUE } from '@/constants';

/**
 * @mixin Handle self financing minimum & maximum amount restrictions based off product and user entered goods price.
 */
export default {
  computed: { ...mapGetters('products', ['getProductData']) },

  methods: {
    getMinSelfFinancingAmountFromPriceByProduct(goodsPrice, renovationPrice = 0) {
      const currentRenovationPrice = isFinite(renovationPrice) ? +renovationPrice : 0;

      return isNumber(goodsPrice) && isNumber(this.getProductData?.selfFinancingMinPercent)
        ? round((goodsPrice + currentRenovationPrice) * this.getProductData?.selfFinancingMinPercent * 0.01 + Number.EPSILON, 2)
        : 0;
    },
    getMaxSelfFinancingAmountFromPriceByProduct(goodsPrice) {
      return isNumber(goodsPrice) && isNumber(this.getProductData?.minAmount)
        ? clamp(goodsPrice - this.getProductData?.minAmount, this.getProductData?.minAmount, MAX_NUM_VALUE)
        : MAX_NUM_VALUE;
    },
  },
};
