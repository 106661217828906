<template>
  <div class="identification-methods">
    <p class="header-text strong small align-center color-gray-80 m-b-10">
      {{ $t('components.identificationMethods.chooseIdentificationMethod') }}
    </p>

    <bb-row
      v-for="method in methods"
      :key="method.type"
      class="m-t-15"
    >
      <bb-col>
        <bb-button
          :id="method.type"
          display="image"
          class="w-100 p-y-5"
          @click="$emit('click', method.type)"
        >
          <img
            :src="method.img"
            :alt="method.name"
            class="h-100"
          >
        </bb-button>
      </bb-col>
    </bb-row>
    <agree-with-consent-modal
      v-if="isPartnerApplication"
      class="m-t-10"
      :applicant="applicant"
    />
    <p class="header-text strong small align-center color-gray-80 m-t-15">
      {{ $t('components.identificationMethods.footer') }}
    </p>
  </div>
</template>

<script>
  import { BbButton, BbCol, BbRow } from '@bigbank/interface-components';
  import { mapGetters } from 'vuex';
  import AgreeWithConsentModal from '../AgreeWithConsentModal';
  import personalDetailsMixin from '../../mixins/personalDetailsMixin';
  import { APPLICATION_ORIGIN } from '../../constants';

  export default {
    name: 'identification-methods',

    components: {
      BbRow,
      BbCol,
      BbButton,
      AgreeWithConsentModal,
    },
    mixins: [personalDetailsMixin],

    computed: {
      ...mapGetters('identification', [
        'allowedMethods',
        'bankLinkMethods',
      ]),
      ...mapGetters('processStep', ['applicationOrigin']),
      isPartnerApplication () {
        return this.applicationOrigin === APPLICATION_ORIGIN.PARTNER;
      },
      methods() {
        return [
          ...this.allowedMethods,
          ...this.bankLinkMethods,
        ].sort((a, b) => a.order < b.order ? -1 : 1);
      },
    },
  };
</script>
