import router from '../../../router';
import { CHANNEL, pages, SOURCE, APPLICATION_ORIGIN } from '@/constants';
import LtLoanOriginationClient from '../../../api/LtLoanOriginationClient';
import { LOADING } from '@bigbank/web-loco/src/store/modules/processStep/types';
import merge from 'lodash/merge';
import trackingData from '../../../util/TrackingData';
import { SET_TASK_VARIABLE_VALUE } from '@/store/modules/processStep/mutations';

const appStarted = async store => {
  const { externalId } = router.currentRoute.query;

  if (router.currentRoute.name === pages.completeRemoteIdentification) {
    await store.dispatch('signing/completeRemoteIdentification', {
      externalId,
      handleResponse: true,
    }, { root: true });
  } else if (router.currentRoute.name === pages.completeOnlineSigning) {
    await store.dispatch('signing/completeOnlineSigning', {}, { root: true });
  } else if (router.currentRoute.name === pages.completeLoginServiceIdentification) {
    await store.dispatch('identification/completeLoginServiceIdentification', externalId, { root: true });
  } else if (externalId) {
    await store.dispatch('processStep/continueProcess', externalId, { root: true });
  } else if (router.currentRoute.name === pages.start) {
    await store.dispatch('processStep/createUpsaleApplication', {
      application: {
        applicant: { agreeToPersonalDataProcessing: true },
        source: {
          channel: CHANNEL.SELF_SERVICE,
          source: SOURCE.BIGBANK,
          countryCode: 'LT',
          applicationOrigin: APPLICATION_ORIGIN.WEB,
        },
        metaData: { tracking: trackingData.getData() },
      },
    }, { root: true });
  }
};

const createPublicApplication = (store, { application, variables, hCaptchaToken }) => {
  const { amount, period } = store.rootGetters['products/getProductData'];
  const initialApplicationData = store.rootGetters['products/initialApplicationData'];

  return store.dispatch('processStep/createApplication', {
    application: {
      ...initialApplicationData,
      ...application,
      applicant: merge({ agreeToPersonalDataProcessing: true }, application.applicant),
      source: merge({
        channel: CHANNEL.WEBPAGE,
        source: SOURCE.BIGBANK,
        countryCode: 'LT',
        applicationOrigin: APPLICATION_ORIGIN.WEB,
      }, application.source),
      initialLoanAmount: amount,
      initialLoanPeriod: period,
      metaData: { tracking: trackingData.getData() },
    },
    variables,
    hCaptchaToken,
  }, { root: true });
};

const updateProcessWithCalculatorData = (store, application) => {
  const { amount, period } = store.rootGetters['products/getProductData'];

  return store.dispatch('processStep/updateProcessStep', {
    application: {
      ...application,
      initialLoanAmount: amount,
      initialLoanPeriod: period,
    },
  }, { root: true });
};

const updateApplicationWithCalculatorData = (store, payload) => {
  const { amount, period } = store.rootGetters['products/getProductData'];

  return store.dispatch('processStep/updateApplication', merge(payload, {
    application: {
      initialLoanAmount: amount,
      initialLoanPeriod: period,
    },
  }), { root: true });
};

const updateApplicationWithPartnerApiRedirectUrl = (store, webShopRedirectUri) => {
  store.commit(`processStep/${LOADING}`, true, { root: true });
  const { externalId } = store.getters;

  return LtLoanOriginationClient.updateRedirectUri(externalId, webShopRedirectUri)
    .catch(error => store.dispatch('processStep/handleError', error, { root: true }))
    .finally(() => store.commit(`processStep/${LOADING}`, false, { root: true }));
};

/**
 * Fetches the postal code based on the provided address.
 *
 * @param {Object} store - The Vuex store.
 * @param {Object} address - The address for which to fetch the postal code.
 * @param {string} address.city - The city associated with the address.
 * @param {string} address.street - The street associated with the address.
 * @param {string} address.houseNumber - The house number associated with the address.
 *
 * @returns {Promise<string>} A Promise that resolves to the postal code.
 */
const fetchPostalCode = async ({ commit, dispatch }, address) => {
  commit(`processStep/${LOADING}`, true, { root: true });

  try {
    const { data: { postalCode }} = await LtLoanOriginationClient.getPostalCode(address);

    return postalCode;
  } catch (err) {
    dispatch('processStep/handleError', err, { root: true });
  } finally {
    commit(`processStep/${LOADING}`, false, { root: true });
  }
};

const hideFraudWarning = async ({ commit, dispatch, getters }) => {
  commit(LOADING, true);
  const { externalId } = getters;

  try {
    await LtLoanOriginationClient.hideFraudWarning(externalId);
    commit(SET_TASK_VARIABLE_VALUE, {
      variableName: 'fraudWarningModalSeen',
      variableValue: true,
    });
  } catch (err) {
    dispatch('handleError', err);
  } finally {
    commit(LOADING, false);
  }
};

export default {
  appStarted,
  createPublicApplication,
  fetchPostalCode,
  hideFraudWarning,
  updateApplicationWithCalculatorData,
  updateApplicationWithPartnerApiRedirectUrl,
  updateProcessWithCalculatorData,
};
