import isNumber from 'lodash/isNumber';
import { mapGetters } from 'vuex';
import { MAX_NUM_VALUE } from '@/constants';

/**
 * @mixin Handle goods price minimum & maximum amount restrictions based off goodsPriceMin, goodsPriceMax and goodsPrice.
 */
export default {
  computed: { ...mapGetters('products', ['getProductData']) },

  methods: {
    getMinGoodsPrice(renovationCost) {
      if (!isNumber(this.getProductData?.minGoodsPrice)) {
        return 0;
      }

      if (!isNumber(renovationCost)) {
        return this.getProductData.minGoodsPrice;
      }

      return this.getProductData.minGoodsPrice - renovationCost > 0 ? this.getProductData.minGoodsPrice - renovationCost : 0;
    },
    getMaxGoodsPrice(renovationCost) {
      if (!isNumber(this.getProductData?.maxGoodsPrice)) {
        return MAX_NUM_VALUE;
      }

      if (!isNumber(renovationCost)) {
        return this.getProductData.maxGoodsPrice;
      }

      return this.getProductData.maxGoodsPrice - renovationCost > 0 ? this.getProductData.maxGoodsPrice - renovationCost : 0;
    },
  },
};
