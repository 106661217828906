import { auth } from '@bigbank/web-loco';
import { PROCESS_STEP_UPDATED } from '@bigbank/web-loco/src/store/modules/processStep/types';

const prefillCoBorrower = (store, userdata) => {
  store.commit(`processStep/${PROCESS_STEP_UPDATED}`, { application: { spouse: userdata }}, { root: true });
};

const actions = { prefillCoBorrower };
export const createAuthModule = (client) => {
  const authModule = auth.moduleBuilder(client);

  return {
    ...authModule,
    actions: {
      ...authModule.actions,
      ...actions,
    },
  };
};

