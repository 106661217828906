const COUNTIES = [
  {
    text: 'Vilniaus m. sav.',
    value: 'Vilniaus m. sav.',
  },
  {
    text: 'Kauno m. sav.',
    value: 'Kauno m. sav.',
  },
  {
    text: 'Klaipėdos m. sav.',
    value: 'Klaipėdos m. sav.',
  },
  {
    text: 'Panevėžio m. sav.',
    value: 'Panevėžio m. sav.',
  },
  {
    text: 'Akmenės r. sav.',
    value: 'Akmenės r. sav.',
  },
  {
    text: 'Alytaus m. sav.',
    value: 'Alytaus m. sav.',
  },
  {
    text: 'Alytaus r. sav.',
    value: 'Alytaus r. sav.',
  },
  {
    text: 'Anykščių r. sav.',
    value: 'Anykščių r. sav.',
  },
  {
    text: 'Birštono r. sav.',
    value: 'Birštono r. sav.',
  },
  {
    text: 'Biržų r. sav.',
    value: 'Biržų r. sav.',
  },
  {
    text: 'Druskininkų sav.',
    value: 'Druskininkų sav.',
  },
  {
    text: 'Elektrėnų sav.',
    value: 'Elektrėnų sav.',
  },
  {
    text: 'Ignalinos r. sav.',
    value: 'Ignalinos r. sav.',
  },
  {
    text: 'Jonavos r. sav.',
    value: 'Jonavos r. sav.',
  },
  {
    text: 'Joniškio r. sav.',
    value: 'Joniškio r. sav.',
  },
  {
    text: 'Jurbarko r. sav.',
    value: 'Jurbarko r. sav.',
  },
  {
    text: 'Kaišiadorių r. sav.',
    value: 'Kaišiadorių r. sav.',
  },
  {
    text: 'Kalvarijos sav.',
    value: 'Kalvarijos sav.',
  },
  {
    text: 'Kauno r. sav.',
    value: 'Kauno r. sav.',
  },
  {
    text: 'Kazlų Rūdos sav.',
    value: 'Kazlų Rūdos sav.',
  },
  {
    text: 'Kėdainių r. sav.',
    value: 'Kėdainių r. sav.',
  },
  {
    text: 'Kelmės r. sav.',
    value: 'Kelmės r. sav.',
  },
  {
    text: 'Klaipėdos r. sav.',
    value: 'Klaipėdos r. sav.',
  },
  {
    text: 'Kretingos r. sav.',
    value: 'Kretingos r. sav.',
  },
  {
    text: 'Kupiškio r. sav.',
    value: 'Kupiškio r. sav.',
  },
  {
    text: 'Lazdijų r. sav.',
    value: 'Lazdijų r. sav.',
  },
  {
    text: 'Marijampolės sav.',
    value: 'Marijampolės sav.',
  },
  {
    text: 'Mažeikių r. sav.',
    value: 'Mažeikių r. sav.',
  },
  {
    text: 'Molėtų r. sav.',
    value: 'Molėtų r. sav.',
  },
  {
    text: 'Neringos sav.',
    value: 'Neringos sav.',
  },
  {
    text: 'Pagėgių sav.',
    value: 'Pagėgių sav.',
  },
  {
    text: 'Pakruojo r. sav.',
    value: 'Pakruojo r. sav.',
  },
  {
    text: 'Palangos m. sav.',
    value: 'Palangos m. sav.',
  },
  {
    text: 'Panevėžio r. sav.',
    value: 'Panevėžio r. sav.',
  },
  {
    text: 'Pasvalio r. sav.',
    value: 'Pasvalio r. sav.',
  },
  {
    text: 'Plungės r. sav.',
    value: 'Plungės r. sav.',
  },
  {
    text: 'Prienų r. sav.',
    value: 'Prienų r. sav.',
  },
  {
    text: 'Radviliškio r. sav.',
    value: 'Radviliškio r. sav.',
  },
  {
    text: 'Raseinių r. sav.',
    value: 'Raseinių r. sav.',
  },
  {
    text: 'Rietavo sav.',
    value: 'Rietavo sav.',
  },
  {
    text: 'Rokiškio r. sav.',
    value: 'Rokiškio r. sav.',
  },
  {
    text: 'Šakių r. sav.',
    value: 'Šakių r. sav.',
  },
  {
    text: 'Šalčininkų r. sav.',
    value: 'Šalčininkų r. sav.',
  },
  {
    text: 'Šiaulių m. sav.',
    value: 'Šiaulių m. sav.',
  },
  {
    text: 'Šiaulių r. sav.',
    value: 'Šiaulių r. sav.',
  },
  {
    text: 'Šilalės r. sav.',
    value: 'Šilalės r. sav.',
  },
  {
    text: 'Šilutės r. sav.',
    value: 'Šilutės r. sav.',
  },
  {
    text: 'Širvintų r. sav.',
    value: 'Širvintų r. sav.',
  },
  {
    text: 'Skuodo r. sav.',
    value: 'Skuodo r. sav.',
  },
  {
    text: 'Švenčionių r. sav.',
    value: 'Švenčionių r. sav.',
  },
  {
    text: 'Tauragės r. sav.',
    value: 'Tauragės r. sav.',
  },
  {
    text: 'Telšių r. sav.',
    value: 'Telšių r. sav.',
  },
  {
    text: 'Trakų r. sav.',
    value: 'Trakų r. sav.',
  },
  {
    text: 'Ukmergės r. sav.',
    value: 'Ukmergės r. sav.',
  },
  {
    text: 'Utenos r. sav.',
    value: 'Utenos r. sav.',
  },
  {
    text: 'Varėnos r. sav.',
    value: 'Varėnos r. sav.',
  },
  {
    text: 'Vilkaviškio r. sav.',
    value: 'Vilkaviškio r. sav.',
  },
  {
    text: 'Vilniaus r. sav.',
    value: 'Vilniaus r. sav.',
  },
  {
    text: 'Visagino sav.',
    value: 'Visagino sav.',
  },
  {
    text: 'Zarasų r. sav.',
    value: 'Zarasų r. sav.',
  },
];

export default {
  computed: {
    counties() {
      return COUNTIES;
    },
  },
};
