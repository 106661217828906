<template>
  <the-frame :config="config" />
</template>

<script>
  import TheFrame from '@/components/partner/TheFrame';
  import logoAutopliusLite from '@/assets/img/logo_AutopliusLite.png';
  import { CONTACT_PHONE_AUTOPLIUS } from '@/constants';
  import { mapGetters } from 'vuex';

  export default {
    name: 'autoplius',

    components: { TheFrame },

    computed: {
      ...mapGetters('products', ['getProductData']),
      config() {
        return {
          logo: logoAutopliusLite,
          contactPhone: CONTACT_PHONE_AUTOPLIUS,
          disclaimerText: this.$t('views.partner.autoplius.disclaimer'),
          perks: [
            this.$t('views.partner.common.perks.interestForAll', { 0: this.$n(this.getProductData.interestRate * 0.01, 'percent') }),
            this.$t('views.partner.common.perks.quickLoanSolution'),
            this.$t('views.partner.common.perks.possibilityOfEarlyRepayment'),
          ],
          showCaptcha: !!window.config?.ENABLE_HCAPTCHA,
        };
      },
    },
  };
</script>
