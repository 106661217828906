<template>
  <div>
    <bb-input
      v-validate="{
        required: true,
        min_value: getMinGoodsPrice(realEstateDataForm.renovationCost),
        goodsPriceMaxValue: true
      }"
      name="goods-price"
      type="number"
      :locale="$i18n.locale"
      add-on="€"
      currency
      :label="$t('views.submitRealEstateInfo.goodsPrice')"
      :data-vv-as="$t('views.submitRealEstateInfo.validation.goodsPricePlusRenovationCost')"
      :value="realEstateDataForm.goodsPrice"
      data-vv-validate-on="input|change"
      @input="$emit('input', $event)"
      @change="realEstateDataForm.goodsPrice = Number($event); $validator.validate('self-financing-amount')"
    />
    <div
      v-if="isProductHousingLoan"
      class="m-t-30"
    >
      <bb-checkbox
        id="showRenovationCostCheck"
        v-model="realEstateDataForm.showRenovationCost"
        name="show-renovation-cost"
        background
        :label="$t('views.submitRealEstateInfo.showRenovationCost')"
        @change="changedShowRenovationCost"
      >
        <bb-input
          v-if="realEstateDataForm.showRenovationCost"
          slot="conditional"
          v-validate="{
            required: true,
            min_value: 0,
            max_value: goodsPriceMax
          }"
          type="number"
          :locale="$i18n.locale"
          add-on="€"
          currency
          name="renovation-cost"
          :label="$t('views.submitRealEstateInfo.renovationCost')"
          :data-vv-as="$t('views.submitRealEstateInfo.renovationCost')"
          :value="realEstateDataForm.renovationCost"
          data-vv-validate-on="input|change"
          @input="realEstateDataForm.renovationCost = $event ? Number($event) : $event; $validator.validate('goods-price')"
          @change="realEstateDataForm.renovationCost = $event ? Number($event) : $event; $validator.validate('goods-price')"
        />
      </bb-checkbox>
    </div>
    <bb-input
      v-validate="{
        required: true,
        min_value: getMinSelfFinancingAmountFromPriceByProduct(realEstateDataForm.goodsPrice, realEstateDataForm.renovationCost),
        max_value: getMaxSelfFinancingAmountFromPriceByProduct(realEstateDataForm.goodsPrice)
      }"
      type="number"
      :locale="$i18n.locale"
      add-on="€"
      currency
      name="self-financing-amount"
      :label="$t('views.submitRealEstateInfo.selfFinancingAmount')"
      :data-vv-as="$t('views.submitRealEstateInfo.selfFinancingAmount')"
      :value="realEstateDataForm.selfFinancingAmount"
      data-vv-validate-on="input|change"
      @input="realEstateDataForm.selfFinancingAmount = Number($event)"
      @change="realEstateDataForm.selfFinancingAmount = Number($event)"
    />
    <bb-input
      v-model="realEstateDataForm.downpaymentOriginDescription"
      v-validate="'required|commonSymbols|min:2|max:256'"
      type="text"
      name="downpayment-origin-description"
      help-active
      :label="$t('views.submitRealEstateInfo.downpaymentOriginDescription')"
      :help-text="$t('views.submitRealEstateInfo.downpaymentOriginDescriptionHelpText')"
      :data-vv-as="$t('views.submitRealEstateInfo.downpaymentOriginDescription')"
    />
    <bb-input
      type="number"
      :locale="$i18n.locale"
      add-on="€"
      currency
      disabled
      name="initial-loan-amount"
      :label="$t('views.submitRealEstateInfo.initialLoanAmount')"
      :value="calculatedInitialLoanAmount"
    />
    <bb-input
      v-validate="{
        required: true,
        numeric: true,
        min_value: productMinPeriod,
        max_value: productMaxPeriod
      }"
      type="number"
      name="initial-loan-period"
      :label="$t('views.submitRealEstateInfo.initialLoanPeriod')"
      :add-on="$t('views.submitRealEstateInfo.initialLoanPeriodUnit')"
      :data-vv-as="$t('views.submitRealEstateInfo.initialLoanPeriod')"
      :value="realEstateDataForm.initialLoanPeriod"
      @input="realEstateDataForm.initialLoanPeriod = Number($event)"
      @change="realEstateDataForm.initialLoanPeriod = Number($event)"
    />
    <bb-textarea
      v-model="realEstateDataForm.additionalInfoText"
      v-validate="'required|commonSymbols|min:2|max:1000'"
      name="additional-info-text"
      :label="$t('views.submitRealEstateInfo.additionalInfoText')"
      :data-vv-as="$t('views.submitRealEstateInfo.additionalInfoValidationField')"
      rows="5"
    />
  </div>
</template>
<script>
  import { BbInput,
           BbCheckbox,
           BbTextarea } from '@bigbank/interface-components';
  import { mapGetters } from 'vuex';
  import goodsAndProductAmountsMixin from '@/mixins/goodsAndProductAmountsMixin';
  import productSelfFinancingAmountMixin from '@/mixins/productSelfFinancingAmountMixin';
  import isNumber from 'lodash/isNumber';
  import goodsPriceMixin from '@/mixins/goodsPriceMixin';

  export default {
    name: 'real-estate-data-form',

    components: {
      BbInput,
      BbTextarea,
      BbCheckbox,
    },

    mixins: [
      goodsAndProductAmountsMixin,
      productSelfFinancingAmountMixin,
      goodsPriceMixin,
    ],

    inject: ['$validator'],

    data() {
      return {
        realEstateDataForm: {
          goodsPrice: null,
          selfFinancingAmount: null,
          downpaymentOriginDescription: null,
          initialLoanAmount: null,
          initialLoanPeriod: null,
          additionalInfoText: null,
          initialRenovationCost: null,
          collaterals: [],
          renovationCost: null,
          showRenovationCost: false,
          ...this.$store.getters['processStep/realEstateInfo'],
        },
      };
    },

    computed: {
      ...mapGetters('processStep', [
        'isLoading',
        'isProductHousingLoan',
        'isLoanPurposeCombiningLoans',
      ]),

      areCustomerDownPaymentFieldsVisible () {
        return !(this.isProductHousingLoan && this.isLoanPurposeCombiningLoans);
      },

      calculatedInitialLoanAmount() {
        if (isNumber(this.realEstateDataForm.goodsPrice) && (isNumber(this.realEstateDataForm.selfFinancingAmount) || isNumber(this.realEstateDataForm.renovationCost))) {
          let loanAmount = this.realEstateDataForm.goodsPrice;
          if (isNumber(this.realEstateDataForm.selfFinancingAmount)) {
            loanAmount = loanAmount - this.realEstateDataForm.selfFinancingAmount;
          }
          if (isNumber(this.realEstateDataForm.renovationCost)) {
            loanAmount = loanAmount + this.realEstateDataForm.renovationCost;
          }

          return loanAmount;
        }

        return this.realEstateDataForm.initialLoanAmount;
      },
    },

    watch: {
      calculatedInitialLoanAmount: {
        handler(value) {
          this.realEstateDataForm.initialLoanAmount = value;
        },
      },
      realEstateDataForm: {
        immediate: true,
        deep: true,
        handler(newValues) {
          this.$emit('realEstateData', newValues);
        },
      },
    },

    created() {
      this.$validator.extend('goodsPriceMaxValue', {
        getMessage: field => this.$t('views.submitRealEstateInfo.validation.goodsPricePlusRenovationCostShouldNotExceed', {
          fieldName: field,
          goodsPriceMax: this.goodsPriceMax,
        }),
        validate: value => this.getMaxGoodsPrice(this.realEstateDataForm.renovationCost) >= value,
      });
    },

    mounted() {
      if (this.realEstateDataForm.renovationCost) {
        this.realEstateDataForm.showRenovationCost = true;
        this.realEstateDataForm.initialRenovationCost = this.realEstateDataForm.renovationCost;
      }
    },

    methods: {
      changedShowRenovationCost (checked) {
        if (!checked) {
          this.realEstateDataForm.renovationCost = null;
        }
      },
    },
  };
</script>
