<template>
  <bb-footer
    class="the-footer"
    condensed
    :contact-links="contactLinks"
    :disclaimer="note"
  />
</template>

<script>
  import { BbFooter } from '@bigbank/interface-components';
  import { contact } from '@/constants';
  import contactEmailMixin from '../mixins/contactEmailMixin';

  export default {
    name: 'the-footer',

    components: { BbFooter },

    mixins: [contactEmailMixin],

    data() {
      return { note: this.$t('footer.note') };
    },

    computed: {
      contactLinks() {
        return [
          {
            label: this.$t('footer.callUs'),
            icon: 'ui-phone-outline',
            link: {
              label: contact.phone,
              to: `tel:${contact.phone}`,
            },
          },
          {
            label: this.$t('footer.writeUs'),
            icon: 'ui-email-outline',
            link: {
              label: this.contactEmail,
              to: `mailto:${this.contactEmail}`,
            },
          },
        ];
      },
    },
  };
</script>
