<template>
  <bb-offer-thank-you-page
    ref="whats-next-page-bank-link"
  >
    <template slot="header">
      <bb-hero
        class="w-100"
        bleed="all"
        :title="$t('views.thankYou.title')"
      />
    </template>
    <template slot="submit">
      <br>
    </template>
    <whats-next />
  </bb-offer-thank-you-page>
</template>

<script>
  import { BbHero, BbOfferThankYouPage } from '@bigbank/interface-components';
  import WhatsNext from '../components/signing/WhatsNext';
  import partnerApiMixin from '../mixins/partnerApiMixin';

  const TIMEOUT_3s = 3000;

  export default {
    name: 'whats-next-page-bank-link',

    components: {
      BbHero,
      BbOfferThankYouPage,
      WhatsNext,
    },

    mixins: [partnerApiMixin],

    mounted () {
      if (this.shouldRedirectToPartnerApi) {
        this.navigateToPartnerApi();
      } else {
        this.navigateToSelfService();
      }
    },

    methods: {
      navigateToSelfService() {
        setTimeout(() => {
          window.location.href = window.config.SELF_SERVICE_URL;
        }, TIMEOUT_3s);
      },
    },
  };
</script>
