export const DOCUMENT_TYPE = {
  bankStatement: 'BANK_STATEMENT',
  yearlyIncomeDeclaration: 'YEARLY_INCOME_DECLARATION',
  employerStatement: 'EMPLOYER_STATEMENT',
  civilServantCertificate: 'CIVIL_SERVANT_CERTIFICATE',
  incomeExpenseJournal: 'INCOME_EXPENSE_JOURNAL',
  incomeExpenseTaxSummary: 'INCOME_EXPENSE_TAX_SUMMARY',
  cashReceipt: 'CASH_RECEIPT',
  transferAct: 'TRANSFER_ACT',
  businessCertificate: 'BUSINESS_CERTIFICATE',
  selfEmploymentCertificate: 'SELF_EMPLOYMENT_CERTIFICATE',
  employmentContract: 'EMPLOYMENT_CONTRACT',
  companysYearlyProfitTaxDeclaration: 'COMPANYS_YEARLY_PROFIT_TAX_DECLARATION',
  rentAgreement: 'RENT_AGREEMENT',
  profitDistributionDecision: 'PROFIT_DISTRIBUTION_DECISION',
  farmersCertificate: 'FARMERS_CERTIFICATE',
  vehicleRegistrationCertificate: 'VEHICLE_REGISTRATION_CERTIFICATE',
  vehicleIdentityDataCertificate: 'VEHICLE_IDENTITY_DATA_CERTIFICATE',
  vehiclePurchaseDocument: 'VEHICLE_PURCHASE_DOCUMENT',
  offerFromOtherCreditProvider: 'OFFER_FROM_OTHER_CREDIT_PROVIDER',
  employerContinuityStatement: 'EMPLOYER_CONTINUITY_STATEMENT',
  retirementPensionProof: 'RETIREMENT_PENSION_PROOF',
  disabilityPensionProof: 'DISABILITY_PENSION_PROOF',
  liabilitiesProof: 'LIABILITIES_PROOF',
  liabilitiesProofEmail: 'LIABILITIES_PROOF_EMAIL',
  liabilitiesProofScreenshot: 'LIABILITIES_PROOF_SCREENSHOT',
  valuation: 'VALUATION',
  defectRectificationReport: 'DEFECT_RECTIFICATION_REPORT',
  saleOffer: 'SALE_OFFER',
  preliminaryPurchaseAgreement: 'PRELIMINARY_PURCHASE_AGREEMENT',
  contractorAgreement: 'CONTRACTOR_AGREEMENT',
  renovationEstimation: 'RENOVATION_ESTIMATION',
  refinancingContract: 'REFINANCING_CONTRACT',
  constructionPermit: 'CONSTRUCTION_PERMIT',
  constructionProject: 'CONSTRUCTION_PROJECT',
  communicationsProject: 'COMMUNICATIONS_PROJECT',
  constructionsEstimation: 'CONSTRUCTION_ESTIMATION',
  realEstateValuationAct: 'REAL_ESTATE_VALUATION_ACT',
  maritalAgreement: 'MARITAL_AGREEMENT',
  employmentDocument: 'EMPLOYMENT_DOCUMENT',
};

export const REFINANCING_LIABILITY_TYPE = {
  livingPurposeMortgage: 'LIVING_PURPOSE_MORTGAGE',
  otherMortgage: 'OTHER_MORTGAGE',
  consumerLoan: 'CONSUMER_LOAN',
  creditCardOrRevolvingCredit: 'CREDIT_CARD_OR_REVOLVING_CREDIT',
  financialOrOperationalLeasing: 'FINANCIAL_OR_OPERATIONAL_LEASING',
  insurance: 'INSURANCE',
  warranty: 'WARRANTY',
  factoring: 'FACTORING',
  guaranty: 'GUARANTY',
  longTermLoan: 'LONG_TERM_LOAN',
  shortTermLoan: 'SHORT_TERM_LOAN',
  creditLine: 'CREDIT_LINE',
  overdraft: 'OVERDRAFT',
  creditLimitAtBusinessCreditCard: 'CREDIT_LIMIT_AT_BUSINESS_CREDIT_CARD',
  repurchaseAgreement: 'REPURCHASE_AGREEMENT',
  billOfExchange: 'BILL_OF_EXCHANGE',
  assetsSoldToCredit: 'ASSETS_SOLD_TO_CREDIT',
  others: 'OTHERS',
  deposits: 'DEPOSITS',
  financialLeasesHirePurchase: 'FINANCIAL_LEASES_HIRE_PURCHASE',
  financialLeasesOther: 'FINANCIAL_LEASES_OTHER',
  otherLoansLeaseback: 'OTHER_LOANS_LEASEBACK',
  tradeReceivablesFactoringWithTheRightOfRecourse: 'TRADE_RECEIVABLES_FACTORING_WITH_THE_RIGHT_OF_RECOURSE',
  tradeReceivablesFactoringWithoutTheRightOfRecourse: 'TRADE_RECEIVABLES_FACTORING_WITHOUT_THE_RIGHT_OF_RECOURSE',
  tradeReceivablesOtherTradeReceivables: 'TRADE_RECEIVABLES_OTHER_TRADE_RECEIVABLES',
  revolvingCredit: 'REVOLVING_CREDIT',
  otherLoansRenovation: 'OTHER_LOANS_RENOVATION',
  smallLoan: 'SMALL_LOAN',
  carLoan: 'carLoan',
  renovationLoan: 'RENOVATION_LOAN',
  regHirePurchase: 'REG_HIRE_PURCHASE',
  compHirePurchase: 'COMP_HIRE_PURCHASE',
  carHirePurchase: 'CAR_HIRE_PURCHASE',
  medicalLoan: 'MEDICAL_LOAN',
  refinancingLoan: 'REFINANCING_LOAN',
};

export const SALARY_INCOME_TYPES = {
  salary: 'SALARY',
  civilServant: 'CIVIL_SERVANT',
  foreignSalary: 'FOREIGN_SALARY',
};

export const BUSINESS_INCOME_TYPES = {
  selfEmployed: 'SELF_EMPLOYED',
  business: 'BUSINESS',
  sportOrPerformance: 'SPORT_OR_PERFORMANCE',
};

export const BENEFITS_INCOME_TYPES = {
  pension: 'PENSION',
  parentalBenefit: 'PARENTAL_BENEFIT',
};

export const MAIN_INCOME_TYPES = {
  ...SALARY_INCOME_TYPES,
  ...BUSINESS_INCOME_TYPES,
  ...BENEFITS_INCOME_TYPES,
};

export const ADDITIONAL_INCOME_TYPES = {
  dailyAllowances: 'DAILY_ALLOWANCES',
  rent: 'RENT',
  other: 'OTHER',
};

export const INCOME_TYPE_IN_FORM = {
  ...MAIN_INCOME_TYPES,
  ...ADDITIONAL_INCOME_TYPES,
};

export const ASSET_CONDITION = {
  used: 'USED',
  new: 'NEW',
};

export const CO_APPLICANT_ROLES = {
  SPOUSE: 'spouse',
  CO_BORROWER: 'coBorrower',
};

export const EMPLOYER_IS_MANDATORY_FOR_INCOME_TYPES = [
  MAIN_INCOME_TYPES.salary,
  MAIN_INCOME_TYPES.parentalBenefit,
  MAIN_INCOME_TYPES.business,
];

export const HOUSING_LOAN_CONTINUE_OPTION = Object.freeze({
  ContactCustomer: 'ContactCustomer',
  ProceedToIdentification: 'ProceedToIdentification',
  InsertRealEstateData: 'InsertRealEstateData',
});

export const PSD2_ACCOUNT_DATA_STATUS_SSN_ERROR = 'SSN_ERROR';
