<template>
  <bb-offer-thank-you-page
    ref="offerThankYouPage"
    class="offer"
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="submit"
  >
    <template slot="header">
      <bb-hero
        class="w-100"
        bleed="all"
        :title="$t('views.submitSpouseDetails.title')"
        :title-line-two="$t('views.submitSpouseDetails.titleLineTwo')"
      >
        <steps-overview />
      </bb-hero>
    </template>
    <offered-amount-box
      class="m-b-30"
      :amount="offer.offeredAmount"
    />
    <bb-input
      id="firstNameField"
      v-model="spouse.firstName"
      v-validate="{required: true, min: 2, max: 35, name: true, doesNotMatchCoApplicant: [null, spouse.surname, applicant.firstName, applicant.surname]}"
      name="first-name"
      :label="$t('application.applicant.firstName.label')"
      :data-vv-as="$t('application.applicant.firstName.label')"
    />
    <bb-input
      id="surnameField"
      v-model="spouse.surname"
      v-validate="{required: true, min: 2, max: 35, name: true, doesNotMatchCoApplicant: [spouse.firstName, null, applicant.firstName, applicant.surname]}"
      name="surname"
      :label="$t('application.applicant.surname.label')"
      :data-vv-as="$t('application.applicant.surname.label')"
    />
    <phone-input
      :entity="spouse"
      @input="phoneNumber = $event"
    />
    <bb-input
      id="emailField"
      v-model="spouse.email"
      v-validate="'required|email'"
      name="email"
      :label="$t('application.applicant.email.label')"
      :data-vv-as="$t('application.applicant.email.label')"
    />
  </bb-offer-thank-you-page>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { BbHero, BbOfferThankYouPage, BbInput } from '@bigbank/interface-components';
  import StepsOverview from '../components/StepsOverview';
  import OfferedAmountBox from '../components/OfferedAmountBox';
  import PhoneInput from '../components/PhoneInput';
  import enumsToOptionsMixin from '../mixins/enumsToOptionsMixin';

  export default {
    name: 'submit-spouse-details',

    components: {
      BbHero,
      BbOfferThankYouPage,
      StepsOverview,
      BbInput,
      OfferedAmountBox,
      PhoneInput,
    },

    mixins: [enumsToOptionsMixin],

    data() {
      return {
        spouse: {
          firstName: null,
          surname: null,
          email: null,
        },
        phoneNumber: {},
      };
    },

    computed: {
      ...mapGetters('processStep', [
        'applicant',
        'isLoading',
        'offer',
      ]),
    },

    methods: {
      ...mapActions('processStep', ['updateProcessStep']),

      submit() {
        const application = {
          spouse: {
            firstName: this.spouse.firstName,
            surname: this.spouse.surname,
            email: this.spouse.email,
            ...this.phoneNumber,
          },
        };

        this.updateProcessStep({ application });
      },
    },
  };
</script>
