<template>
  <bb-container
    v-if="show"
    fluid
    class="align-center p-x-0 m-t-30"
  >
    <div class="nm-x-30 m-y-25">
      <bb-separator dashed />
    </div>
    <bb-row class="align-items-center justify-content-between gutter-10">
      <bb-col
        class="f-smallest f-gotham-medium color-navy text-left col"
        md="15"
      >
        {{ documentName }}
      </bb-col>
      <bb-col
        class="text-right"
        col="auto"
      >
        <bb-button
          :id="`${documentType}Button`"
          tag="a"
          :href="documentFile"
          :download="documentFileName"
          :label="buttonText"
          size="sm"
        />
      </bb-col>
    </bb-row>
  </bb-container>
</template>

<script>
  import { BbButton,
           BbCol,
           BbContainer,
           BbRow,
           BbSeparator } from '@bigbank/interface-components';
  import { DOWNLOAD_DOCUMENT_TYPE_LEASING,
           DOWNLOAD_DOCUMENT_TYPE_INCOME,
           DOWNLOAD_DOCUMENT_TYPES } from '@/constants';

  const POE_BASE = 'components.documentDownload';
  export const DOCUMENT_ASSET_NAME_BY_TYPE = {
    [DOWNLOAD_DOCUMENT_TYPE_LEASING]: 'commercial_offer_for_used_cars',
    [DOWNLOAD_DOCUMENT_TYPE_INCOME]: 'income_proof_requirements',
  };

  export default {
    name: 'document-download',

    components: {
      BbButton,
      BbCol,
      BbContainer,
      BbRow,
      BbSeparator,
    },

    props: {
      documentType: {
        type: String,
        required: true,
        validator: value => DOWNLOAD_DOCUMENT_TYPES.includes(value),
      },
    },

    computed: {
      buttonText() {
        return this.$t(`${POE_BASE}.${this.documentType}.buttonText`);
      },
      documentFile() {
        return require(`@/assets/${DOCUMENT_ASSET_NAME_BY_TYPE[this.documentType]}.docx`)?.default;
      },
      documentFileName() {
        return `${this.documentName}.docx`;
      },
      documentName() {
        return this.$t(`${POE_BASE}.${this.documentType}.documentName`);
      },
      show() {
        return DOWNLOAD_DOCUMENT_TYPES.includes(this.documentType);
      },
    },
  };
</script>
