import actions from './actions';
import router from '@/router';
import { SUPPORTED_AUTH_AND_SIGNING_METHODS,
  MOBILE_ID,
  SMART_ID } from '@/constants/verification';
import { SET_LOADING } from '../signing/types';
import { ROLE } from '@/constants';

const state = {
  allowedMethods: [
    MOBILE_ID,
    SMART_ID,
  ],
  bankLinkMethods: [],
  loading: false,
};

const getters = {
  allowedMethods: state => state.allowedMethods,
  bankLinkMethods: state => state.bankLinkMethods,
  isLoading: state => state.loading,
  // @Deprecated: Use 'processStep/isActivePersonRoleCoApplicant' instead.
  isCoApplicant: () => !!(router.currentRoute.query.role && router.currentRoute.query.role === ROLE.CO_APPLICANT),
};

export const SET_AUTHENTICATION_METHODS = 'SET_AUTHENTICATION_METHODS';

const mutations = {
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_AUTHENTICATION_METHODS](state, list) {
    state.bankLinkMethods = list.map(bankLinkMethod => ({
      ...SUPPORTED_AUTH_AND_SIGNING_METHODS.find(method => method.type === bankLinkMethod.type),
      url: bankLinkMethod.url,
      ...(bankLinkMethod.bic && { bic: bankLinkMethod.bic }),
    }));
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
