import clamp from 'lodash/clamp';
import round from 'lodash/round';
import { MAX_NUM_VALUE, MAX_PERIOD_VALUE } from '@/constants';
import { mapGetters } from 'vuex';

/**
 * @mixin Handle goods price minimum & maximum amount restrictions based off product.
 */
export default {
  data() {
    return { selfFinancingAmount: null };
  },

  computed: {
    ...mapGetters('products', ['getProductData']),

    productMinPeriod() {
      return this.getProductData?.minPeriod
        ? round(this.getProductData.minPeriod, 0)
        : 0;
    },

    productMaxPeriod() {
      return round(
        clamp(
          this.getProductData?.maxPeriod
            ? this.getProductData.maxPeriod
            : MAX_PERIOD_VALUE,
          this.productMinPeriod,
          MAX_NUM_VALUE,
        ),
        0,
      );
    },

    productMinAmount() {
      return this.getProductData?.minAmount
        ? round(this.getProductData.minAmount, 2)
        : 0;
    },
    productMaxAmount() {
      return round(
        clamp(
          this.getProductData?.maxAmount
            ? this.getProductData.maxAmount
            : MAX_NUM_VALUE,
          this.productMinAmount,
          MAX_NUM_VALUE,
        ),
        2,
      );
    },
    goodsPriceMin() {
      return this.getProductData?.minGoodsPrice
        ? round(this.getProductData.minGoodsPrice, 2)
        : 0;
    },
    goodsPriceMax() {
      return round(
        clamp(
          this.getProductData?.maxGoodsPrice
            ? this.getProductData.maxGoodsPrice
            : MAX_NUM_VALUE,
          this.goodsPriceMin,
          MAX_NUM_VALUE,
        ),
        2,
      );
    },
  },
};
