<template>
  <bb-application-page
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isLoading,
      loading: isLoading,
    }"
    class="spouse-or-co-borrower-incomes-liabilities"
    @submit="submit"
  >
    <bb-public-header
      slot="header"
      :title="$t(`views.${viewName}.title`)"
      icon="client-coborrower"
    />

    <bb-radio
      v-if="customData.isPostMarriageAgreementOptionsVisible"
      id="postMarriageAgreement"
      v-model="doesPostMarriageAgreementExist"
      v-validate="'required'"
      equal-width
      name="post-marriage-agreement"
      :label="$t('views.postMarriageAgreement.label')"
      :data-vv-as="$t('views.postMarriageAgreement.label')"
      :options="postMarriageAgreementOptions"
    />

    <bb-upload
      v-if="showUploadDocumentsForm"
      v-model="uploadedDocuments"
      v-validate="'required|uploadedFilesAreValid'"
      class="m-t-30"
      name="file"
      flavor="user-selfcontained"
      :allowed-types="FILE_UPLOAD_SETTINGS.allowedTypes"
      :allowed-types-label="$t('views.postMarriageAgreementUpload.allowedTypesLabel')"
      :capture-label="$t('views.postMarriageAgreementUpload.captureLabel')"
      :choose-label="$t('views.postMarriageAgreementUpload.chooseLabel')"
      :duplicate-file-label="$t('views.documents.duplicateFileLabel')"
      :empty-file-label="$t('views.documents.emptyFileLabel')"
      :heading="$t('views.postMarriageAgreementUpload.heading')"
      :size-limit="FILE_UPLOAD_SETTINGS.sizeLimit"
      :size-limit-label="$t('views.postMarriageAgreementUpload.sizeLimitLabel')"
    >
      <template slot="browseLabel">
        {{ $t('views.postMarriageAgreementUpload.browseLabel') }}
      </template>
    </bb-upload>
    <template v-else-if="showIncomesAndLiabilitiesForm">
      <bb-radio
        v-if="!isCoBorrowerTask"
        id="forFamilyUseRadio"
        v-model="forFamilyUse"
        v-validate="'required'"
        equal-width
        help-active
        name="for-family-use"
        :help-text="$t('application.forFamilyUse.helpText')"
        :label="$t('application.forFamilyUse.label')"
        :data-vv-as="$t('application.forFamilyUse.label')"
        :options="forFamilyUseOptions"
      />

      <div
        v-if="!isCoBorrowerTask"
        class="loan-purpose-info-box m-t-30 p-x-30 p-t-30 p-b-20 bg-gray-10 f-color-gray-80 f-small text-center"
      >
        <h4 class="color-navy f-gotham-book nm-t-10 m-b-15">
          {{ $t('views.spouseOrCoBorrowerIncomesLiabilities.loanPurposeInfoHeader') }}
        </h4>
        {{ loanPurposeInfoText }}
      </div>

      <template v-if="isCoBorrowerTask || forFamilyUse === 'true'">
        <bb-input
          v-if="showCoBorrowerEmailField"
          v-model="email"
          v-validate="'required|email'"
          name="coBorrowerEmailField"
          :label="$t('views.coBorrowerIncomesLiabilities.email')"
          :data-vv-as="$t('views.coBorrowerIncomesLiabilities.email')"
        />

        <!-- TODO: Next ticket that touches this file should refactor this entire component into smaller components. -->
        <template v-if="showCoborrowerFinancialFields">
          <h4 class="f-max-pro-demi-bold m-y-30 text-center f-color-navy">
            {{ $t(`views.${viewName}.incomes`) }}
          </h4>

          <bb-form-repeater
            id="incomes"
            v-model="incomes"
            hide-initial-row
            name="incomes"
            :add-button-label="$t('views.incomesLiabilities.addIncomes')"
            :row-limit="10"
          >
            <template
              slot="highlights"
              slot-scope="row"
            >
              <div>{{ enumToText(row.model.typeInForm) }}</div>
              <div>{{ formatAmount(row.model.declaredNetMonthlyAmount) }}</div>
            </template>
            <template
              slot="editLabel"
              slot-scope="row"
            >
              {{ enumToText(row.model.typeInForm) || $t('views.incomesLiabilities.addIncomesTitle') }}
            </template>
            <template
              slot="fields"
              slot-scope="row"
            >
              <bb-select
                :id="'income_type_' + row.index"
                v-model="row.model.typeInForm"
                v-validate="'required'"
                :label="$t('application.income.typeInForm.label')"
                :data-vv-as="$t('application.income.typeInForm.label')"
                :selected="row.model.typeInForm"
                noempty
                name="income-type"
              >
                <optgroup
                  v-for="group in incomeTypeGroups"
                  :key="group.name"
                  :label="group.name"
                >
                  <option
                    v-for="option in group.options"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </optgroup>
              </bb-select>
              <bb-input
                :id="'income_amount_' + row.index"
                v-model="row.model.declaredNetMonthlyAmount"
                v-validate="`required|amount`"
                :label="$t('application.income.declaredNetMonthlyAmount.label')"
                :data-vv-as="$t('application.income.declaredNetMonthlyAmount.label')"
                name="declared-net-monthly"
                help-active
                :help-text="$t('application.income.declaredNetMonthlyAmount.helpText')"
                add-on="€"
              />
            </template>
          </bb-form-repeater>

          <income-bank
            v-model="incomeReceivedToBankInBusinessUnitCountry"
            :incomes="incomes"
          />

          <div class="m-b-50">
            <bb-input
              v-if="isEmployerMandatory(incomes, isProductHousingLoan)"
              v-model="employment.companyName"
              v-validate="'required|min:2|max:200'"
              name="employer"
              type="text"
              maxlength="200"
              autocomplete="off"
              :label="$t(`application.${coApplicantRole}.employment.companyName.label`)"
              :data-vv-as="$t(`application.${coApplicantRole}.employment.companyName.label`)"
            />
          </div>

          <div class="bg-gray-10 m-t-10">
            <bb-checkbox
              id="incomeMayDecreaseField"
              ref="income-ref"
              v-model="incomeMayDecrease"
              background
              name="income-may-decrease"
              :label="$t(`application.${coApplicantRole}.incomeMayDecrease.label`)"
            />
            <bb-input
              v-if="incomeMayDecrease"
              id="incomeMayDecreaseInput"
              v-model="probableDecreasedIncome"
              v-validate="`required_if:income-ref,true|amount`"
              class="p-x-20 p-b-20"
              :label="$t('application.applicant.probableDecreasedIncome.label')"
              :data-vv-as="$t('application.applicant.probableDecreasedIncome.label')"
              name="probable-decreased-income"
              add-on="€"
            />
          </div>

          <h4 class="f-max-pro-demi-bold text-center m-y-30 f-color-navy">
            {{ $t(`views.${viewName}.obligations`) }}
          </h4>

          <bb-checkbox
            id="noLiabilitiesCheckbox"
            v-model="noLiabilities"
            name="no-liabilities-checkbox"
            background
            :label="$t(`application.liability.${coApplicantRole}.noLiabilities`)"
          />

          <bb-form-repeater
            v-if="!noLiabilities"
            id="obligations"
            v-model="liabilities"
            hide-initial-row
            class="m-t-10"
            name="obligations"
            :add-button-label="$t('views.incomesLiabilities.addObligations')"
            :row-limit="10"
          >
            <template
              slot="highlights"
              slot-scope="row"
            >
              <div>{{ enumToText(row.model.type) }}</div>
              <div>{{ formatAmount(row.model.declaredMonthlyPayment) }}</div>
            </template>
            <template
              slot="editLabel"
              slot-scope="row"
            >
              {{ enumToText(row.model.type) }}
            </template>
            <template
              slot="fields"
              slot-scope="row"
            >
              <bb-select
                :id="'obligation_type_' + row.index"
                v-model="row.model.type"
                v-validate="'required'"
                :options="liabilityTypeOptions"
                :label="$t('application.liability.type.label')"
                :data-vv-as="$t('application.liability.type.label')"
                name="liabilities-type"
              />
              <bb-input
                :id="'obligation_amount_' + row.index"
                v-model="row.model.declaredMonthlyPayment"
                v-validate="`required|amount`"
                type="text"
                :label="$t('application.liability.declaredMonthlyPayment.label')"
                :data-vv-as="$t('application.liability.declaredMonthlyPayment.label')"
                name="declared-monthly-payment"
                add-on="€"
              />
            </template>
          </bb-form-repeater>
        </template>
      </template>
    </template>
  </bb-application-page>
</template>

<script>
  import { BbInput, BbRadio, BbUpload } from '@bigbank/interface-components';
  import '@bigbank/interface-components/dist/svg/client/coborrower';
  import { mapGetters, mapActions } from 'vuex';
  import enumsToOptionsMixin from '@/mixins/enumsToOptionsMixin';
  import incomesLiabilitiesMixin from '@/mixins/incomesLiabilitiesMixin';
  import employerCompanyMixin from '@/mixins/employerCompanyMixin';
  import { CO_APPLICANT_ROLES, DOCUMENT_TYPE } from '@/constants/application';
  import { FILE_UPLOAD_SETTINGS } from '@/constants/requiredDocuments';
  import { PERSON } from '@/constants';
  import IncomeBank from '@/components/IncomeBank';

  export default {
    name: 'spouse-or-co-borrower-incomes-liabilities',

    components: {
      BbInput,
      BbRadio,
      BbUpload,
      IncomeBank,
    },

    mixins: [
      enumsToOptionsMixin,
      incomesLiabilitiesMixin,
      employerCompanyMixin,
    ],

    props: {
      coApplicantRole: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        uploadedDocuments: null,
        forFamilyUse: 'true',
        email: '',
        doesPostMarriageAgreementExist: null,
        ...this.$store.getters['processStep/coApplicantFinanceData'],
        FILE_UPLOAD_SETTINGS,
      };
    },

    computed: {
      ...mapGetters('processStep', [
        'customData',
        'isProductHousingLoan',
      ]),
      isCoBorrowerTask() {
        return this.coApplicantRole === CO_APPLICANT_ROLES.CO_BORROWER;
      },
      showCoBorrowerEmailField() {
        return this.isCoBorrowerTask && this.isProductHousingLoan;
      },
      viewName() {
        return this.isCoBorrowerTask ? 'coBorrowerIncomesLiabilities' : 'spouseOrCoBorrowerIncomesLiabilities';
      },
      postMarriageAgreementOptions() {
        return [
          {
            text: this.$t('views.postMarriageAgreement.options.yes'),
            value: 'true',
          },
          {
            text: this.$t('views.postMarriageAgreement.options.no'),
            value: 'false',
          },
        ];
      },
      forFamilyUseOptions() {
        return [
          {
            text: this.$t('application.forFamilyUse.options.true'),
            value: 'true',
          },
          {
            text: this.$t('application.forFamilyUse.options.false'),
            value: 'false',
          },
        ];
      },
      loanPurposeInfoText() {
        return this.forFamilyUse === 'true'
          ? this.$t('views.spouseOrCoBorrowerIncomesLiabilities.loanPurposeInfoTextForFamilyUse')
          : this.$t('views.spouseOrCoBorrowerIncomesLiabilities.loanPurposeInfoTextForPersonalUse');
      },
      showUploadDocumentsForm() {
        return this.customData.isPostMarriageAgreementOptionsVisible &&
          this.doesPostMarriageAgreementExist === 'true' &&
          !this.showIncomesAndLiabilitiesForm;
      },
      showIncomesAndLiabilitiesForm() {
        return !this.customData.isPostMarriageAgreementOptionsVisible ||
          this.doesPostMarriageAgreementExist === 'false';
      },
      showCoborrowerFinancialFields() {
        return this.isProductHousingLoan
          ? this.doesPostMarriageAgreementExist === 'true'
          : true;
      },
    },
    watch: {
      forFamilyUse(newValue) {
        if (newValue === 'false') {
          this.incomeMayDecrease = null;
          this.incomes = [];
          this.liabilities = [];
          this.noLiabilities = null;
          this.probableDecreasedIncome = null;
        }
      },
    },
    methods: {
      ...mapActions('processStep', [
        'uploadDocuments',
        'updateProcessWithCalculatorData',
      ]),

      submitIncomesAndLiabilities() {
        const forFamilyUse = this.forFamilyUse === 'true';
        const application = this.isCoBorrowerTask ? {} : { forFamilyUse };

        if (this.isCoBorrowerTask || forFamilyUse) {
          const liabilities = this.noLiabilities
            ? []
            : this.liabilities.map(liability => (
              {
                ...liability,
                declaredMonthlyPayment: this.unlocalizeLtNumber(liability.declaredMonthlyPayment),
              }
            ));

          const incomes = this.incomes.map(income => ({
            ...income,
            declaredNetMonthlyAmount: this.unlocalizeLtNumber(income.declaredNetMonthlyAmount),
          }));

          const incomeMayDecrease = !!this.incomeMayDecrease;

          application[this.coApplicantRole] = {
            incomeMayDecrease,
            liabilities,
            incomes,
            ...(this.showCoBorrowerEmailField && { email: this.email }),
            ...(
              // eslint-disable-next-line array-element-newline, array-bracket-newline
              [undefined, null].includes(this.incomeReceivedToBankInBusinessUnitCountry)
                ? {}
                : { incomeReceivedToBankInBusinessUnitCountry: this.incomeReceivedToBankInBusinessUnitCountry }
            ),
          };

          if (this.isEmployerMandatory(this.incomes, this.isProductHousingLoan)) {
            application[this.coApplicantRole].employment = this.employment;
          }

          if (incomeMayDecrease) {
            application[this.coApplicantRole].probableDecreasedIncome = this.unlocalizeLtNumber(this.probableDecreasedIncome);
          }
        }

        this.updateProcessWithCalculatorData(application);
      },

      async submitUploadedDocuments () {
        const files = this.uploadedDocuments.map(({ file }) => file);

        await this.uploadDocuments({
          files,
          documentOwner: PERSON.APPLICANT,
          documentType: DOCUMENT_TYPE.maritalAgreement,
        });
        await this.updateProcessWithCalculatorData({});
      },

      submit() {
        if (this.showUploadDocumentsForm) {
          this.submitUploadedDocuments();
        } else {
          this.submitIncomesAndLiabilities();
        }
      },
    },
  };
</script>
