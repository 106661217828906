<template>
  <bb-offer-thank-you-page
    ref="offerThankYouPage"
    class="offer"
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="submit"
  >
    <template slot="header">
      <bb-hero
        class="w-100"
        bleed="all"
        :title="$t('views.offerReviewConditionalNegative.title')"
        :title-line-two="$t(`views.offerReviewConditionalNegative.${person}.titleLineTwo`)"
      >
        <steps-overview />
      </bb-hero>
    </template>
    <offered-amount-box
      class="m-b-30"
      :amount="application.initialLoanAmount"
    />
    <p class="text-center f-small f-medium m-b-30">
      {{ $t(`views.offerReviewConditionalNegative.${person}.description`) }}
    </p>
    <bb-input
      id="firstNameField"
      v-model="entity.firstName"
      v-validate="{required: true, min: 2, max: 35, name: true, doesNotMatchCoApplicant: [null, entity.surname, applicant.firstName, applicant.surname]}"
      name="first-name"
      :label="$t('application.applicant.firstName.label')"
      :data-vv-as="$t('application.applicant.firstName.label')"
    />
    <bb-input
      id="surnameField"
      v-model="entity.surname"
      v-validate="{required: true, min: 2, max: 35, name: true, doesNotMatchCoApplicant: [entity.firstName, null, applicant.firstName, applicant.surname]}"
      name="surname"
      :label="$t('application.applicant.surname.label')"
      :data-vv-as="$t('application.applicant.surname.label')"
    />
    <phone-input
      :entity="entity"
      @input="phoneNumber = $event"
    />
    <bb-input
      id="emailField"
      v-model="entity.email"
      v-validate="'required|email'"
      name="email"
      :label="$t('application.applicant.email.label')"
      :data-vv-as="$t('application.applicant.email.label')"
    />
    <template slot="form-extras">
      <p
        v-if="!isCoBorrowerTask"
        class="text-center f-small f-medium"
      >
        {{ $t('views.offerReviewConditionalNegative.disclaimer') }}
      </p>
      <div class="text-center m-t-30">
        <bb-button
          id="applicationDiscontinueButton"
          class="f-small"
          display="link"
          color="gray"
          @click="discontinueApplication"
        >
          {{ $t('views.offerReviewConditionalNegative.discontinue') }}
        </bb-button>
      </div>
    </template>
  </bb-offer-thank-you-page>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex';
  import { BbHero, BbOfferThankYouPage, BbInput, BbButton } from '@bigbank/interface-components';
  import StepsOverview from '../components/StepsOverview';
  import OfferedAmountBox from '../components/OfferedAmountBox';
  import PhoneInput from '../components/PhoneInput';
  import enumsToOptionsMixin from '../mixins/enumsToOptionsMixin';
  import { taskDefinitionKeys } from '@/constants';

  export default {
    name: 'offer-review-condition-negative',

    components: {
      BbHero,
      BbOfferThankYouPage,
      StepsOverview,
      BbInput,
      BbButton,
      OfferedAmountBox,
      PhoneInput,
    },

    mixins: [enumsToOptionsMixin],

    data() {
      return {
        entity: {
          firstName: null,
          surname: null,
          email: null,
        },
        phoneNumber: {},
      };
    },

    computed: {
      ...mapGetters('processStep', [
        'applicant',
        'isLoading',
        'definitionKey',
        'coBorrowerData',
      ]),
      ...mapState('processStep', ['application']),

      isCoBorrowerTask() {
        return this.definitionKey === taskDefinitionKeys.ReviewRejectedBringCoBorrower;
      },
      person() {
        return this.isCoBorrowerTask ? 'coBorrower' : 'spouse';
      },
    },

    created() {
      this.entity = {
        ...this.entity,
        ...(this.isCoBorrowerTask
          ? this.$store.getters['processStep/coBorrowerData']
          : {}
        ),
      };
    },

    methods: {
      ...mapActions('processStep', ['updateProcessStep']),

      discontinueApplication() {
        this.updateProcessStep({
          application: {
            ...(this.isCoBorrowerTask
              ? { involveCoBorrower: false }
              : { involveSpouse: false }),
          },
        });
      },

      submit() {
        const application = {
          [this.person]: {
            firstName: this.entity.firstName,
            surname: this.entity.surname,
            email: this.entity.email,
            ...this.phoneNumber,
          },
          ...(this.isCoBorrowerTask
            ? { involveCoBorrower: true }
            : { involveSpouse: true }),
        };

        this.updateProcessStep({ application });
      },
    },
  };
</script>
