<template>
  <div class="offer-calculator">
    <bb-calculator
      ref="calculator"
      name="offer-calculator"
      :locale="$i18n.locale"
      :period="edited.period"
      :min-period="offer.customerMinPeriod"
      :max-period="offer.customerMaxPeriod"
      :period-step="1"
      :interest-rate="getSelectedOption.interestRate"
      v-bind="configProps"
      @input="onInput"
    />
    <bb-ticket-stub class="m-t-30 nm-x-30 nm-b-30">
      <bb-button
        display="ticket"
        class="p-y-15"
        name="submit-calculator"
        :label="$t('views.offer.updateMyOffer')"
        :disabled="!hasChanged"
        @click="submitCalculator"
      />
    </bb-ticket-stub>
  </div>
</template>

<script>
  import { BbButton, BbCalculator, BbTicketStub } from '@bigbank/interface-components';
  import { mapGetters } from 'vuex';
  import isNumber from 'lodash/isNumber';
  import clamp from 'lodash/clamp';
  import productSelfFinancingAmountMixin from '@/mixins/productSelfFinancingAmountMixin';

  export default {
    name: 'offer-calculator',

    components: {
      BbButton,
      BbCalculator,
      BbTicketStub,
    },

    mixins: [productSelfFinancingAmountMixin],

    props: {
      price: Number,
      selfFinancingAmount: Number,
    },

    data() {
      return {
        edited: {
          amount: null,
          period: null,
          price: null,
          selfFinancingAmount: null,
        },
      };
    },

    computed: {
      ...mapGetters('offer', ['getSelectedOption']),
      ...mapGetters('products', ['getProductData']),
      ...mapGetters('processStep', [
        'offer',
        'isOfferDecisionApproved',
        'isProductCarFinanceLease',
      ]),

      showSelfFinancing() {
        return !!this.isProductCarFinanceLease;
      },

      minSelfFinancingAmount() {
        if (this.isOfferDecisionApproved && isNumber(this.offer?.minSelfFinancingAmount)) {
          return this.offer.minSelfFinancingAmount;
        }

        return this.minSelfFinancingAmountForNonApprovedOffer < this.minSelfFinancingAmountByProduct
          ? this.minSelfFinancingAmountByProduct
          : this.minSelfFinancingAmountForNonApprovedOffer;
      },

      minSelfFinancingAmountForNonApprovedOffer() {
        if (!this.isOfferDecisionApproved && isNumber(this.edited.price) && isNumber(this.offer?.customerMaxAmount)) {

          return (this.edited.price - this.offer.customerMaxAmount) > 0
            ? this.edited.price - this.offer.customerMaxAmount
            : 0;
        }

        return 0;
      },

      minPriceByProduct() {
        return this.getProductData?.minGoodsPrice;
      },

      maxPriceByProduct() {
        return this.getProductData?.maxGoodsPrice;
      },

      minSelfFinancingAmountByProduct() {
        return this.getMinSelfFinancingAmountFromPriceByProduct(this.edited.price);
      },

      maxSelfFinancingAmount() {
        return this.edited.price - this.offer.customerMinAmount;
      },

      selfFinancingAmountLabel() {
        return `${this.$t('views.offer.downpaymentAmount')} (${this.$n(this.minSelfFinancingAmount)} - ${this.$n(this.maxSelfFinancingAmount, 'currency')})`;
      },

      selfFinancingPercentLabel() {
        return this.edited.selfFinancingAmount
          ? `${this.$refs.calculator.minSelfFinancingPercent}% - ${this.$refs.calculator.maxSelfFinancingPercent}%`
          : '';
      },

      additionalPeriodicCost() {
        let cost = 0;

        if (this.offer.administrationFee || this.offer.administrationFeePercent) {
          if (isNumber(this.offer.administrationFee)) {
            cost += this.offer.administrationFee;
          } else if (isNumber(this.offer.administrationFeePercent)) {
            cost += this.edited.amount * this.offer.administrationFeePercent * 0.01;
          }
        }

        if (this.offer.conclusionFee || this.offer.conclusionFeePercent) {
          if (isNumber(this.offer.conclusionFee) && this.edited.period > 0) {
            cost += this.offer.conclusionFee / this.edited.period;
          } else if (isNumber(this.offer.conclusionFeePercent) && this.edited.period > 0) {
            cost += (this.edited.amount * this.offer.conclusionFeePercent * 0.01) / this.edited.period;
          }
        }

        return Math.round(cost * 100) / 100;
      },

      configProps() {
        return this.showSelfFinancing
          ? {
            bigSliders: true,
            showLoanAmount: true,
            flavorSelfFinancing: true,
            priceDisabled: this.isOfferDecisionApproved,
            price: this.edited.price,
            minPrice: this.minPriceByProduct,
            maxPrice: this.maxPriceByProduct,
            priceStep: 50,
            selfFinancingAmount: this.edited.selfFinancingAmount,
            minSelfFinancingAmount: this.minSelfFinancingAmount,
            maxSelfFinancingAmount: this.maxSelfFinancingAmount,
            selfFinancingAmountStep: 1,
            selfFinancingPercentStep: 0.1,
            additionalPeriodicCost: this.additionalPeriodicCost,
            labels: {
              loanAmount: { title: this.$t('views.offer.yourLoan') },
              price: { title: this.$t('views.offer.vehiclePrice') },
              selfFinancingAmount: { title: this.selfFinancingAmountLabel },
              selfFinancingPercent: { title: this.selfFinancingPercentLabel },
            },
          }
          : {
            sliders: true,
            amount: this.edited.amount,
            minAmount: this.offer.customerMinAmount,
            maxAmount: this.offer.customerMaxAmount,
            amountStep: 50,
          };
      },

      hasChanged() {
        return this.edited.amount !== this.getSelectedOption.amount ||
          this.edited.period !== this.getSelectedOption.period ||
          this.edited.price !== this.price ||
          this.edited.selfFinancingAmount !== this.selfFinancingAmount;
      },
    },

    methods: {
      onInput({ amount, period, price, selfFinancingAmount }) {
        this.edited = {
          amount: this.showSelfFinancing ? Math.round((price - selfFinancingAmount) * 100) / 100 : amount,
          period,
          price: clamp(price, this.minPriceByProduct, this.maxPriceByProduct),
          selfFinancingAmount: Math.round(selfFinancingAmount * 100) / 100,
        };
      },

      submitCalculator() {
        this.$emit('submit', this.edited);
      },

      setInitialValues() {
        this.edited = {
          amount: this.getSelectedOption.amount,
          period: this.getSelectedOption.period,
          price: this.price,
          selfFinancingAmount: Math.round(this.selfFinancingAmount * 100) / 100,
        };
      },
    },
  };
</script>
