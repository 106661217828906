'use strict';

import SectionType from './SectionType';

export default class Section {
  constructor (sectionLabel, options, key, fileCountLimit, mandatory) {
    this.title = sectionLabel;
    this.key = key;
    this.fixedFileCount = options.length;
    this.types = options.map(requiredTypes => new SectionType(requiredTypes));
    this.fileCountLimit = fileCountLimit;
    this.mandatory = mandatory;
  }
}
