<template>
  <section>
    <bb-select
      id="county-field"
      v-model="address.county"
      v-validate="'required'"
      name="county"
      :label="$t('application.applicant.address.county.label')"
      :data-vv-as="$t('application.applicant.address.county.label')"
      :options="counties"
    />
    <bb-input
      id="city-field"
      v-model="address.city"
      v-validate="'required|commonSymbols|min:2|max:50'"
      name="city"
      :label="$t('application.applicant.address.city.label')"
      :data-vv-as="$t('application.applicant.address.city.label')"
      @change="getNewPostalCode"
    />
    <bb-input
      id="street-field"
      v-model="address.street"
      v-validate="'required|commonSymbols|min:2|max:50'"
      name="street"
      :label="$t('application.applicant.address.street.label')"
      :data-vv-as="$t('application.applicant.address.street.label')"
      @change="getNewPostalCode"
    />
    <bb-input
      id="house-number-field"
      v-model="address.houseNumber"
      v-validate="'required|houseOrFlatNumber|min:1|max:10'"
      name="house-number"
      :label="$t('application.applicant.address.houseNumber.label')"
      :data-vv-as="$t('application.applicant.address.houseNumber.label')"
      @change="getNewPostalCode"
    />
    <bb-input
      id="flat-number-field"
      v-model="address.flatNumber"
      v-validate="'houseOrFlatNumber|min:1|max:10'"
      name="flat-number"
      :label="$t('application.applicant.address.flatNumber.label')"
      :data-vv-as="$t('application.applicant.address.flatNumber.label')"
    />
    <bb-masked-input
      id="post-code-field"
      v-model="address.postCode"
      v-validate="'required|postCode'"
      :disabled="isLoading"
      help-active
      name="post-code"
      data-vv-validate-on="input|blur"
      :label="$t('application.applicant.address.postCode.label')"
      :data-vv-as="$t('application.applicant.address.postCode.label')"
      mask="LT-11111"
    >
      <template #tooltipText>
        <bb-link
          :to="POST_CODE_HELP_URL"
          name="post-code-help"
          target="_blank"
          :label="$t('application.applicant.address.postCode.helpText')"
        />
      </template>
    </bb-masked-input>

    <div
      v-if="showMarketingConsent"
      class="m-t-30"
    >
      <bb-checkbox
        id="marketingConsentField"
        v-model="marketingConsent"
        background
        name="marketing-consent"
      >
        {{ $t('application.marketingConsent.label') }}
      </bb-checkbox>

      <marketing-consent-modal />
    </div>
  </section>
</template>

<script>
  import { BbCheckbox,
           BbInput,
           BbLink,
           BbMaskedInput,
           BbSelect } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';
  import countiesMixin from '@/mixins/countiesMixin';
  import MarketingConsentModal from '@/components/MarketingConsentModal';
  import { POST_CODE_HELP_URL } from '@/constants';

  export default {
    name: 'address-details',

    components: {
      BbInput,
      BbMaskedInput,
      BbSelect,
      BbLink,
      BbCheckbox,
      MarketingConsentModal,
    },

    mixins: [countiesMixin],

    inject: ['$validator'],

    data() {
      return {
        POST_CODE_HELP_URL,
        address: this.$store.getters['processStep/addressData'].contactAddress,
        marketingConsent: this.$store.getters['processStep/addressData'].marketingConsent,
      };
    },

    computed: {
      ...mapGetters('processStep', [
        'addressData',
        'isActivePersonRoleCoApplicant',
        'isLoading',
      ]),

      showMarketingConsent() {
        return !this.isActivePersonRoleCoApplicant && !this.addressData.marketingConsent;
      },
    },

    watch: {
      address: {
        deep: true,
        handler: 'emitUpdate',
      },

      marketingConsent: 'emitUpdate',
    },

    created() {
      this.sanitizeCounty();
    },

    methods: {
      ...mapActions('processStep', ['fetchPostalCode']),

      sanitizeCounty() {
        if (this.address.county && !this.counties.some(({ value }) => value === this.address.county)) {
          this.address.county = null;
        }
      },

      emitUpdate() {
        this.$emit('update', {
          address: {
            ...this.address,
            postCode: this.address?.postCode?.replace('LT-', ''),
          },
          marketingConsent: !!this.marketingConsent,
        });
      },

      async getNewPostalCode() {
        const { city, street, houseNumber } = this.address;

        if (!city || !street || !houseNumber) {
          return;
        }

        const postalCode = await this.fetchPostalCode({
          city,
          street,
          houseNumber,
        });

        if (postalCode) {
          this.address.postCode = postalCode;
        }
      },
    },
  };
</script>
