<template>
  <div class="p-x-10">
    <bb-list-group class="m-t-20">
      <bb-list-item
        v-for="(saleArgument, i) in saleArguments"
        :key="i"
        condensed
        :title="saleArgument"
        :title-uppercase="false"
        bullet
      />
    </bb-list-group>
  </div>
</template>
<script>
  import { BbListGroup, BbListItem } from '@bigbank/interface-components';

  export default {
    name: 'product-card-content',
    components: {
      BbListGroup,
      BbListItem,
    },
    props: {
      /**
       * Product sales arguments as strings values
      */
      saleArguments: Object | Array,
    },
  };
</script>
