<template>
  <bb-application-page
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isLoading,
      loading: isLoading,
    }"
    class="income-liabilities"
    @submit="submit"
  >
    <bb-public-header
      slot="header"
      :title="$t('views.incomesLiabilities.title')"
      icon="client-finance"
    />

    <bb-form-repeater
      id="incomes"
      v-model="incomes"
      name="incomes"
      :add-button-label="$t('views.incomesLiabilities.addIncomes')"
      :row-limit="10"
      hide-initial-row
    >
      <template
        slot="highlights"
        slot-scope="row"
      >
        <div>{{ enumToText(row.model.typeInForm) }}</div>
        <div>{{ formatAmount(row.model.declaredNetMonthlyAmount) }}</div>
      </template>
      <template
        slot="editLabel"
        slot-scope="row"
      >
        {{ enumToText(row.model.typeInForm) }}
      </template>
      <template
        slot="fields"
        slot-scope="row"
      >
        <bb-select
          :id="'income_type_' + row.index"
          v-model="row.model.typeInForm"
          v-validate="'required'"
          :label="$t('application.income.typeInForm.label')"
          :data-vv-as="$t('application.income.typeInForm.label')"
          :selected="row.model.typeInForm"
          noempty
          name="income-type"
        >
          <optgroup
            v-for="group in incomeTypeGroups"
            :key="group.name"
            :label="group.name"
          >
            <option
              v-for="option in group.options"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </optgroup>
        </bb-select>
        <bb-input
          :id="'income_amount_' + row.index"
          v-model="row.model.declaredNetMonthlyAmount"
          v-validate="`required|amount`"
          :label="$t('application.income.declaredNetMonthlyAmount.label')"
          :data-vv-as="$t('application.income.declaredNetMonthlyAmount.label')"
          name="declared-net-monthly"
          help-active
          :help-text="$t('application.income.declaredNetMonthlyAmount.helpText')"
          add-on="€"
        />
      </template>
    </bb-form-repeater>

    <income-bank
      v-model="incomeReceivedToBankInBusinessUnitCountry"
      :incomes="incomes"
    />

    <div class="m-b-50">
      <bb-input
        v-if="isEmployerMandatory(incomes, isProductHousingLoan)"
        v-model="employment.companyName"
        v-validate="'required|min:2|max:200'"
        name="employer"
        type="text"
        maxlength="200"
        autocomplete="off"
        :label="$t(`application.${coApplicantRole}.employment.companyName.label`)"
        :data-vv-as="$t(`application.${coApplicantRole}.employment.companyName.label`)"
      />
    </div>

    <div class="bg-gray-10 m-t-10">
      <bb-checkbox
        id="incomeMayDecreaseField"
        ref="income-ref"
        v-model="incomeMayDecrease"
        background
        name="income-may-decrease"
      >
        {{ $t(`application.${coApplicantRole}.incomeMayDecrease.label`) }}
      </bb-checkbox>
      <bb-input
        v-if="incomeMayDecrease"
        id="incomeMayDecreaseInput"
        v-model="probableDecreasedIncome"
        v-validate="`required_if:income-ref,true|amount`"
        class="p-x-20 p-b-20"
        :label="$t('application.applicant.probableDecreasedIncome.label')"
        :data-vv-as="$t('application.applicant.probableDecreasedIncome.label')"
        name="probable-decreased-income"
        add-on="€"
      />
    </div>
    <h4 class="f-max-pro-demi-bold text-center p-y-30 f-color-navy">
      {{ $t('views.incomesLiabilities.obligations') }}
    </h4>

    <bb-checkbox
      id="noLiabilitiesCheckbox"
      v-model="noLiabilities"
      name="no-liabilities-checkbox"
      background
    >
      {{ $t('application.liability.noLiabilities') }}
    </bb-checkbox>

    <bb-form-repeater
      v-if="!noLiabilities"
      v-once
      id="obligations"
      v-model="liabilities"
      name="obligations"
      class="m-t-10"
      :add-button-label="$t('views.incomesLiabilities.addObligations')"
      :row-limit="10"
      hide-initial-row
    >
      <template
        slot="highlights"
        slot-scope="row"
      >
        <div>{{ enumToText(row.model.type) }}</div>
        <div>{{ formatAmount(row.model.declaredMonthlyPayment) }}</div>
      </template>
      <template
        slot="editLabel"
        slot-scope="row"
      >
        {{ enumToText(row.model.type) }}
      </template>
      <template
        slot="fields"
        slot-scope="row"
      >
        <bb-select
          :id="'obligation_type_' + row.index"
          v-model="row.model.type"
          v-validate="'required'"
          :options="liabilityTypeOptions"
          :label="$t('application.liability.type.label')"
          :data-vv-as="$t('application.liability.type.label')"
          name="liabilities-type"
        />
        <bb-input
          :id="'obligation_amount_' + row.index"
          v-model="row.model.declaredMonthlyPayment"
          v-validate="`required|amount`"
          type="text"
          :label="$t('application.liability.declaredMonthlyPayment.label')"
          :data-vv-as="$t('application.liability.declaredMonthlyPayment.label')"
          name="declared-monthly-payment"
          add-on="€"
        />
      </template>
    </bb-form-repeater>
  </bb-application-page>
</template>

<script>
  import '@bigbank/interface-components/dist/svg/client/finance';
  import { mapActions, mapGetters } from 'vuex';
  import enumsToOptionsMixin from '@/mixins/enumsToOptionsMixin';
  import incomesLiabilitiesMixin from '@/mixins/incomesLiabilitiesMixin';
  import employerCompanyMixin from '@/mixins/employerCompanyMixin';
  import IncomeBank from '@/components/IncomeBank';

  export default {
    name: 'spouse-finance-data',

    components: { IncomeBank },

    mixins: [
      enumsToOptionsMixin,
      incomesLiabilitiesMixin,
      employerCompanyMixin,
    ],
    data() {
      return { ...this.$store.getters['processStep/coApplicantFinanceData'] };
    },

    computed: {
      ...mapGetters('processStep', [
        'coApplicantRole',
        'isProductHousingLoan',
      ]),
    },

    methods: {
      ...mapActions('processStep', ['updateProcessStep']),
      submit() {
        const liabilities = this.noLiabilities
          ? []
          : this.liabilities.map(liability => (
            {
              ...liability,
              declaredMonthlyPayment: this.unlocalizeLtNumber(liability.declaredMonthlyPayment),
            }
          ));

        const incomes = this.incomes.map(income => ({
          ...income,
          declaredNetMonthlyAmount: this.unlocalizeLtNumber(income.declaredNetMonthlyAmount),
        }));

        const incomeMayDecrease = !!this.incomeMayDecrease;

        const application = {
          [this.coApplicantRole]: {
            incomeMayDecrease,
            liabilities,
            incomes,
            ...(
              // eslint-disable-next-line array-element-newline, array-bracket-newline
              [undefined, null].includes(this.incomeReceivedToBankInBusinessUnitCountry)
                ? {}
                : { incomeReceivedToBankInBusinessUnitCountry: this.incomeReceivedToBankInBusinessUnitCountry }
            ),
          },
        };

        if (this.isEmployerMandatory(this.incomes, this.isProductHousingLoan)) {
          application[this.coApplicantRole].employment = this.employment;
        }

        if (incomeMayDecrease) {
          application[this.coApplicantRole].probableDecreasedIncome = this.unlocalizeLtNumber(this.probableDecreasedIncome);
        }

        this.updateProcessStep({ application });
      },
    },
  };
</script>
