/**
 * @mixin Render options from constants.js
 */
export default {
  methods: {
    enumsToOptions(options, localePath) {
      return Object.entries(options)
        .map(([
          translationKey,
          apiEnum,
        ]) => ({
          value: apiEnum,
          text: this.$t(`${localePath}.options.${translationKey}`),
        }));
    },
  },
};
