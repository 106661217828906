import LogoSEB from '@/assets/img/logo_SEB.svg';
import LogoSwedbank from '@/assets/img/logo_Swedbank.svg';
import LogoLuminorDNB from '@/assets/img/logo_Luminor-DNB.png';
import LogoMobileID from '@/assets/img/logo_Mobile-ID.png';
import LogoSmartID from '@/assets/img/logo_Smart-ID.png';
import LogoURBO from '@/assets/img/logo_URBO.svg';
import LogoSB from '@/assets/img/logo_SB.svg';
import LogoCitadele from '@/assets/img/logo_Citadele.svg';

export const BANKLINK_TYPE = 'banklink';

const BANKS = Object.freeze([
  {
    type: `${BANKLINK_TYPE}_swedbank`, img: LogoSwedbank, name: 'Swedbank',
  },
  {
    type: `${BANKLINK_TYPE}_seb`, img: LogoSEB, name: 'SEB',
  },
  {
    type: `${BANKLINK_TYPE}_luminor`, img: LogoLuminorDNB, name: 'Luminor',
  },
  {
    type: `${BANKLINK_TYPE}_dnb`, img: LogoLuminorDNB, name: 'DnB',
  },
  {
    type: `${BANKLINK_TYPE}_urbo`, img: LogoURBO, name: 'URBO',
  },
  {
    type: `${BANKLINK_TYPE}_sb`, img: LogoSB, name: 'Siauliu bankas',
  },
  {
    type: `${BANKLINK_TYPE}_citadele`, img: LogoCitadele, name: 'Citadele',
  },
]);

export const VERIFICATION_TYPES = Object.freeze({
  remote_identification: 'remote_identification',
  smart_id: 'smart_id',
  mobile_id: 'mobile_id',
  bigbank_id: 'bigbank_id',
  ...Object.fromEntries(BANKS.map(link => [
    link.type,
    link.type,
  ])),
});

class VerificationMethod {
  constructor({ type, img, name, instructionImg = undefined, order }) {
    this.type = type;
    this.img = img;
    this.name = name;
    this.instructionImg = instructionImg;
    this.disabled = true;
    this.order = order;
  }
}

export const SMART_ID = new VerificationMethod({
  type: VERIFICATION_TYPES.smart_id,
  img: LogoSmartID,
  name: 'SMART-ID',
  order: 1,
});

export const MOBILE_ID = new VerificationMethod({
  type: VERIFICATION_TYPES.mobile_id,
  img: LogoMobileID,
  name: 'MOBILE-ID',
  order: 2,
});

export const SUPPORTED_AUTH_AND_SIGNING_METHODS = [...createBankMethods(BANKS)];

function createBankMethods (banks) {
  return banks.map((link, index) => new VerificationMethod({
    type: VERIFICATION_TYPES[link.type],
    img: link.img,
    name: link.name,
    order: index + 3, // Bank methods go after mobile methods
  }));
}
