import { IBAN } from 'ibankit';

export default {
  validate: valueWithOrWithoutSpaces => {
    if (typeof valueWithOrWithoutSpaces !== 'string') {
      return false;
    }

    const value = valueWithOrWithoutSpaces.replace(/ /g, '').toUpperCase();

    return /^LT\d{18}$/.test(value) && IBAN.isValid(value);
  },
};
