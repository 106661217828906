import { EMPLOYER_IS_MANDATORY_FOR_INCOME_TYPES } from '@/constants/application';

export default {
  methods: {
    isEmployerMandatory(incomes, isProductHousingLoan = false) {
      return isProductHousingLoan
        ? incomes.some(income => EMPLOYER_IS_MANDATORY_FOR_INCOME_TYPES.includes(income.typeInForm))
        : false;
    },
  },
};
