<template>
  <bb-application-page
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isLoading,
      loading: isLoading,
    }"
    class="choose-continue-option"
    @submit="submit"
  >
    <bb-public-header
      slot="header"
      :title="$t('views.chooseContinueOption.title')"
      icon="client-wave"
    />

    <bb-detailed-radio
      v-model="continueOption"
      v-validate="'required'"
      name="continue-option"
      :options="continueOptions"
    />

    <real-estate-data-form
      v-if="showRealEstateData"
      @realEstateData="applicationRealEstateData = $event"
    />
  </bb-application-page>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import '@bigbank/interface-components/dist/svg/client';
  import { BbApplicationPage, BbPublicHeader, BbDetailedRadio } from '@bigbank/interface-components';
  import { taskDefinitionKeys } from '@/constants';
  import { HOUSING_LOAN_CONTINUE_OPTION } from '@/constants/application';
  import RealEstateDataForm from '@/components/RealEstateDataForm';

  export default {
    name: 'choose-continue-option',

    components: {
      BbApplicationPage,
      BbPublicHeader,
      BbDetailedRadio,
      RealEstateDataForm,
    },

    data() {
      return {
        continueOption: null,
        applicationRealEstateData: null,
      };
    },

    computed: {
      ...mapGetters('processStep', [
        'isLoading',
        'definitionKey',
        'isProductHousingLoan',
        'isLoanPurposeCombiningLoans',
      ]),

      showRealEstateData () {
        return this.continueOption === HOUSING_LOAN_CONTINUE_OPTION.InsertRealEstateData;
      },

      areCustomerDownPaymentFieldsVisible () {
        return !(this.isProductHousingLoan && this.isLoanPurposeCombiningLoans);
      },

      allContinueOptions() {
        return {
          [HOUSING_LOAN_CONTINUE_OPTION.ProceedToIdentification]: {
            value: HOUSING_LOAN_CONTINUE_OPTION.ProceedToIdentification,
            leftColumn: { label: this.$t('views.chooseContinueOption.options.proceedToIdentification') },
          },
          [HOUSING_LOAN_CONTINUE_OPTION.InsertRealEstateData]: {
            value: HOUSING_LOAN_CONTINUE_OPTION.InsertRealEstateData,
            leftColumn: { label: this.$t('views.chooseContinueOption.options.insertRealEstateData') },
          },
          [HOUSING_LOAN_CONTINUE_OPTION.ContactCustomer]: {
            value: HOUSING_LOAN_CONTINUE_OPTION.ContactCustomer,
            leftColumn: { label: this.$t('views.chooseContinueOption.options.contactCustomer') },
          },
        };
      },

      continueOptions() {
        switch (this.definitionKey) {
          case taskDefinitionKeys.ChooseContinueOption:
            return [
              this.allContinueOptions[HOUSING_LOAN_CONTINUE_OPTION.InsertRealEstateData],
              this.allContinueOptions[HOUSING_LOAN_CONTINUE_OPTION.ProceedToIdentification],
            ];

          case taskDefinitionKeys.ChooseContinueOptionAfterReData:
            return [this.allContinueOptions[HOUSING_LOAN_CONTINUE_OPTION.ProceedToIdentification]];

          default:
            return [];
        }
      },
    },

    methods: {
      ...mapActions('processStep', ['updateProcessStep']),

      getRealEstateApplicationData () {
        const applicationUpdate = {
          goodsPrice: this.applicationRealEstateData?.goodsPrice,
          selfFinancingAmount: 0,
          initialLoanAmount: this.applicationRealEstateData?.initialLoanAmount,
          initialLoanPeriod: this.applicationRealEstateData?.initialLoanPeriod,
          collaterals: [
            ...(this.applicationRealEstateData?.collaterals ? this.applicationRealEstateData.collaterals.filter(collateral => !collateral.main) : []),
            {
              main: true,
              additionalInfoText: this.applicationRealEstateData?.additionalInfoText,
            },
          ],
        };

        if (this.areCustomerDownPaymentFieldsVisible) {
          applicationUpdate.downpaymentOriginDescription = this.applicationRealEstateData?.downpaymentOriginDescription;
          applicationUpdate.selfFinancingAmount = this.applicationRealEstateData?.selfFinancingAmount;
        }

        if (this.applicationRealEstateData?.showRenovationCost) {
          applicationUpdate.renovationCost = this.applicationRealEstateData?.renovationCost;
          applicationUpdate.goodsPrice = this.applicationRealEstateData?.goodsPrice;
        } else if (this.initialRenovationCost) {
          applicationUpdate.renovationCost = 0;
        }

        return applicationUpdate;
      },

      async submit() {
        this.updateProcessStep({
          ...this.showRealEstateData && { application: this.getRealEstateApplicationData() },
          variables: {
            continueOption: {
              type: 'String',
              value: this.continueOption,
            },
          },
        });
      },
    },
  };
</script>
