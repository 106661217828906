import axios from 'axios';

export class LtLoanOriginationClient {
  /**
   * Supports both legacy DSM and NeoPayLT signing options.
   * */
  static getAutomatedSigningList(id, body) {
    return axios.post(`/api/v1/automated-signing/${id}/method/list`, body);
  }

  static checkSigningStatus(id) {
    return axios.get(`/api/v1/automated-signing/${id}`);
  }

  static initiateSmartIdSigning(id) {
    return axios.post(`/api/v1/automated-signing/${id}`);
  }

  static checkRemoteIdentification(id) {
    return axios.get(`/api/v1/identity/${id}`);
  }

  static completeRemoteIdentification(id) {
    return axios.get(`/api/v1/identity/${id}/status`);
  }

  static completeOnlineSigning(id) {
    return axios.put(`/api/v1/automated-signing/${id}`);
  }

  static initiateRemoteIdentification(id, body) {
    return axios.post(`/api/v1/identity/${id}`, body);
  }

  /**
   * @deprecated Endpoints from /identification switch over to /authentication.
   * */
  static getIdentificationMethods(body) {
    return axios.post(`/api/v1/identification/method/list`, body);
  }

  /**
   * @deprecated Endpoints from /identification switch over to /authentication.
   * */
  static resetIdentification(id) {
    return axios.get(`/api/v1/identification/${id}/reset-identification`);
  }

  static login(id, body) {
    return axios.post(`/api/v1/login/${id}`, body);
  }

  static updateRedirectUri(id, redirectUri) {
    return axios.post(`/api/v1/application-process/${id}/update-redirect-uri`, redirectUri);
  }

  static validateCampaignCode(campaignCode, productName) {
    return axios.post('/api/v1/validate/campaign-code', {
      campaignCode,
      productName,
    });
  }

  static getInstitutionsList() {
    return axios.get('/api/v1/psd2/list-institutions');
  }

  static selectInstitution(body) {
    return axios.post('/api/v1/psd2/select-institution', body);
  }

  static getPostalCode(body) {
    return axios.post('/api/v1/postal-code', body);
  }

  static hideFraudWarning(id) {
    return axios.post(`/api/v1/application-process/${id}/hide-fraud-warning`);
  }

  static getAuthenticationMethods() {
    return axios.get(`/api/v1/authentication/methods`);
  }

  static initiateAuthentication(body) {
    return axios.post(`/api/v1/authentication/initiate`, body);
  }

  static processAuthenticationStatus() {
    return axios.post(`/api/v1/authentication/status`);
  }

  static getSigningMethods() {
    return axios.get(`/api/v1/signing/methods`);
  }

  static initiateSigning(body) {
    return axios.post(`/api/v1/local/signing/initiate`, body);
  }

  static processSigningStatus() {
    return axios.post(`/api/v1/local/signing/status`);
  }
}

// import LtLoanOriginationClientDev from '../../tests/mocks/LtLoanOriginationClientDev';
export default LtLoanOriginationClient;
