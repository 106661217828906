<template>
  <div class="customer-pep-modal">
    <bb-radio
      v-validate="'required'"
      label-center
      equal-width
      :value="value === null ? null : value.toString()"
      name="customer-is-pep"
      :data-vv-as="$t('application.applicant.customerIsPep.label')"
      :label="$t('application.applicant.customerIsPep.label')"
      :options="pepOptions"
      @change="$emit('input', $event === 'true')"
    />
    <div class="text-center m-t-10">
      <bb-button
        id="pepReadMore"
        class="smallest"
        color="blue"
        display="link"
        @click="open"
      >
        {{ $t('form.readMore') }}
      </bb-button>
      <bb-modal
        id="pep-modal"
        class="text-left"
        flavor="user"
        :visible="showModal"
        center-vertiacally
        @close="close"
      >
        <bb-scroll-wrapper
          class="nm-r-30"
          viewport-class="p-r-30"
        >
          <p class="f-strong">
            {{ $t('components.customerPepModal.relatives.header') }}
          </p>
          <p>{{ $t('components.customerPepModal.relatives.text') }}</p>
          <br />
          <p class="f-strong">
            {{ $t('components.customerPepModal.assistant.header') }}
          </p>
          <p>{{ $t('components.customerPepModal.assistant.text') }}</p>
          <br />
          <p class="f-strong">
            {{ $t('components.customerPepModal.publicDuties.header') }}
          </p>
          <div class="pep-preline">
            <p>{{ $t('components.customerPepModal.publicDuties.text') }}</p>
          </div>
        </bb-scroll-wrapper>
      </bb-modal>
    </div>
  </div>
</template>

<script>
  import { modalMixin } from '@/mixins/modalMixin';
  import { BbRadio, BbScrollWrapper } from '@bigbank/interface-components';

  export default {
    name: 'customer-pep-modal',
    components: {
      BbScrollWrapper,
      BbRadio,
    },
    mixins: [modalMixin],
    inject: ['$validator'],
    props: { value: Boolean },
    computed: {
      pepOptions() {
        return [
          {
            text: this.$t('application.applicant.customerIsPep.options.yes'),
            value: 'true',
          },
          {
            text: this.$t('application.applicant.customerIsPep.options.no'),
            value: 'false',
          },
        ];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pep-preline {
    white-space: pre-line;
  }
</style>
