var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.sections.length)?_c('bb-upload',{directives:[{name:"validate",rawName:"v-validate",value:('required|uploadedFilesAreValid'),expression:"'required|uploadedFilesAreValid'"}],staticClass:"m-t-5 p-b-10 documents-upload",attrs:{"name":"file-upload-row","allowed-types":_vm.fileUploadSettings.allowedTypes,"size-limit":_vm.fileUploadSettings.sizeLimit,"size-limit-label":_vm.$t('views.documents.sizeLimitLabel'),"allowed-types-label":_vm.$t('views.documents.allowedTypesLabel'),"duplicate-file-label":_vm.$t('views.documents.duplicateFileLabel'),"empty-file-label":_vm.$t('views.documents.emptyFileLabel'),"sections":_vm.sections,"heading":_vm.heading,"allow-duplicate-files-in-different-sections":!_vm.canSelectPreviouslyUploadFile,"upload-item-class-provider":_vm.uploadItemClassProvider,"flavor":"user","disable-drag-and-drop":""},on:{"add":_vm.onAdd,"remove":_vm.onRemove},scopedSlots:_vm._u([{key:"itemButtons",fn:function(ref){
    var removeFile = ref.removeFile;
    var file = ref.file;
    var fileIndexInSection = ref.indexFile;
    var section = ref.section;
return [(_vm.shouldShowAddRowButton(section.key, fileIndexInSection))?_c('bb-button',{attrs:{"no-background":"","icon":"ui-plus-circle-outline","display":"circle"},on:{"click":function($event){return _vm.cloneType(section.key, fileIndexInSection, file)}}}):_vm._e(),_c('bb-button',{staticClass:"m-l-20",attrs:{"no-background":"","color":"red","icon":"ui-close","display":"square"},on:{"click":function($event){return _vm.removeFileAndType(removeFile, section, fileIndexInSection, file)}}})]}},{key:"uploadCTA",fn:function(ref){
    var section = ref.section;
    var file = ref.file;
    var fileIndexInSection = ref.indexFile;
    var openFileDialog = ref.openFileDialog;
return _c('li',{class:[_vm.uploadItemClassProvider(section.key, fileIndexInSection), 'upload-item p-l-30 p-r-30 p-t-10 p-b-10']},[_c('bb-row',{attrs:{"gutter":"10","align":"center","justify":"between"}},[_c('bb-col',{attrs:{"col":"","md":"15"}},[(section.types[fileIndexInSection].options.length > 1)?_c('bb-select',{staticClass:"p-0",attrs:{"options":_vm.documentTypeOptions(section.types[fileIndexInSection].options),"framed":"","noempty":"","small":"","name":"doc-type-select"},model:{value:(section.types[fileIndexInSection].value),callback:function ($$v) {_vm.$set(section.types[fileIndexInSection], "value", $$v)},expression:"section.types[fileIndexInSection].value"}}):_c('div',{staticClass:"f-smallest color-gray"},[_vm._v(" "+_vm._s(_vm.documentTypeOptions([section.types[fileIndexInSection].value])[0].text)+" ")])],1),_c('bb-col',{attrs:{"col":"auto"}},[_c('bb-button',{attrs:{"no-underline":"","label":_vm.$t('views.documents.upload'),"icon":"ui-arrow-up-circle-outline","display":"link"},on:{"click":function($event){openFileDialog({
            section: section.key,
            docTypes: [
              _vm.getDocType(section, fileIndexInSection)
            ],
            key: file.key
          })}}}),(section.types[fileIndexInSection].cloned)?_c('bb-button',{staticClass:"p-0 m-l-20",attrs:{"no-background":"","color":"red","icon":"ui-close"},on:{"click":function($event){return _vm.removeType(section.key, fileIndexInSection, file.key)}}}):_vm._e()],1)],1),(_vm.canSelectPreviouslyUploadFile)?[_c('bb-row',{staticClass:"m-0"},[(_vm.uniqueFiles(section, fileIndexInSection).length)?_c('bb-checkbox',{staticClass:"nm-l-10",staticStyle:{"flex":"1"},attrs:{"size":"tiny","center-checkbox":"","name":"use existing file"},model:{value:(section.types[fileIndexInSection].useExistingFile),callback:function ($$v) {_vm.$set(section.types[fileIndexInSection], "useExistingFile", $$v)},expression:"section.types[fileIndexInSection].useExistingFile"}},[_vm._v(" "+_vm._s(_vm.$t('views.documents.useUploadedFiles'))+" ")]):_vm._e()],1),(section.types[fileIndexInSection].useExistingFile)?_c('ul',_vm._l((_vm.uniqueFiles(section, fileIndexInSection)),function(existingFile,indexExistingFile){return _c('bb-upload-item',{key:'existingFile' + indexExistingFile,attrs:{"file":existingFile.file,"description":existingFile.description,"background-gray":""}},[_c('bb-checkbox',{staticClass:"align-self-center",staticStyle:{"width":"30px"},attrs:{"name":'select file ' + indexExistingFile,"no-padding":""},on:{"input":function($event){return _vm.selectExistingFile(section, fileIndexInSection, file.key, existingFile)}}})],1)}),1):_vm._e()]:_vm._e()],2)}}],null,false,2821105383),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }