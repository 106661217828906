import GtmEvent from './GtmEvent';
import Tracking from './Tracking';
import { pages, SOURCE } from '@/constants';
import { PROCESS_STEP_UPDATED } from '@bigbank/web-loco/src/store/modules/processStep/types';
import { SET_PRODUCT_DATA } from '@bigbank/web-loco/src/store/modules/products/types';
import { APP_MAP_BY_PRODUCT_URL_NAME } from '@/util/applicationMapping';

const pagesToSkip = [pages.start];

export default function trackPageViews(Vue, router, store) {

  let unsubscribeStore = null;

  router.afterEach(toRoute => {
    if (pagesToSkip.includes(toRoute.name)) {
      return;
    }

    if (unsubscribeStore) {
      unsubscribeStore();
      unsubscribeStore = null;
    }

    if (store.getters['processStep/externalId']) {
      trackEvent(toRoute, store, Vue);
    }

    unsubscribeStore = store.subscribe(mutation => {
      if ([
        `processStep/${PROCESS_STEP_UPDATED}`,
        `products/${SET_PRODUCT_DATA}`,
      ].includes(mutation.type)) {
        trackEvent(toRoute, store, Vue);
      }
    });
  });
}

export function trackEvent(toRoute, store, Vue) {
  const appMapByProductUrl = store.getters['products/applicationMappingId'] &&
    Object.entries(APP_MAP_BY_PRODUCT_URL_NAME)
      .find(mapping => mapping[1].id === store.getters['products/applicationMappingId']);

  const productUrlFromApplication = appMapByProductUrl && appMapByProductUrl[0];
  const productUrlFromUrl = toRoute.params?.productName ?? toRoute.query?.productName;
  const productUrl = productUrlFromApplication ?? productUrlFromUrl ?? null;
  const eventCategory = productUrl
    ? `${productUrl}-${store.getters['processStep/source'] ?? toRoute.query?.source ?? SOURCE.BIGBANK}`
    : null;

  Vue.gtm.trackEvent(
    new GtmEvent({
      bbeventlabel: toRoute.name,
      bbloanamount: store.getters['products/getProductData'].amount,
      bbeventcategory: eventCategory,
      bbapplicationid: store.getters['processStep/externalId'],
      tracking: new Tracking(toRoute.query),
    }).filterEmptyValues(),
  );

  Vue.gtm.trackEvent(
    new GtmEvent({
      event: 'pageview',
      bbeventlabel: toRoute.name,
      bbloanamount: store.getters['products/getProductData'].amount,
      bbeventcategory: eventCategory,
      bbapplicationid: store.getters['processStep/externalId'],
      tracking: new Tracking(toRoute.query),
    }).filterEmptyValues(),
  );
}
