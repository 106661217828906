import { BbHero, BbOfferThankYouPage, BbRadio } from '@bigbank/interface-components';
import { mapActions, mapGetters } from 'vuex';
import StepsOverview from '@/components/StepsOverview';
import { PAYMENT_DAY_OPTIONS } from '@/constants';

export default {
  components: {
    BbHero,
    BbOfferThankYouPage,
    BbRadio,
    StepsOverview,
  },

  data() {
    return {
      bankAccountNumber: this.$store.getters['processStep/bankDetailsData'].bankAccountNumber,
      monthlyPaymentDay: this.$store.getters['processStep/bankDetailsData'].monthlyPaymentDay || PAYMENT_DAY_OPTIONS[0].value,
      paymentDayOptions: PAYMENT_DAY_OPTIONS,
    };
  },

  computed: { ...mapGetters('processStep', ['isLoading']) },

  methods: {
    ...mapActions('processStep', ['updateProcessStep']),

    setPaymentDay(value) {
      this.monthlyPaymentDay = parseInt(value, 10);
    },
  },
};
