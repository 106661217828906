<template>
  <bb-offer-thank-you-page
    class="offer"
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="submit"
  >
    <template slot="header">
      <bb-hero
        class="w-100"
        bleed="all"
        :title="$t('views.offerReviewHousingLoan.title')"
      >
        <steps-overview />
      </bb-hero>
    </template>
    <p class="color-gray-70 bold f-case-upper f-tiny text-center">
      {{ $t('views.offerReviewHousingLoan.overture') }}
    </p>
    <p class="text-center f-gotham-bold color-green h3 m-t-10">
      {{ $n(amount, 'currency') }}
    </p>
    <template slot="form-extras">
      <p class="text-center f-small f-medium m-y-20">
        {{ $t('views.offerReviewHousingLoan.disclaimer') }}
      </p>
    </template>
  </bb-offer-thank-you-page>
</template>

<script>
  import { BbHero, BbOfferThankYouPage } from '@bigbank/interface-components';
  import StepsOverview from '../components/StepsOverview';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'offer-review-housing-loan',

    components: {
      BbHero,
      BbOfferThankYouPage,
      StepsOverview,
    },

    computed: {
      ...mapGetters('processStep', [
        'isLoading',
        'offer',
      ]),

      amount () {
        return Number(this.offer?.customerMaxAmount);
      },
    },

    methods: {
      ...mapActions('processStep', ['updateProcessStep']),

      submit() {
        this.updateProcessStep({});
      },
    },
  };
</script>
