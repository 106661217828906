const ltLetterMapping = {
  'Ą': 'A',
  'Č': 'C',
  'Ę': 'E',
  'Ė': 'E',
  'Į': 'I',
  'Š': 'S',
  'Ū': 'U',
  'Ų': 'U',
  'Ž': 'Z',
};

function normalizeName(fullName) {
  return fullName.replace(/[ĄČĘĖĮŠŪŲŽ]/g, char => ltLetterMapping[char] || char);
}

export default {
  validate: (value, [
    coApplicantName,
    coApplicantSurname,
    applicantName,
    applicantSurname,
  ]) => {
    const coApplicantFullName = `${coApplicantName?.trim() ?? value?.trim()} ${coApplicantSurname?.trim() ?? value?.trim()}`.toUpperCase();
    const applicantFullName = `${applicantName?.trim()} ${applicantSurname?.trim()}`.toUpperCase();

    return normalizeName(coApplicantFullName) !== normalizeName(applicantFullName);
  },
};
