<template>
  <loading
    v-if="goBackEnabled"
    :show-text="false"
  />
  <bb-offer-thank-you-page
    v-else
    ref="documents"
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('views.documents.allAvailableFilesUploaded'),
      disabled: showLoader || !canSubmitForm,
      loading: showLoader,
    }"
    @submit="submit"
  >
    <template slot="header">
      <bb-hero
        :title="$t('views.documents.title')"
        class="w-100"
        bleed="all"
      >
        <steps-overview />
      </bb-hero>
    </template>
    <bb-banner
      :visible="uploadFailed"
      type="error"
      class="m-b-25"
      permanent
    >
      {{ $t('views.documents.uploadFailed') }}
    </bb-banner>
    <div class="align-center m-t-10">
      <bb-icon
        name="client-upload"
        size="64"
        fill="original"
      />
    </div>
    <document-upload
      :can-select-previously-upload-file="canSelectPreviouslyUploadFile"
      :is-refinancing-page="isRefinancingPage"
      @files-updated="filesUpdated"
      @are-files-set-for-all-sections-updated="areFilesSetForAllSections = $event"
      @are-files-set-for-all-mandatory-sections-updated="areFilesSetForAllMandatorySections = $event"
    />
    <document-download
      v-if="documentTypeForDownload"
      :document-type="documentTypeForDownload"
    />
    <back-to-psd2-task
      v-if="showGoBackToPsd2"
      :loading="showLoader"
      @goBackEnabled="goBackEnabled = $event"
    />
  </bb-offer-thank-you-page>
</template>

<script>
  import { BbBanner,
           BbHero,
           BbIcon,
           BbOfferThankYouPage } from '@bigbank/interface-components';
  import '@bigbank/interface-components/dist/svg/ui';
  import '@bigbank/interface-components/dist/svg/client/upload';
  import StepsOverview from '@/components/StepsOverview';
  import DocumentUpload from '@/components/DocumentUpload';
  import DocumentDownload from '@/components/DocumentDownload';
  import { mapActions, mapGetters } from 'vuex';
  import { FIXED_SECTIONS } from '@/constants/requiredDocuments';
  import { ASSET_CONDITION, DOCUMENT_TYPE } from '@/constants/application';
  import { DOWNLOAD_DOCUMENT_TYPE_LEASING,
           DOWNLOAD_DOCUMENT_TYPE_INCOME,
           DOCUMENT_RESOLUTION,
           PERSON,
           taskDefinitionKeys } from '@/constants';
  import cloneDeep from 'lodash/cloneDeep';
  import has from 'lodash/has';
  import isEmpty from 'lodash/isEmpty';
  import BackToPsd2Task from '@/components/documentsPage/BackToPsd2Task';
  import Loading from '@/components/Loading';

  export default {
    name: 'documents-page',

    components: {
      BackToPsd2Task,
      BbBanner,
      BbHero,
      BbIcon,
      BbOfferThankYouPage,
      DocumentUpload,
      DocumentDownload,
      StepsOverview,
      Loading,
    },

    props: {
      shouldSetFilesForAllSections: Boolean,
      shouldSetAllFilesForMandatorySections: Boolean,
      canSelectPreviouslyUploadFile: Boolean,
      isRefinancingPage: Boolean,
    },

    data () {
      return {
        goBackEnabled: false,
        showLoader: false,
        uploadFailed: false,
        files: [],
        isSpouseFlow: this.$store.getters['processStep/documentsUpload'].isSpouseFlow,
        areFilesSetForAllSections: false,
        areFilesSetForAllMandatorySections: false,
      };
    },

    computed: {
      ...mapGetters('processStep', [
        'assetCondition',
        'documentsUpload',
        'definitionKey',
      ]),
      requiredDocuments () {
        return this.documentsUpload?.requiredDocuments;
      },
      filesToUpload () {
        return cloneDeep(this.files)
          .reduce((result, file) => {
            const fileKey = has(file, 'duplicateOf') ? file.duplicateOf.key : file.key;
            if (!result[fileKey]) {
              result[fileKey] = {
                documentTypes: file.docTypes ?? [],
                file: file.file,
              };
            } else if (!result[fileKey].documentTypes.includes(file.docTypes[0])) {
              result[fileKey].documentTypes.push(file.docTypes[0]);
            }

            return result;
          }, {});
      },
      hasAnyFilesToUpload () {
        return !isEmpty(this.filesToUpload);
      },
      isFormValid () {
        return this.$validator.errors.items.length === 0;
      },
      areAllDocsRealEstateNotMandatory () {
        return this.requiredDocuments?.length
          ? this.requiredDocuments.every(({ name, mandatory }) => name === FIXED_SECTIONS.realEstate && !mandatory)
          : false;
      },
      areFilesSet () {
        return (this.shouldSetFilesForAllSections ? this.areFilesSetForAllSections : this.hasAnyFilesToUpload) &&
          (this.shouldSetAllFilesForMandatorySections ? this.areFilesSetForAllMandatorySections : this.hasAnyFilesToUpload);
      },
      canSubmitForm () {
        return this.isFormValid && (this.areAllDocsRealEstateNotMandatory || this.areFilesSet);
      },
      showLeasingDocumentDownload() {
        return this.isAssetConditionUsed && this.isSaleOfferDocumentAvailable && this.isSaleOfferDocumentInvalid;
      },
      showIncomeDocumentDownload() {
        return [
          taskDefinitionKeys.UploadDocuments,
          taskDefinitionKeys.UploadSpouseDocuments,
        ].includes(this.definitionKey);
      },
      isAssetConditionUsed() {
        return this.assetCondition === ASSET_CONDITION.used;
      },
      saleOfferDocument() {
        return {
          ...((this.requiredDocuments || [])
            .find(d => d.name === FIXED_SECTIONS.leasing &&
              Array.isArray(d.options) &&
              d.options[0] === DOCUMENT_TYPE.saleOffer)
          ),
        };
      },
      isSaleOfferDocumentAvailable() {
        return !isEmpty(this.saleOfferDocument);
      },
      isSaleOfferDocumentInvalid() {
        return this.isSaleOfferDocumentAvailable
          ? [
            DOCUMENT_RESOLUTION.INSUFFICIENT,
            DOCUMENT_RESOLUTION.FRAUDULENT,
            DOCUMENT_RESOLUTION.MISSING,
            undefined,
            null,
          ].includes(this.saleOfferDocument.resolution)
          : false;
      },
      documentOwner() {
        if (!this.isSpouseFlow) {
          return PERSON.APPLICANT;
        }

        return this.coApplicantRole();
      },
      documentTypeForDownload() {
        if (this.showLeasingDocumentDownload) {
          return DOWNLOAD_DOCUMENT_TYPE_LEASING;
        }

        if (this.showIncomeDocumentDownload) {
          return DOWNLOAD_DOCUMENT_TYPE_INCOME;
        }

        return undefined;
      },
      showGoBackToPsd2 () {
        return this.showIncomeDocumentDownload && this.requiredDocuments
          .some(({ options }) => options.includes(DOCUMENT_TYPE.bankStatement));
      },
    },

    methods: {
      ...mapActions('processStep', [
        'updateProcessStep',
        'uploadDocument',
      ]),
      ...mapGetters('processStep', ['coApplicantRole']),
      filesUpdated (files) {
        this.files = files;
        this.uploadFailed = false;
      },
      uploadLater () {
        this.updateProcessStep({
          application: {},
          variables: {
            areDocumentsUploaded: {
              type: 'Boolean',
              value: false,
            },
          },
        });
      },
      submit () {
        if (!this.canSubmitForm) {
          return;
        }

        this.showLoader = true;
        this.uploadFailed = false;

        const filesToUpload = Object.values(this.filesToUpload);

        if (this.areAllDocsRealEstateNotMandatory && !filesToUpload?.length) {
          this.completeTask();

          return;
        }

        filesToUpload
          .reduce((promise, requiredDocument) =>
            promise.then(result =>
              this.uploadFileAndSaveDocuments(requiredDocument)
                .then(Array.prototype.concat.bind(result)),
            ), Promise.resolve([]))
          .then(results => {
            if (results.find(result => result.state === 'success')) {
              this.completeTask();
            } else {
              this.uploadFailed = true;
              this.showLoader = false;
            }
          });
      },
      uploadFileAndSaveDocuments (requiredDocument) {
        return this.uploadDocument({
          file: requiredDocument.file,
          documentOwner: this.documentOwner,
          documentTypes: requiredDocument.documentTypes,
        }).then(
          value => ({
            state: 'success',
            value,
          }),
          value => ({
            state: 'failure',
            value,
          }),
        );
      },
      completeTask () {
        if (this.definitionKey === taskDefinitionKeys.UploadDocuments ||
            this.definitionKey === taskDefinitionKeys.UploadSpouseDocuments) {
          this.updateProcessStep({
            application: {},
            variables: {
              areDocumentsUploaded: {
                type: 'Boolean',
                value: true,
              },
              provideAdditionalPsd2Documents: {
                type: 'Boolean',
                value: false,
              },
            },
          });
        } else {
          this.updateProcessStep({});
        }
      },
    },
  };
</script>
