<template>
  <div>
    <fraud-warning-modal />
    <signing-modal
      ref="signingModal"
    />

    <section v-if="showLoader">
      <steps-overview />
      <loading />
    </section>
    <section v-else>
      <bb-contract-page
        :conditions-paragraph="$t('views.signContract.contract.conditions')"
        :extra-heading="$t('views.signContract.tableColumns.heading')"
        :download-title="$t('views.signContract.agreement.title')"
        :download-paragraphs="[$t('views.signContract.agreement.paragraph')]"
        :loan-amount="loanBoxes.loanAmount"
        :monthly-payment="loanBoxes.monthlyPayment"
        :period="loanBoxes.period"
        :condition-lists="conditionLists"
        :sign-button="signButton"
        @download="downloadUnsignedContract"
        @sign="onSign"
      >
        <template #steps>
          <steps-overview />
        </template>
        <template #extraContent>
          <bb-table
            :data="paymentSchedule"
            :columns="tableColumns"
            :fixed="fixed"
            head-underline
            condensed
            trimmed
            striped
            :start-index="1"
            index-column
            style="max-height: 40vh"
          >
            <template
              #footer
              class="tfoot"
            >
              <td class="p-y-20"></td>
              <td class="color-gray f-gotham-bold">
                {{ $t('views.signContract.tableColumns.total') }}
              </td>
              <td class="color-gray f-gotham-bold align-right">
                {{ $n(contractDetails.paymentSchedule.total.principal, 'money') }}
              </td>
              <td class="color-gray f-gotham-bold align-right">
                {{ $n(contractDetails.agreementData.conclusionFee, 'money') }}
              </td>
              <td class="color-gray f-gotham-bold align-right">
                {{ $n(contractDetails.paymentSchedule.total.interestAmount, 'money') }}
              </td>
              <td class="color-gray f-gotham-bold align-right">
                {{ $n(contractDetails.paymentSchedule.total.administrationFee, 'money') }}
              </td>
              <td class="color-gray f-gotham-bold align-right">
                {{ $n(contractDetails.paymentSchedule.total.total, 'money') }}
              </td>
            </template>
          </bb-table>
        </template>
      </bb-contract-page>
    </section>
  </div>
</template>

<script>
  import { BbContractPage, BbTable } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';
  import FraudWarningModal from '../components/signing/FraudWarningModal';
  import Loading from '../components/Loading';
  import SigningModal from '../components/signing/SigningModal';
  import StepsOverview from '../components/StepsOverview';
  import { INTEREST_TYPE, taskDefinitionKeys, LOAN_PURPOSE, PRODUCT_NAME } from '@/constants';
  import isEmpty from 'lodash/isEmpty';
  import administrationFeeMixin from '@/mixins/administrationFeeMixin';

  export default {
    name: 'sign-contract',
    components: {
      BbContractPage,
      BbTable,
      FraudWarningModal,
      Loading,
      SigningModal,
      StepsOverview,
    },

    mixins: [administrationFeeMixin],

    data() {
      return {
        fixed: {
          head: true,
          firstColumn: true,
          lastColumn: true,
          footer: true,
          lastRow: true,
        },
        tableColumns: [
          {
            key: 'paymentDate',
            label: this.$t('views.signContract.tableColumns.paymentDate'),
            class: 'text-no-wrap',
          },
          {
            key: 'principal',
            label: this.$t('views.signContract.tableColumns.loanAmount'),
            class: 'align-right',
          },
          {
            key: 'conclusionFee',
            label: this.$t('views.signContract.tableColumns.conclusionFee'),
            class: 'align-right',
          },
          {
            key: 'interest',
            label: this.$t('views.signContract.tableColumns.interestRate'),
            class: 'align-right',
          },
          {
            key: 'administrationFee',
            label: this.$t('views.signContract.tableColumns.administrationFee'),
            class: 'align-right',
          },
          {
            key: 'total',
            label: this.$t('views.signContract.tableColumns.totalMonthlyPayment'),
            class: 'align-right',
          },
        ],
      };
    },
    computed: {
      ...mapGetters('contract', [
        'contractDetails',
        'contractDetailsLoading',
      ]),
      ...mapGetters('processStep', [
        'offeredProductName',
        'loanPurpose',
        'offerChangeLoanPurpose',
        'offerAcceptanceDate',
        'isApplicantIdentified',
        'leasingProductDetails',
        'isProductCarFinanceLease',
        'definitionKey',
      ]),
      ...mapGetters('signing', [
        'identificationStatus',
        'isLoading',
        'showRemoteIdentification',
      ]),

      showWhatsNextPage() {
        return taskDefinitionKeys.WhatsNextPage === this.definitionKey;
      },
      loanBoxes() {
        return {
          loanAmount: {
            title: this.$t('views.signContract.side.loanAmount'),
            content: this.$n(this.contractDetails.paymentSchedule.total.principal, 'currency'),
          },
          monthlyPayment: {
            title: this.$t('views.signContract.side.monthlyPayment'),
            content: this.$n(this.contractDetails.paymentData.monthlyPaymentAmount, 'currency'),
          },
          period: {
            title: this.$t('views.signContract.side.period'),
            content: `${this.contractDetails.agreementData.contractPeriod} ${this.$t('components.calculator.period.unit')}`,
          },
        };
      },
      interestRateAsNumber() {
        const interestRateOriginal = this.contractDetails.paymentData.interestRate;
        const interestRateProcessed = typeof interestRateOriginal === 'string'
          ? interestRateOriginal.replace(',', '.')
          : interestRateOriginal;

        return Number(interestRateProcessed);
      },
      conditionListItemAccount() {
        return this.isProductCarFinanceLease
          ? undefined
          : {
            title: this.$t('views.signContract.contract.beneficiaryIban'),
            content: this.contractDetails.paymentData.beneficiaryIban,
          };
      },
      conditionListItemInterest() {
        let title;

        switch (this.leasingProductDetails?.interestType) {
          case INTEREST_TYPE.FIXED:
          default:
            title = this.$t('views.signContract.contract.interestType.fix');
            break;

          case INTEREST_TYPE.EURIBOR_6_MONTHS:
            title = this.$t('views.signContract.contract.interestType.6m');
            break;
        }

        return {
          title,
          content: this.$n(this.interestRateAsNumber * 0.01, 'percent'),
        };
      },
      conditionListItemsForCarFinanceLease() {
        return this.isProductCarFinanceLease
          ? [
            {
              title: this.$t('views.signContract.contract.goodsPrice'),
              content: this.$n(this.leasingProductDetails.goodsPrice, 'currency'),
            },
            {
              title: this.$t('views.signContract.contract.selfFinancingAmount'),
              content: this.$n(this.leasingProductDetails.selfFinancingAmount, 'currency'),
            },
          ]
          : [];
      },
      conditionLists() {
        return [{
          heading: this.$t('views.signContract.contract.heading'),
          list: [
            { ...this.conditionListItemAccount },
            {
              title: this.$t('views.signContract.contract.paymentDay'),
              content: `${this.contractDetails.agreementData.paymentDay }`,
            },
            {
              title: this.$t('views.signContract.contract.aprc'),
              content: this.$n(this.contractDetails.agreementData.APR * 0.01, 'percent'),
            },
            {
              title: this.$t('views.signContract.contract.totalAmountOfCredit'),
              content: this.$n(this.contractDetails.paymentSchedule.total?.principal, 'currency'),
            },
            {
              title: this.$t('views.signContract.contract.contractFee'),
              content: this.$n(this.contractDetails.agreementData.conclusionFee, 'currency'),
            },
            {
              title: this.$t('views.signContract.contract.administrationFee'),
              content: this.administrationFee({
                amount: this.contractDetails.paymentSchedule.total?.principal,
                administrationFee: this.contractDetails.agreementData.administrationFee,
                formatCurrency: 'currency',
                formatPercent: 'percent',
              }),
            },
            { ...this.conditionListItemInterest },
            ...this.conditionListItemsForCarFinanceLease,
            {
              title: this.$t('views.signContract.download.title'),
              button: {
                label: this.$t('views.signContract.download.buttonLabel'),
                clickEventName: 'download',
              },
            },
          ].filter(item => Object.keys(item).length),
        }];
      },
      paymentSchedule() {
        return this.contractDetails.paymentSchedule.paymentScheduleLines
          .map(year => year.lines
            .filter(line => line.rowNum > 0)
            .map(line => {
              let tableDate = '--';
              if (line.paymentDate) {
                const dateParts = line.paymentDate.split('.');
                tableDate = this.$d(new Date(`${year.year}-${dateParts[1]}-${dateParts[0]}`));
              }

              return {
                paymentDate: tableDate,
                principal: this.$n(line.principal || 0, 'money'),
                conclusionFee: this.$n(line.conclusionFee || 0, 'money'),
                interest: this.$n(line.interest || 0, 'money'),
                administrationFee: this.$n(line.administrationFee || 0, 'money'),
                total: this.$n(line.total, 'money'),
              };
            }),
          )
          .flat();
      },
      isSigningDisabled() {
        const loanPurpose = this.offerChangeLoanPurpose || this.loanPurpose;

        if (this.offeredProductName !== PRODUCT_NAME.RENOVATION_LOAN || loanPurpose === LOAN_PURPOSE.RENOVATING_HOME) {
          return false;
        }

        const acceptanceDate = new Date(new Date(this.offerAcceptanceDate).setHours(0, 0, 0, 0));
        const todayDate = new Date(new Date().setHours(0, 0, 0, 0));

        return todayDate.getTime() < acceptanceDate.getTime();
      },
      signButton() {
        return this.isSigningDisabled
          ? {
            color: 'red',
            disabled: true,
            readOnly: true,
            inverted: true,
            label: this.$t('views.signContract.signDisabled'),
          }
          : { label: this.$t('views.signContract.sign') };
      },
      showLoader() {
        return this.contractDetailsLoading || this.isLoading;
      },
    },
    async created() {
      if (this.showWhatsNextPage) {
        return;
      }

      await this.getUnsignedContractDetails();
      // TODO Enable validation after BANK-12873 is released (for UAT this was agreed to be disabled).
      // this.validateContractDetails(this.contractDetails);

      await this.initializeSigning();
    },
    methods: {
      ...mapActions('processStep', ['handleError']),
      ...mapActions('contract', [
        'getUnsignedContractDetails',
        'downloadUnsignedContract',
        'initializeSigning',
      ]),

      ...mapActions('signing', ['initializeSigning']),

      validateContractDetails({ agreementData, paymentData, paymentSchedule, repaymentBankDetails }) {
        if (isEmpty(agreementData) || isEmpty(paymentData) || isEmpty(paymentSchedule) || isEmpty(repaymentBankDetails)) {
          this.handleError(new Error('Invalid unsigned contract details!'));
        }
      },

      onSign() {
        this.$refs.signingModal.open();
      },
    },
  };
</script>
