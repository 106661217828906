import consentTextBuilder from '@/util/consentTextBuilder';

export default class AuthenticationRequest {

  constructor(externalId, person, redirectSuccessUrl, redirectFailUrl) {
    this.reference = externalId;
    this.redirectSuccessUrl = redirectSuccessUrl;
    this.redirectFailUrl = redirectFailUrl;
    this.postMessageUrl = window.location.origin;
    this.consentText = consentTextBuilder(person);
  }
}
