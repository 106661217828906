<template>
  <div class="agree-with-consent-modal text-center m-t-30">
    <div class="m-b-30">
      <i18n
        class="f-smallest"
        path="application.applicant.agreeWithConsent.label1"
      >
        <template #link>
          <bb-button
            id="registryReadMoreModal"
            class="smallest"
            color="blue"
            display="link"
            @click="open"
          >
            {{ $t('application.applicant.agreeWithConsent.link1') }}
          </bb-button>
        </template>
      </i18n>
    </div>
    <i18n
      class="f-smallest"
      path="application.applicant.agreeWithConsent.label2"
    >
      <template #link>
        <bb-button
          id="registryReadMoreLink"
          class="smallest"
          color="blue"
          display="link"
          target="_blank"
          :to="linkToDocument"
        >
          {{ $t('application.applicant.agreeWithConsent.link2') }}
        </bb-button>
      </template>
    </i18n>
    <bb-modal
      id="registryConsentModal"
      class="text-left"
      flavor="user"
      public-size="l"
      center-vertically
      :visible="showModal"
      @close="close"
    >
      <bb-scroll-wrapper
        class="text-break-word nm-r-30"
        viewport-class="p-r-30"
      >
        <h4>
          {{ $t(`${TRANSLATION_KEYS.AGREE_WITH_CONSENT_TITLE}`) }}
        </h4>

        <p
          v-if="applicant.firstName && applicant.surname"
        >
          {{ applicant.firstName }} {{ applicant.surname }}
        </p>

        <p
          v-if="applicant.personalIdentityCode"
          class="m-b-10"
        >
          {{ $t('application.applicant.personalIdentityCode.label') }}: {{ applicant.personalIdentityCode }}
        </p>

        <p class="m-b-10">
          {{ $t(`${paragraphs}.0.0`) }}
          <bb-link
            color="blue"
            target="_blank"
            :to="$t(`${paragraphs}.0.1`)"
            :label="$t(`${paragraphs}.0.2`)"
          />{{ $t(`${paragraphs}.0.3`) }}
          <strong>
            {{ $t(`${paragraphs}.0.4`) }}
          </strong>
          {{ $t(`${paragraphs}.0.5`) }}
        </p>

        <p class="m-b-10">
          <strong>{{ $t(`${paragraphs}.1.0`) }}</strong>
        </p>
        <p class="m-b-10">
          {{ $t(`${paragraphs}.1.1`) }}
          <strong>
            {{ $t(`${paragraphs}.1.2`) }}
          </strong>
          {{ $t(`${paragraphs}.1.3`) }}
        </p>
        <p class="m-b-10">
          {{ $t(`${paragraphs}.1.4`) }}
          <strong>
            {{ $t(`${paragraphs}.1.5`) }}
          </strong>
          {{ $t(`${paragraphs}.1.6`) }}
        </p>
        <p class="m-b-10">
          {{ $t(`${paragraphs}.1.7`) }}
          <strong>
            {{ $t(`${paragraphs}.1.8`) }}
          </strong>
          {{ $t(`${paragraphs}.1.9`) }}
        </p>

        <p class="m-b-10">
          {{ $t(`${paragraphs}.1.10`) }}
        </p>

        <p class="m-b-10">
          {{ $t(`${paragraphs}.2.0`) }}
        </p>

        <p class="m-b-10">
          {{ $t(`${paragraphs}.3.0`) }}
        </p>

        <p
          v-for="[key, value] in Object.entries($t(`${paragraphs}.4`))"
          :key="key"
          class="m-b-10"
        >
          {{ value }}
        </p>
      </bb-scroll-wrapper>
    </bb-modal>
  </div>
</template>

<script>
  import { BbLink, BbModal, BbScrollWrapper } from '@bigbank/interface-components';
  import { modalMixin } from '@/mixins/modalMixin';
  import { TRANSLATION_KEYS } from '@/constants/translationKeys';

  export default {
    name: 'agree-with-consent-modal',

    components: {
      BbLink,
      BbModal,
      BbScrollWrapper,
    },

    mixins: [modalMixin],

    props: {
      applicant: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        TRANSLATION_KEYS,
        paragraphs: TRANSLATION_KEYS.AGREE_WITH_CONSENT_PARAGRAPHS,
        linkToDocument: 'https://www.bigbank.lt/naudinga-informacija/?main=42&sec&doc=14492',
      };
    },
  };
</script>

