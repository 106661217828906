'use strict';

export default class SectionType {
  constructor (requiredTypes) {
    this.options = requiredTypes;
    this.value = this.options[0];
    this.useExistingFile = false;
    this.cloned = false;
  }
}
