import { APPLICATION_OFFER_TYPE_UPSALE, PRODUCT_NAME, SOURCE, taskDefinitionKeys, pages, LIABILITY } from '@/constants';
import { MAIN_INCOME_TYPES } from '@/constants/application';

const UploadDocuments = {
  application: {
    requiredDocuments: [
      {
        name: 'CIVIL_SERVANT',
        options: [
          'BANK_STATEMENT',
          'EMPLOYER_STATEMENT',
        ],
      },
      {
        name: 'CIVIL_SERVANT',
        options: ['CIVIL_SERVANT_CERTIFICATE'],
      },
      {
        name: 'DAILY_ALLOWANCES',
        options: [
          'BANK_STATEMENT',
          'EMPLOYER_STATEMENT',
        ],
      },
      {
        name: 'CAMPAIGN',
        options: ['OFFER_FROM_OTHER_CREDIT_PROVIDER'],
      },
    ],
  },
  task: { definitionKey: taskDefinitionKeys.UploadDocuments },
};

const UploadEmploymentDocuments = {
  application: {
    requiredDocuments: [
      {
        name: 'EMPLOYMENT',
        options: ['EMPLOYMENT_CONTRACT'],
      },
      {
        name: 'EMPLOYMENT',
        options: ['EMPLOYER_CONTINUITY_STATEMENT'],
      },
    ],
  },
  task: { definitionKey: taskDefinitionKeys.UploadEmploymentDocuments },
};

const UploadRefinancingDocuments = {
  application: {
    requiredDocuments: [
      {
        name: '4Finance AS',
        description: 'CONSUMER_LOAN',
        options: [
          'LIABILITIES_PROOF',
          'LIABILITIES_PROOF_EMAIL',
          'LIABILITIES_PROOF_SCREENSHOT',
        ],
      },
      {
        name: 'SIA Soho group',
        description: 'OTHERS',
        options: [
          'LIABILITIES_PROOF',
          'LIABILITIES_PROOF_EMAIL',
          'LIABILITIES_PROOF_SCREENSHOT',
        ],
      },
    ],
  },
  task: { definitionKey: taskDefinitionKeys.UploadRefinancingDocuments },
};

const UploadSpouseDocuments = {
  application: {
    requiredDocuments: [
      {
        name: 'SELF_EMPLOYED',
        options: [
          'INCOME_EXPENSE_JOURNAL',
          'INCOME_EXPENSE_TAX_SUMMARY',
        ],
      },
      {
        name: 'SELF_EMPLOYED',
        options: [
          'BUSINESS_CERTIFICATE',
          'SELF_EMPLOYMENT_CERTIFICATE',
        ],
      },
    ],
  },
  task: { definitionKey: taskDefinitionKeys.UploadSpouseDocuments },
};

const UploadLeasingDocuments = {
  application: {
    assetCondition: 'USED',
    requiredDocuments: [
      {
        name: 'LEASING',
        options: ['VALUATION'],
      },
      {
        name: 'LEASING',
        options: ['DEFECT_RECTIFICATION_REPORT'],
      },
      {
        name: 'LEASING',
        options: ['SALE_OFFER'],
      },
    ],
  },
  task: { definitionKey: taskDefinitionKeys.UploadLeasingDocuments },
};

const incomesAndLiabilitiesData = {
  incomeMayDecrease: true,
  probableDecreasedIncome: 9999.99,
  incomes: [
    {
      typeInForm: MAIN_INCOME_TYPES.salary,
      declaredNetMonthlyAmount: 10000,
    },
    {
      typeInForm: MAIN_INCOME_TYPES.business,
      declaredNetMonthlyAmount: 1234.55,
    },
    {
      typeInForm: MAIN_INCOME_TYPES.foreignSalary,
      declaredNetMonthlyAmount: 54.33,
    },
    {
      typeInForm: MAIN_INCOME_TYPES.parentalBenefit,
      declaredNetMonthlyAmount: 321.99,
    },
  ],
  liabilities: [
    {
      type: LIABILITY.mortgage,
      declaredMonthlyPayment: 321.12,
    },
    {
      type: LIABILITY.consumerLoan,
      declaredMonthlyPayment: 555.55,
    },
    {
      type: LIABILITY.other,
      declaredMonthlyPayment: 69.69,
    },
  ],
};

const SubmitIncomesAndLiabilities = {
  application: {
    productName: Object.values(PRODUCT_NAME)[Math.floor(Math.random() * Object.values(PRODUCT_NAME).length)],
    initialLoanPeriod: 45,
    initialLoanAmount: 10000,
    applicant: { ...incomesAndLiabilitiesData },
  },
  task: { definitionKey: taskDefinitionKeys.SubmitIncomesAndLiabilities },
};

const SubmitBankDetailsAndAddress = {
  application: {
    applicant: {
      contactAddress: {
        city: 'Vilnius',
        county: 'Vilniaus m. sav.',
        flatNumber: '10',
        houseNumber: '12',
        postCode: '22222',
        street: 'Kauno g.',
      },
    },
    marketingConsent: false,
    monthlyPaymentDay: 20,
  },
  task: { definitionKey: taskDefinitionKeys.SubmitBankDetailsAndAddress },
};

const SubmitBankDetails = {
  application: {
    applicant: { bankAccountNumber: 'LT121000011101001000' },
    monthlyPaymentDay: 20,
  },
  task: { definitionKey: taskDefinitionKeys.SubmitBankDetails },
};

const SubmitAddress = {
  application: {
    applicant: {
      contactAddress: {
        city: 'Vilnius',
        county: 'Vilniaus m. sav.',
        flatNumber: '10',
        houseNumber: '12',
        postCode: '22222',
        street: 'Kauno g.',
      },
    },
    marketingConsent: false,
  },
  task: { definitionKey: taskDefinitionKeys.SubmitAddress },
};

const ReviewApproved = {
  application: {
    applicant: { firstName: 'Axel' },
    offer: {
      administrationFee: 7,
      administrationFeePercent: null,
      conclusionFee: 191,
      conclusionFeePercent: 4.9,
      currency: 'EUR',
      customerMinAmount: 500,
      customerMaxAmount: 6000,
      customerMinPeriod: 6,
      customerMaxPeriod: 120,
      interestRate: 7.00,
      offeredAmount: 3191,
      offeredPeriod: 66,
      offeredProductName: 'SMALL_LOAN',
      offeredProductType: 'SMALL_LOAN_LT01',
      decisionTime: '2020-06-01T07:34:14.184',
    },
    monthlyPaymentDay: 1,
  },
  task: { definitionKey: taskDefinitionKeys.ReviewApproved },
};

const ReviewApprovedUpsale = {
  application: {
    applicant: { firstName: 'Blaze' },
    offer: {
      administrationFee: 7,
      administrationFeePercent: null,
      conclusionFee: 191,
      conclusionFeePercent: 4.9,
      currency: 'EUR',
      customerMaxAmount: 4091,
      customerMaxPeriod: 120,
      customerMinAmount: 750,
      customerMinPeriod: 6,
      interestRate: 19,
      offeredAmount: 4091,
      offeredPeriod: 82,
      offeredProductType: 'SMALL_LOAN_LT01',
      offeredProductName: 'SMALL_LOAN',
      decisionTime: '2020-06-02T07:34:14.184',
    },
    monthlyPaymentDay: 5,
  },
  task: { definitionKey: taskDefinitionKeys.ReviewApprovedUpsale },
};

const ReviewApprovedFinal = {
  application: {
    applicant: { firstName: 'Max' },
    offer: {
      administrationFee: 7,
      administrationFeePercent: null,
      conclusionFee: 191,
      conclusionFeePercent: 4.9,
      currency: 'EUR',
      customerMinAmount: 750,
      customerMaxAmount: 4091,
      customerMinPeriod: 6,
      customerMaxPeriod: 120,
      interestRate: 19,
      offeredAmount: 3991,
      offeredPeriod: 82,
      offeredProductName: 'SMALL_LOAN',
      offeredProductType: 'SMALL_LOAN_LT01',
      decisionTime: '2020-06-03T07:34:14.184',
    },
    monthlyPaymentDay: 10,
    productName: 'CAR_FINANCE_LEASE',
    goodsPrice: 3000,
    selfFinancingAmount: 191,
    interestType: '6m',
  },
  task: { definitionKey: taskDefinitionKeys.ReviewApprovedFinal },
};

const ReviewApprovedUpsaleFinal = {
  application: {
    applicant: { firstName: 'Adam' },
    offer: {
      administrationFee: 7,
      administrationFeePercent: null,
      conclusionFee: 432.55,
      conclusionFeePercent: 3.9,
      currency: 'EUR',
      customerMinAmount: 3500,
      customerMaxAmount: 20000,
      customerMinPeriod: 6,
      customerMaxPeriod: 120,
      interestRate: 13,
      offeredAmount: 11091,
      offeredPeriod: 120,
      offeredProductName: 'SMALL_LOAN',
      offeredProductType: 'SMALL_LOAN_LT01',
      decisionTime: '2020-06-04T07:34:14.184',
    },
    monthlyPaymentDay: 15,
  },
  task: { definitionKey: taskDefinitionKeys.ReviewApprovedUpsaleFinal },
};

const ReadConditionalPositiveOffer = {
  application: {
    applicant: { firstName: 'Adam' },
    offer: {
      administrationFee: 7,
      administrationFeePercent: null,
      conclusionFee: 432.55,
      conclusionFeePercent: 3.9,
      currency: 'EUR',
      customerMinAmount: 3500,
      customerMaxAmount: 20000,
      customerMinPeriod: 6,
      customerMaxPeriod: 120,
      interestRate: 13,
      offeredAmount: 11091,
      offeredPeriod: 120,
      offeredProductName: 'SMALL_LOAN',
      offeredProductType: 'SMALL_LOAN_LT01',
      decisionTime: '2020-06-04T07:34:14.184',
    },
    monthlyPaymentDay: 15,
  },
  task: { definitionKey: taskDefinitionKeys.ReadConditionalPositiveOffer },
};

const ReviewConditionalNegative = {
  application: {
    applicant: {
      firstName: 'Carl',
      surname: 'Johnson',
    },
    initialLoanAmount: 5000,
  },
  task: { definitionKey: taskDefinitionKeys.ReviewConditionalNegative },
};

const ReadNegativeDecision = {
  application: {
    source: { source: 'BIGBANK' },
    applicant: { firstName: 'Johny Cage' },
    offer: { rejectedReason: 'refinancingProductNotPossible' },
  },
  task: { definitionKey: taskDefinitionKeys.ReadNegativeDecision },
};

const ShowContractSigningNegativePage = {
  application: {
    source: { source: 'BIGBANK' },
    applicant: { firstName: 'John Rambo' },
    offer: { rejectedReason: 'activeExternalSignificantDebtFinancial' },
  },
  task: { definitionKey: taskDefinitionKeys.ShowContractSigningNegativePage },
};

const ApplicationSubmittedThankYou = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.ApplicationSubmittedThankYou },
};

const DuplicateApplicationThankYouPage = {
  application: { source: { source: 'Autoplius' }},
  task: { definitionKey: taskDefinitionKeys.DuplicateApplicationThankYouPage },
};

const ReadUndecidedDecision = {
  application: { source: { source: 'Autoplius' }},
  task: { definitionKey: taskDefinitionKeys.ReadUndecidedDecision },
};

const UnexpectedDecisionOnContractGenerationThankYouPage = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.UnexpectedDecisionOnContractGenerationThankYouPage },
};

const SpouseIdentifiedAndDataSubmittedThankYouPage = {
  application: { source: { source: 'Autoplius' }},
  task: { definitionKey: taskDefinitionKeys.SpouseIdentifiedAndDataSubmittedThankYouPage },
};

const AdditionalDataSubmittedThankYouPage = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.AdditionalDataSubmittedThankYouPage },
};
const DocumentUploadSkippedThankYouPage = {
  application: { source: { source: 'Diginet' }},
  task: { definitionKey: taskDefinitionKeys.DocumentUploadSkippedThankYouPage },
};
const DocumentsUploadedThankYouPage = {
  application: { source: { source: 'Diginet' }},
  task: { definitionKey: taskDefinitionKeys.DocumentsUploadedThankYouPage },
};

const UploadedRefinancingDocumentsThankYouPage = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.UploadedRefinancingDocumentsThankYouPage },
};

const EmploymentDocumentsUploadedThankYouPage = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.EmploymentDocumentsUploadedThankYouPage },
};

const SpouseFinanceDataSubmittedThankYouPage = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.SpouseFinanceDataSubmittedThankYouPage },
};
const SpouseDocumentsUploadedThankYouPage = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.SpouseDocumentsUploadedThankYouPage },
};
const LeasingDocumentsUploadedThankYouPage = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.LeasingDocumentsUploadedThankYouPage },
};
const CallCustomerThankYouPage = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.CallCustomerThankYouPage },
};
const ReadHousingLoanRejectedPage = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.ReadHousingLoanRejectedPage },
};
const WaitForHousingLoanDecisionThankYouPage = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.WaitForHousingLoanDecisionThankYouPage },
};
const WaitForHousingLoanDecisionThankYouPageConditionalPositive = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.WaitForHousingLoanDecisionThankYouPageConditionalPositive },
};
const HousingLoanDocumentsUploadedThankYouPage = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.HousingLoanDocumentsUploadedThankYouPage },
};

const WhatsNextPage = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.WhatsNextPage },
};

const WhatsNextPageBankLink = {
  application: { source: { source: 'BIGBANK' }},
  task: { definitionKey: taskDefinitionKeys.WhatsNextPageBankLink },
};

const ViewAndSignContract = {
  application: {
    source: { source: 'BIGBANK' },
    externalId: '123',
  },
  task: { definitionKey: taskDefinitionKeys.ViewAndSignContract },
};

const SubmitSpousePersonalData = {
  application: {
    applicant: {
      firstName: 'Carl',
      surname: 'Johnson',
    },
  },
  task: { definitionKey: taskDefinitionKeys.SubmitSpousePersonalData },
};

const SubmitSpouseDetails = {
  application: {
    applicant: {
      firstName: 'Carl',
      surname: 'Johnson',
    },
    offer: { offeredAmount: 11091 },
  },
  task: { definitionKey: taskDefinitionKeys.SubmitSpouseDetails },
};

const SubmitSpouseFinanceData = {
  application: {
    productName: PRODUCT_NAME.SMALL_LOAN,
    initialLoanPeriod: 10000,
    initialLoanAmount: 64,
    segmentName: null,
    personalOffer: { customerSegment: null },
    involveCoBorrower: false,
    spouse: { ...incomesAndLiabilitiesData },
    coBorrower: { ...incomesAndLiabilitiesData },
  },
  task: { definitionKey: taskDefinitionKeys.SubmitSpouseFinanceData },
};

const SpouseIncomesAndLiabilities = {
  application: {
    productName: Object.values(PRODUCT_NAME)[Math.floor(Math.random() * Object.values(PRODUCT_NAME).length)],
    initialLoanPeriod: 10000,
    initialLoanAmount: 64,
    segmentName: null,
    personalOffer: { customerSegment: null },
    involveCoBorrower: false,
    spouse: { ...incomesAndLiabilitiesData },
    coBorrower: { ...incomesAndLiabilitiesData },
  },
  task: { definitionKey: taskDefinitionKeys.SpouseIncomesAndLiabilities },
};

const SubmitCoBorrowerIncomesAndLiabilities = {
  application: {
    productName: PRODUCT_NAME.HOUSING_LOAN,
    initialLoanPeriod: 256,
    initialLoanAmount: 256000,
    segmentName: null,
    personalOffer: { customerSegment: null },
    involveCoBorrower: true,
    coBorrower: { ...incomesAndLiabilitiesData },
  },
  task: { definitionKey: taskDefinitionKeys.SubmitCoBorrowerIncomesAndLiabilities },
};

const IdentifyOnline = {
  application: {
    productName: Object.values(PRODUCT_NAME)[Math.floor(Math.random() * Object.values(PRODUCT_NAME).length)],
    initialLoanPeriod: 45,
    initialLoanAmount: 10000,
    applicant: {
      firstName: 'Carl',
      surname: 'Johnson',
      personalIdentityCode: '39010100097',
      idDocumentType: 'ID_CARD',
      mainMobileNo: '+372771793336',
      maritalStatus: 'DIVORCED',
      email: 'something.at@bigbank.ee',
    },
    loanPurpose: 'PURCHASING_OTHER_REAL_ESTATE',
    marketingConsent: false,
    source: { source: SOURCE.BIGBANK },
  },
  task: { definitionKey: taskDefinitionKeys.IdentifyOnline },
};

const SubmitPersonalDetails = {
  application: {
    productName: PRODUCT_NAME.CAR_LOAN,
    initialLoanPeriod: 45,
    initialLoanAmount: 10000,
    applicant: {
      firstName: 'Carl',
      surname: 'Johnson',
      landlinePhone: '+37052755245',
      personalIdentityCode: '39010100097',
      email: 'something.at@bigbank.ee',
    },
    segmentName: 'PREMIUM',
    loanPurpose: 'PURCHASING_MOTOR_VEHICLES',
    source: { source: SOURCE.AUTOPLIUS },
  },
  task: { definitionKey: taskDefinitionKeys.SubmitPersonalDetails },
};

const SubmitPersonalDetailsWithUpsale = {
  application: {
    ...SubmitPersonalDetails.application,
    personalOffer: { type: APPLICATION_OFFER_TYPE_UPSALE },
  },
  task: { definitionKey: taskDefinitionKeys.SubmitPersonalDetails },
};

const ChooseContinueOption = { task: { definitionKey: taskDefinitionKeys.ChooseContinueOption }};

const ChooseContinueOptionAfterReData = { task: { definitionKey: taskDefinitionKeys.ChooseContinueOptionAfterReData }};

const SubmitRealEstateInfo = {
  application: {
    goodsPrice: 35000,
    selfFinancingAmount: 25000,
    initialLoanAmount: 11000,
    initialLoanPeriod: 120,
    downpaymentOriginDescription: 'Looted from goblins',
    collaterals: [
      {
        main: false,
        additionalInfoText: 'Obsolete --- outdated info.',
      },
      {
        main: true,
        additionalInfoText: 'Chatka Puchatka',
      },
    ],
    source: { source: SOURCE.BIGBANK },
  },
  task: { definitionKey: taskDefinitionKeys.SubmitRealEstateInfo },
};

export const mockProcessSteps = {
  UploadDocuments,
  UploadEmploymentDocuments,
  UploadRefinancingDocuments,
  UploadSpouseDocuments,
  UploadLeasingDocuments,
  SubmitBankDetails,
  SubmitAddress,
  UnexpectedDecisionOnContractGenerationThankYouPage,
  ReadUndecidedDecision,
  ReadNegativeDecision,
  ShowContractSigningNegativePage,
  ReviewApproved,
  ReviewApprovedUpsale,
  ReviewApprovedFinal,
  ReviewApprovedUpsaleFinal,
  ReadConditionalPositiveOffer,
  ReviewConditionalNegative,
  ApplicationSubmittedThankYou,
  DuplicateApplicationThankYouPage,
  SpouseIdentifiedAndDataSubmittedThankYouPage,
  SpouseFinanceDataSubmittedThankYouPage,
  UploadedRefinancingDocumentsThankYouPage,
  EmploymentDocumentsUploadedThankYouPage,
  AdditionalDataSubmittedThankYouPage,
  DocumentsUploadedThankYouPage,
  DocumentUploadSkippedThankYouPage,
  SpouseDocumentsUploadedThankYouPage,
  LeasingDocumentsUploadedThankYouPage,
  CallCustomerThankYouPage,
  ReadHousingLoanRejectedPage,
  WaitForHousingLoanDecisionThankYouPage,
  WaitForHousingLoanDecisionThankYouPageConditionalPositive,
  HousingLoanDocumentsUploadedThankYouPage,
  spouseIdentification: {
    application: {
      spouse: {
        firstName: 'Carl',
        surname: 'Johnson',
        personalIdentityCode: '39010100097',
        idDocumentType: 'ID_CARD',
        mainMobileNo: '+372771793336',
        email: 'something.at@bigbank.ee',
        marketingConsent: true,
      },
    },
    task: { definitionKey: pages.spouseIdentification },
  },
  WhatsNextPage,
  WhatsNextPageBankLink,
  ViewAndSignContract,
  SubmitSpousePersonalData,
  SubmitSpouseDetails,
  SubmitIncomesAndLiabilities,
  IdentifyOnline,
  SubmitPersonalDetails,
  SubmitSpouseFinanceData,
  SpouseIncomesAndLiabilities,
  SubmitCoBorrowerIncomesAndLiabilities,
  SubmitPersonalDetailsWithUpsale,
  ChooseContinueOption,
  ChooseContinueOptionAfterReData,
  SubmitRealEstateInfo,
  SubmitBankDetailsAndAddress,
};
