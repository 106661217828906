<template>
  <div class="remote-identification-error align-center">
    <h2 class="h4 f-small f-color-red align-center m-b-25">
      {{ $t('components.remoteIdentificationError.title') }}
    </h2>
    <p class="header-text f-medium small align-center color-gray-80 m-b-25">
      {{ message }}
    </p>
    <bb-button
      color="blue"
      @click="$emit('cancel')"
    >
      {{ $t('components.remoteIdentificationError.buttonText') }}
    </bb-button>
  </div>
</template>

<script>
  import { BbButton } from '@bigbank/interface-components';
  import { IDENTIFICATION_STATUS } from '../../constants';

  export default {
    name: 'remote-identification-error',
    components: { BbButton },
    props: {
      status: {
        type: String,
        default: undefined,
      },
    },
    computed: {
      message() {
        if (this.status === IDENTIFICATION_STATUS.TECHNICAL_ERROR) {
          return this.$t('components.remoteIdentificationError.generalMessage');
        }

        return this.$t('components.remoteIdentificationError.identificationFailedMessage');
      },
    },
  };
</script>

