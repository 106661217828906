import merge from 'lodash/merge';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { processStep } from '@bigbank/web-loco';
import actions from './actions';
import router from '@/router';
import { customPostHooks } from './customPostHooks';
import mutations from './mutations';
import { APPLICATION_OFFER_DECISION_APPROVED,
  APPLICATION_OFFER_TYPE_UPSALE,
  HIRE_PURCHASE_PRODUCT_NAMES,
  LOAN_PURPOSE,
  PERSON,
  PRODUCT_NAME,
  ROLE } from '@/constants';
import { CO_APPLICANT_ROLES } from '@/constants/application';

export const address = {
  county: null,
  city: null,
  street: null,
  houseNumber: null,
  flatNumber: null,
  postCode: null,
};

// state has to include all nested keys required for the application processStep
// for Vue getters to be reactive
// Some state comes from common
export const state = {
  application: {
    applicant: {
      firstName: null,
      surname: null,
      personalIdentityCode: null,
      bankAccountNumber: null,
      consentMethod: null,
      mainMobileNo: '',
      landlinePhone: '',
      email: null,
      customerIsPEP: null,
      idDocumentType: null,
      maritalStatus: null,
      contactAddress: { ...address },
      identification: { isIdentified: null },
      incomes: [],
      incomeReceivedToBankInBusinessUnitCountry: null,
      incomeMayDecrease: null,
      probableDecreasedIncome: null,
      liabilities: [],
    },
    source: { source: null },
    involveSpouse: null,
    spouse: {
      firstName: null,
      surname: null,
      email: null,
      personalIdentityCode: null,
      mainMobileNo: null,
      landlinePhone: null,
      incomeMayDecrease: null,
      probableDecreasedIncome: null,
      liabilities: [],
      incomes: [],
      incomeReceivedToBankInBusinessUnitCountry: null,
      contactAddress: { ...address },
    },
    involveCoBorrower: null,
    campaignCode: null,
    coBorrower: {
      firstName: null,
      surname: null,
      email: null,
      personalIdentityCode: null,
      mainMobileNo: null,
      landlinePhone: null,
      incomeMayDecrease: null,
      probableDecreasedIncome: null,
      liabilities: [],
      incomes: [],
      incomeReceivedToBankInBusinessUnitCountry: null,
      contactAddress: { ...address },
    },
    marketingConsent: false,
    monthlyPaymentDay: null,
    requiredDocuments: [],
    isSpouseFlow: false,
    offer: {
      decision: null,
      currency: null,
      offeredAmount: null,
      offeredPeriod: null,
      customerMaxAmount: null,
      customerMinAmount: null,
      customerMaxPeriod: null,
      customerMinPeriod: null,
      interestRate: null,
      conclusionFee: null,
      conclusionFeePercent: null,
      administrationFee: null,
      administrationFeePercent: null,
      offeredProductName: null,
      offeredProductType: null,
      offerIncludesRefinance: null,
      rejectedReason: null,
      minSelfFinancingAmount: null,
    },
    offerChange: {
      loanAmount: null,
      loanPeriod: null,
    },
    productName: null,
    segmentName: null,
    initialLoanPeriod: null,
    initialLoanAmount: null,
    personalOffer: {
      type: null,
      customerSegment: null,
    },
    goodsPrice: null,
    selfFinancingAmount: null,
    interestType: null,
    processData: { webShopRedirectUri: null },
    isPartnerApi: false,
    assetCondition: null,
    collaterals: null,
    creditCardOfferAccepted: null,
    downpaymentOriginDescription: null,
  },
  initialSource: null,
  offerAcceptanceDate: null,
  customData: {},
  task: { variables: { fraudWarningModalSeen: null }},
};
export const getters = {
  activePersonRole: (state, { isActivePersonRoleCoApplicant, coApplicantRole }) => {
    if (isActivePersonRoleCoApplicant) {
      return coApplicantRole;
    }

    return PERSON.APPLICANT;
  },
  isActivePersonRoleCoApplicant: () => [ROLE.CO_APPLICANT].includes(router.currentRoute.query.role),
  source: state => state.application.source?.source,
  applicationOrigin: state => state.application.source?.applicationOrigin,
  channel: state => state.application.source?.channel,
  segmentName: state => state.application.segmentName,
  productName: state => state.application.productName,
  loanPurpose: state => state.application.loanPurpose,
  bankDetailsData: state => ({
    ...pick(state.application.applicant, ['bankAccountNumber']),
    monthlyPaymentDay: state.application.monthlyPaymentDay,
  }),
  submitBankAccountNumberData: state => ({ ...pick(state.application.applicant, ['bankAccountNumber']) }),
  applicant: state => state.application.applicant,
  spouse: state => state.application.spouse,
  coBorrower: state => state.application.coBorrower,
  addressData: ({ application }, { activePersonRole }) => ({
    ...pick(application[activePersonRole], ['contactAddress']),
    ...pick(application, ['marketingConsent']),
  }),
  marketingConsent: state => state.application.marketingConsent,
  offerRejectedReason: state => get(state, 'application.offer.rejectedReason'),
  documentsUpload: state => ({
    ...pick(state.application, ['isSpouseFlow']),
    ...pick(state.application, ['requiredDocuments']),
  }),
  offer: state => get(state, 'application.offer'),
  monthlyPaymentDay: state => get(state, 'application.monthlyPaymentDay'),
  initialCalculator: state => ({
    period: state.application.initialLoanPeriod,
    amount: state.application.initialLoanAmount,
    productName: state.application.productName,
    segmentName: state.application.segmentName,
  }),
  personalDetails: state => ({
    applicant: pick(state.application.applicant, [
      'firstName',
      'surname',
      'email',
      'mainMobileNo',
      'landlinePhone',
      'personalIdentityCode',
      'maritalStatus',
      'idDocumentType',
    ]),
    marketingConsent: state.application.marketingConsent,
    loanPurpose: state.application.loanPurpose,
    involveCoBorrower: state.application.involveCoBorrower,
    campaignCode: state.application.campaignCode,
  }),
  leasingProductDetails: state => ({
    goodsPrice: state.application.goodsPrice,
    selfFinancingAmount: state.application.selfFinancingAmount,
    interestType: state.application.interestType,
    productName: state.application.productName,
  }),
  spouseData: state => ({
    ...pick(state.application.spouse, [
      'firstName',
      'surname',
      'email',
      'personalIdentityCode',
      'mainMobileNo',
      'marketingConsent',
      'landlinePhone',
      'idDocumentType',
    ]),
  }),
  coBorrowerData: state => ({
    ...pick(state.application.coBorrower, [
      'firstName',
      'surname',
      'email',
      'personalIdentityCode',
      'mainMobileNo',
      'landlinePhone',
      'idDocumentType',
    ]),
  }),
  applicantFinanceData: state => pick(state.application.applicant, [
    'incomes',
    'incomeReceivedToBankInBusinessUnitCountry',
    'incomeMayDecrease',
    'probableDecreasedIncome',
    'liabilities',
    'employment',
  ]),
  coApplicantFinanceData: (state, getters) => pick(state.application[getters.coApplicantRole], [
    'incomes',
    'incomeReceivedToBankInBusinessUnitCountry',
    'incomeMayDecrease',
    'probableDecreasedIncome',
    'liabilities',
    'employment',
  ]),
  initialSource: state => state.initialSource,
  isUpsale: state => get(state, 'application.personalOffer.type') === APPLICATION_OFFER_TYPE_UPSALE,
  isApplicantIdentified: state => get(state, 'application.applicant.identification.isIdentified'),
  isPartnerApiFlow: state => state.application.isPartnerApi,
  isPartnerApiHpFlow: state => HIRE_PURCHASE_PRODUCT_NAMES.includes(get(state, 'application.productName')),
  webShopRedirectUri: state => get(state, 'application.processData.webShopRedirectUri'),
  customerSegment: state => get(state, 'application.personalOffer.customerSegment'),
  isProductCarFinanceLease: state => {
    const offeredProductName = get(state, 'application.offer.offeredProductName');

    return offeredProductName
      ? offeredProductName === PRODUCT_NAME.CAR_FINANCE_LEASE
      : get(state, 'application.productName') === PRODUCT_NAME.CAR_FINANCE_LEASE;
  },
  isProductHousingLoan: state => {
    const offeredProductName = get(state, 'application.offer.offeredProductName');

    return offeredProductName
      ? offeredProductName === PRODUCT_NAME.HOUSING_LOAN
      : get(state, 'application.productName') === PRODUCT_NAME.HOUSING_LOAN;
  },
  isLoanPurposeCombiningLoans: (state, getters) => getters.loanPurpose === LOAN_PURPOSE.COMBINING_LOANS,
  coApplicantRole: state => get(state, 'application.involveCoBorrower')
    ? CO_APPLICANT_ROLES.CO_BORROWER
    : CO_APPLICANT_ROLES.SPOUSE,
  isCoBorrowerTask: state => !!get(state, 'application.involveCoBorrower'),
  assetCondition: state => state.application.assetCondition,
  isOfferDecisionApproved: state => get(state, 'application.offer.decision') === APPLICATION_OFFER_DECISION_APPROVED,
  offeredProductName: state => get(state, 'application.offer.offeredProductName'),
  offerAcceptanceDate: state => get(state, 'offerAcceptanceDate'),
  customerIsPEP: state => get(state, 'application.applicant.customerIsPEP'),
  realEstateInfo: state => ({
    goodsPrice: state.application.goodsPrice,
    selfFinancingAmount: state.application.selfFinancingAmount,
    initialLoanPeriod: state.application.initialLoanPeriod,
    initialLoanAmount: state.application.initialLoanAmount,
    downpaymentOriginDescription: state.application.downpaymentOriginDescription,
    renovationCost: state.application.renovationCost,
    collaterals: Array.isArray(state.application.collaterals)
      ? state.application.collaterals
      : [],
    additionalInfoText: Array.isArray(state.application.collaterals)
      ? state.application.collaterals.find(collateral => collateral.main)?.additionalInfoText
      : null,
  }),
  offerChangeLoanPurpose: state => get(state, 'application.offerChange.loanPurpose'),
  customData: state => get(state, 'application.customData', {}),
  applicantConsentMethod: state => get(state, 'application.applicant.consentMethod'),
};

export const createProcessStepModule = client => {
  const processModule = processStep.moduleBuilder(
    client,
    router,
    customPostHooks,
  );

  return {
    ...processModule,
    state: merge(processModule.state, state),
    getters: {
      ...processModule.getters,
      ...getters,
    },
    actions: {
      ...processModule.actions,
      ...actions,
    },
    mutations: {
      ...processModule.mutations,
      ...mutations,
    },
  };
};
