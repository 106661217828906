<template>
  <bb-col
    class="p-20"
    style="border: 8px dashed lightgrey"
  >
    <locale-changer />

    <bb-radio
      name="route-select"
      label="Route"
      grouped
      :options="routes"
      :value="$route.name"
      @change="changeRoute"
    />
  </bb-col>
</template>

<script>
  import { BbCol, BbRadio } from '@bigbank/interface-components';
  import LocaleChanger from './LocaleChanger';
  import { processStep } from '@bigbank/web-loco';
  import { mockProcessSteps } from '../../tests/mocks/process-mock';

  export default {
    name: 'development-helper',
    components: {
      LocaleChanger,
      BbCol,
      BbRadio,
    },
    computed: {
      routes() {
        return this.$router.options.routes.map(route => ({
          text: route.path,
          value: route.name,
        }));
      },
    },
    created() {
      // eslint-disable-next-line no-console
      console.log('created', this.$route.name);
      this.setupRoute(this.$route.name);
    },
    methods: {
      async changeRoute(routeName) {
        await this.setupRoute(routeName);
        this.$router.push({ name: routeName });
      },
      async setupRoute(routeName) {
        // eslint-disable-next-line no-console
        console.log('Setting up default data and appearance for route/task', routeName);
        if (mockProcessSteps[routeName]) {
          this.$store.commit(`processStep/${processStep.types.PROCESS_STEP_UPDATED}`, mockProcessSteps[routeName]);
        }
        this.$store.dispatch('ui/updateUi', routeName, { root: true });
      },
    },
  };
</script>
