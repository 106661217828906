import LtLoanOriginationClient from '../../../api/LtLoanOriginationClient';
import { SET_LOADING, SET_INSTITUTIONS } from './types';

const mapInstitutionsList = (institutions = {}) => {
  const { availableInstitutions = [] } = institutions;

  const enrolledInstitutions = institutions?.enrolledInstitutions?.map(institution => {
    const logo = availableInstitutions
      .find(item => item.id === institution.id || item.name === institution.name)?.logo;

    return {
      ...institution,
      logo,
    };
  }) || [];

  return {
    enrolledInstitutions,
    availableInstitutions,
  };
};

const getInstitutionsList = async (store) => {
  return LtLoanOriginationClient.getInstitutionsList()
    .then(({ data }) => {
      store.commit(SET_INSTITUTIONS, mapInstitutionsList(data));
    });
};

const getInstitutions = async (store) => {
  store.commit(SET_LOADING, true);

  try {
    await getInstitutionsList(store);
  } catch (error) {
    store.commit(SET_INSTITUTIONS, {});
    store.dispatch('processStep/handleError', error, { root: true });
  } finally {
    store.commit(SET_LOADING, false);
  }
};

export default { getInstitutions };
