<template>
  <section
    v-if="showIncomeBank"
    v-once
    class="income-bank m-y-30"
  >
    <bb-radio
      v-validate="'required'"
      name="income-bank"
      equal-width
      label-center
      :value="value === null ? null : value.toString()"
      :data-vv-as="$t('application.income.incomeReceivedToRegionalBank.label')"
      :label="$t('application.income.incomeReceivedToRegionalBank.label')"
      :options="options"
      @change="$emit('input', $event === 'true')"
    />
  </section>
</template>

<script>
  import { BbRadio } from '@bigbank/interface-components';
  import { MAIN_INCOME_TYPES } from '@/constants/application';

  export default {
    name: 'income-bank',

    components: { BbRadio },

    inject: ['$validator'],

    props: {
      incomes: {
        type: Array,
        default: () => [],
      },
      value: {
        type: [
          Boolean,
          String,
        ],
        default: null,
      },
    },

    computed: {
      options() {
        return [
          {
            text: this.$t('application.income.incomeReceivedToRegionalBank.options.yes'),
            value: 'true',
          },
          {
            text: this.$t('application.income.incomeReceivedToRegionalBank.options.no'),
            value: 'false',
          },
        ];
      },
      showIncomeBank() {
        return this.incomes.some(({ typeInForm }) => typeInForm === MAIN_INCOME_TYPES.foreignSalary);
      },
    },

    watch: {
      showIncomeBank: {
        handler(show) {
          if (!show) {
            this.$emit('input', null);
          }
        },
      },
    },
  };
</script>
