<template>
  <section class="partner-calculator p-t-15">
    <bb-calculator
      ref="calculator"
      :key="initialized"
      name="calculator"
      sliders
      :amount-step="50"
      :period-step="1"
      :loader="isLoading || loanLoading"
      :locale="$i18n.locale"
      :payment="calculatorData.monthlyPayment"
      v-bind="getProductData"
      @input="onInput"
    />
    <p class="product-disclaimer f-medium f-smallest m-y-15">
      {{ disclaimer }}
    </p>
  </section>
</template>

<script>
  import { BbCalculator } from '@bigbank/interface-components';
  import { mapState, mapActions, mapGetters } from 'vuex';
  import { DEFAULT_MONTHLY_PAYMENT_DAY_UNTIL_PROVIDED } from '@/constants';

  export default {
    name: 'partner-calculator',

    components: { BbCalculator },

    data() {
      return {
        initialized: false,
        calculatorData: {
          amount: null,
          period: null,
          interestRate: null,
          monthlyPayment: null,
        },
      };
    },

    computed: {
      ...mapState('products', ['product']),
      ...mapGetters('products', [
        'isLoading',
        'productType',
        'getProductData',
      ]),
      ...mapGetters('loan', [
        'loanPaymentDetails',
        'loanLoading',
      ]),
      disclaimer() {
        return this.$t('components.calculator.disclaimer', {
          amount: this.$n(this.calculatorData.amount, 'currency'),
          period: this.$n(this.calculatorData.period),
          interestRate: this.$n(this.getProductData.interestRate * 0.01, 'percent'),
          administrationFee: this.$n(this.getProductData.maintenanceFee, 'currency-decimal'),
          conclusionFeeRate: this.$n(this.getProductData.contractConclusionFeeRate * 0.01, 'percent'),
          monthlyPayment: this.$n(this.calculatorData.monthlyPayment, 'currency-decimal'),
          aprc: this.$n(this.loanPaymentDetails[0]?.apr * 0.01, 'percent'),
          totalRepayableAmount: this.$n(this.loanPaymentDetails[0]?.totalRepayableAmount, 'currency-decimal'),
        });
      },
    },

    watch: {
      loanPaymentDetails(newValue) {
        this.calculatorData.monthlyPayment = newValue[0]?.monthlyPayment;
        if (!this.initialized) {
          this.initialized = true;
          this.submit();
        }
      },
    },

    methods: {
      ...mapActions('products', ['setProductData']),
      ...mapActions('loan', ['debouncedCalculateLoanPaymentDetails']),
      onInput(calculatorData) {
        this.calculatorData = {
          ...calculatorData,
          amount: this.initialized ? calculatorData.amount : this.getProductData?.amount,
          period: this.initialized ? calculatorData.period : this.getProductData?.period,
          monthlyPayment: this.$refs.calculator.computedPayment,
        };

        this.debouncedCalculateLoanPaymentDetails({
          productType: this.productType,
          amount: this.calculatorData.amount,
          maturity: this.calculatorData.period,
          monthlyPaymentDay: DEFAULT_MONTHLY_PAYMENT_DAY_UNTIL_PROVIDED,
          interestRate: this.getProductData.interestRate,
          conclusionFee: this.calculatorData.amount * this.getProductData?.contractConclusionFeeRate * 0.01,
          administrationFee: this.getProductData?.maintenanceFee,
        });
      },
      submit() {
        this.setProductData({ ...this.calculatorData });
      },
    },
  };
</script>

<style lang="scss">
  .partner-calculator {
    .bb-calculator {
      padding-left: 0;
      padding-right: 0;

      &__result-sliders.bb-box {
        background-color: #FFFFFF;
      }
    }
  }
</style>
