<template>
  <bb-prefixed-input
    id="phoneField"
    v-validate="'phoneRequired|phone'"
    :data-vv-as="$t('application.applicant.mainMobileNo.label')"
    :label="$t('application.applicant.mainMobileNo.label')"
    :prefixes="prefixes"
    :value="phoneNumberPrefix.value + ' ' + phoneNumberSuffix"
    :prefix="phoneNumberPrefix"
    :suffix="phoneNumberSuffix"
    :formatter="formatterNumericOnly"
    name="phone"
    type="tel"
    @prefix="phoneNumberPrefix = $event; emit()"
    @suffix="phoneNumberSuffix = $event; emit()"
  />
</template>

<script>
  import { BbPrefixedInput } from '@bigbank/interface-components';
  import enumsToOptionsMixin from '../mixins/enumsToOptionsMixin';
  import { splitPhoneNumber } from '@/util/phoneNumber';
  import { isLandlineLT,
           LT_COUNTRY_CALLING_CODE,
           SUPPORTED_COUNTRIES_CALLING_CODES } from '@/util/validators/PhoneNumberValidator';
  import '@bigbank/interface-components/dist/svg/ui/alert-circle-outline.svg';
  import '@bigbank/interface-components/dist/svg/ui/menu-down';

  export default {
    name: 'phone-input',

    components: { BbPrefixedInput },

    mixins: [enumsToOptionsMixin],

    inject: ['$validator'],

    props: {
      /**
       * Entity can be applicant, spouse or coBorrower.
       */
      entity: Object,
    },

    data() {
      const prefixes = this.enumsToOptions(SUPPORTED_COUNTRIES_CALLING_CODES, 'application.applicant.mainMobileNo')
        .map(({ value, text }) => ({
          value,
          label: text,
        }));

      const { prefix, suffix } = (({ mainMobileNo, landlinePhone }) => {
        if (mainMobileNo) {
          return splitPhoneNumber(mainMobileNo);
        }
        if (landlinePhone) {
          return splitPhoneNumber(landlinePhone);
        }

        return {
          prefix: LT_COUNTRY_CALLING_CODE,
          suffix: '',
        };
      })({ ...this.entity });

      const phoneNumberPrefix = prefixes.find(({ value }) => value === prefix);

      return {
        prefixes,
        phoneNumberSuffix: suffix,
        phoneNumberPrefix,
      };
    },

    created() {
      this.emit();
    },

    methods: {
      formatterNumericOnly(value) {
        return value ? value.replace(/\D/g, '') : '';
      },
      emit() {
        const value = this.phoneNumberPrefix.value + this.phoneNumberSuffix;
        this.$emit('input', isLandlineLT(value) ? ({ landlinePhone: value }) : ({ mainMobileNo: value }));
      },
    },
  };
</script>
