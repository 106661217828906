<template>
  <section class="offer-warning bg-white m-b-30 p-y-15 p-x-25 border-gray-30">
    <bb-row class="align-center align-self-center align-items-center">
      <bb-col
        col="2"
        sm="3"
        md="3"
        class="p-0"
      >
        <bb-icon
          name="ui-alert-circle-outline"
          fill="yellow"
          size="24"
        />
      </bb-col>
      <bb-col
        col="22"
        sm
        md
        class="text-center f-smallest f-medium p-0"
      >
        <div>
          {{ $t('views.offer.minSelfFinancingAmountWarning.line1') }}
        </div>
        <div>
          {{ $t('views.offer.minSelfFinancingAmountWarning.line2', { amount: $n(offer.minSelfFinancingAmount, 'currency-decimal') }) }}
        </div>
      </bb-col>
    </bb-row>
  </section>
</template>

<script>
  import { BbCol, BbRow, BbIcon } from '@bigbank/interface-components';
  import { mapGetters } from 'vuex';

  export default {
    name: 'offer-warning',

    components: {
      BbCol,
      BbRow,
      BbIcon,
    },

    computed: { ...mapGetters('processStep', ['offer']) },
  };
</script>

<style lang="scss" scoped>
  .offer-warning {
    border-width: 2px;
    border-radius: 10px;
    border-style: solid;
  }
</style>
