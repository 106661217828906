import Vue from 'vue';
import store from './../../store/store';
import router from './../../router';
import VueGtag from 'vue-gtag';
import { taskDefinitionKeys } from '@/constants';

export const MAYBE_PAGE_LIST = [
  taskDefinitionKeys.ReadUndecidedDecision,
  taskDefinitionKeys.ReviewApprovedAdditionalCheck,
  taskDefinitionKeys.ReviewConditionalNegative,
  taskDefinitionKeys.ReviewRejectedBringCoBorrower,
  taskDefinitionKeys.ReviewApprovedRefinanceConditional,
  taskDefinitionKeys.ReviewApprovedConditional,
];

const YES_PAGE_LIST = [
  taskDefinitionKeys.ReviewApproved,
  taskDefinitionKeys.ReviewApprovedFinal,
  taskDefinitionKeys.ReviewApprovedUpsale,
  taskDefinitionKeys.ReviewApprovedUpsaleFinal,
];

Vue.use(VueGtag, {
  config: { id: window.config.GTAG },
  disableScriptLoad: !window.config.GTAG,
  bootstrap: !!window.config.GTAG,
  pageTrackerEnabled: false,
  onReady() {
    router.afterEach(to => {
      if (MAYBE_PAGE_LIST.includes(to.name)) {
        router.app.$root.$gtag.event('conversion', {
          send_to: `${window.config.GTAG}/l6gUCLjJoKUBEJ-ImtsC`,
          value: store.getters['products/getProductData'].amount,
          transaction_id: '',
        });
      }
      if (YES_PAGE_LIST.includes(to.name)) {
        router.app.$root.$gtag.event('conversion', { send_to: `${window.config.GTAG}/X2hqCJTGoKUBEJ-ImtsC` });
      }
    });
  },
}, router);
