import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('processStep', [
      'isPartnerApiFlow',
      'webShopRedirectUri',
      'productName',
      'isPartnerApiHpFlow',
    ]),
    shouldRedirectToPartnerApi() {
      return this.isPartnerApiFlow && !!this.webShopRedirectUri;
    },
    partnerApiThankYouButtonText() {
      return this.$t('form.backToMerchant');
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    navigateToPartnerApi({ immediate } = { immediate: false }) {
      if (this.shouldRedirectToPartnerApi) {
        setTimeout(
          () => {
            this.logout();
            window.location.href = this.webShopRedirectUri;
          },
          immediate ? 0 : 10000,
        );
      }
    },
  },
};
