import LtLoanOriginationClient from '../../../api/LtLoanOriginationClient';
import { SET_LOADING, SET_ERROR, SET_LOGIN_ERROR } from './types';
import router from '../../../router';
import { pages } from '@/constants';

const login = (store, { personalCodeLastDigits, role }) => {
  const externalId = store.rootGetters['processStep/externalId'];
  store.commit(SET_ERROR, false);
  store.commit(SET_LOGIN_ERROR, false);
  store.commit(SET_LOADING, true);

  return LtLoanOriginationClient.login(externalId, {
    personalCodeLastDigits,
    role,
  })
    .then(() => {
      store.dispatch('processStep/continueProcess', externalId, { root: true });
    })
    .catch((error) => {
      switch (error.response?.status) {
        case 401:
          store.commit(SET_LOGIN_ERROR, true);
          break;
        case 403:
          router.push({ name: pages.unauthorized });
          break;
        default:
          store.commit(SET_ERROR, true);
      }
      store.commit(SET_LOADING, false);
    });
};

export default { login };

