import actions from './actions';
import { SET_LOADING, SET_ERROR, SET_LOGIN_ERROR } from './types';

const state = {
  error: false,
  loading: false,
  loginError: false,
};

const getters = {
  isLoading: state => state.loading,
  hasError: state => state.error,
  hasLoginError: state => state.loginError,
};

const mutations = {
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [SET_LOGIN_ERROR](state, loginError) {
    state.loginError = loginError;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
