<template>
  <bb-upload
    v-if="sections.length"
    v-model="files"
    v-validate="'required|uploadedFilesAreValid'"
    name="file-upload-row"
    :allowed-types="fileUploadSettings.allowedTypes"
    :size-limit="fileUploadSettings.sizeLimit"
    :size-limit-label="$t('views.documents.sizeLimitLabel')"
    :allowed-types-label="$t('views.documents.allowedTypesLabel')"
    :duplicate-file-label="$t('views.documents.duplicateFileLabel')"
    :empty-file-label="$t('views.documents.emptyFileLabel')"
    :sections="sections"
    :heading="heading"
    :allow-duplicate-files-in-different-sections="!canSelectPreviouslyUploadFile"
    :upload-item-class-provider="uploadItemClassProvider"
    flavor="user"
    disable-drag-and-drop
    class="m-t-5 p-b-10 documents-upload"
    @add="onAdd"
    @remove="onRemove"
  >
    <template
      slot="itemButtons"
      slot-scope="{
        removeFile,
        file,
        indexFile: fileIndexInSection,
        section
      }"
    >
      <bb-button
        v-if="shouldShowAddRowButton(section.key, fileIndexInSection)"
        no-background
        icon="ui-plus-circle-outline"
        display="circle"
        @click="cloneType(section.key, fileIndexInSection, file)"
      />
      <bb-button
        no-background
        color="red"
        icon="ui-close"
        display="square"
        class="m-l-20"
        @click="removeFileAndType(removeFile, section, fileIndexInSection, file)"
      />
    </template>

    <li
      slot="uploadCTA"
      slot-scope="{
        section,
        file,
        indexFile: fileIndexInSection,
        openFileDialog,
      }"
      :class="[uploadItemClassProvider(section.key, fileIndexInSection), 'upload-item p-l-30 p-r-30 p-t-10 p-b-10']"
    >
      <bb-row
        gutter="10"
        align="center"
        justify="between"
      >
        <bb-col
          col
          md="15"
        >
          <bb-select
            v-if="section.types[fileIndexInSection].options.length > 1"
            v-model="section.types[fileIndexInSection].value"
            :options="documentTypeOptions(section.types[fileIndexInSection].options)"
            framed
            noempty
            small
            name="doc-type-select"
            class="p-0"
          />
          <div
            v-else
            class="f-smallest color-gray"
          >
            {{ documentTypeOptions([section.types[fileIndexInSection].value])[0].text }}
          </div>
        </bb-col>
        <bb-col col="auto">
          <bb-button
            no-underline
            :label="$t('views.documents.upload')"
            icon="ui-arrow-up-circle-outline"
            display="link"
            @click="openFileDialog({
              section: section.key,
              docTypes: [
                getDocType(section, fileIndexInSection)
              ],
              key: file.key
            })"
          />
          <bb-button
            v-if="section.types[fileIndexInSection].cloned"
            no-background
            color="red"
            icon="ui-close"
            class="p-0 m-l-20"
            @click="removeType(section.key, fileIndexInSection, file.key)"
          />
        </bb-col>
      </bb-row>

      <template v-if="canSelectPreviouslyUploadFile">
        <bb-row class="m-0">
          <bb-checkbox
            v-if="uniqueFiles(section, fileIndexInSection).length"
            v-model="section.types[fileIndexInSection].useExistingFile"
            size="tiny"
            center-checkbox
            style="flex: 1"
            name="use existing file"
            class="nm-l-10"
          >
            {{ $t('views.documents.useUploadedFiles') }}
          </bb-checkbox>
        </bb-row>

        <ul v-if="section.types[fileIndexInSection].useExistingFile">
          <bb-upload-item
            v-for="(existingFile, indexExistingFile) in uniqueFiles(section, fileIndexInSection)"
            :key="'existingFile' + indexExistingFile"
            :file="existingFile.file"
            :description="existingFile.description"
            background-gray
          >
            <bb-checkbox
              :name="'select file ' + indexExistingFile"
              no-padding
              class="align-self-center"
              style="width: 30px"
              @input="selectExistingFile(section, fileIndexInSection, file.key, existingFile)"
            />
          </bb-upload-item>
        </ul>
      </template>
    </li>
  </bb-upload>
</template>

<script>
  import { BbButton,
           BbCheckbox,
           BbRow,
           BbCol,
           BbSelect,
           BbUpload,
           BbUploadItem } from '@bigbank/interface-components';
  import '@bigbank/interface-components/dist/svg/ui';
  import '@bigbank/interface-components/dist/svg/client/upload';
  import enumsToOptionsMixin from '../mixins/enumsToOptionsMixin';
  import { FILE_UPLOAD_SETTINGS, FIXED_SECTIONS } from '@/constants/requiredDocuments';
  import { ASSET_CONDITION,
           DOCUMENT_TYPE,
           INCOME_TYPE_IN_FORM,
           REFINANCING_LIABILITY_TYPE } from '@/constants/application';
  import Section from '../dtos/Section';
  import SectionType from '../dtos/SectionType';
  import { mapGetters } from 'vuex';

  import has from 'lodash/has';
  import invert from 'lodash/invert';
  import replace from 'lodash/replace';

  export default {
    name: 'document-upload',

    components: {
      BbUpload,
      BbRow,
      BbCol,
      BbButton,
      BbSelect,
      BbCheckbox,
      BbUploadItem,
    },

    mixins: [enumsToOptionsMixin],

    props: {
      canSelectPreviouslyUploadFile: {
        type: Boolean,
        required: true,
      },
      isRefinancingPage: {
        type: Boolean,
        required: true,
      },
    },

    data () {
      return {
        sections: [],
        files: [],
        fileUploadSettings: FILE_UPLOAD_SETTINGS,
      };
    },

    computed: {
      ...mapGetters('processStep', [
        'assetCondition',
        'isProductHousingLoan',
        'productName',
      ]),
      areFilesSetForAllSections () {
        return this.sections.length > 0 &&
          this.sections.length === new Set(this.files.map(file => file.section)).size;
      },
      areFilesSetForAllMandatorySections () {
        const filesDocTypesBySections = this.uploadedFileDocTypesBySection;

        if (!this.sections.length) {
          return false;
        }

        return this.sections
          .filter(({ mandatory }) => mandatory === undefined || mandatory === true)
          .map(section => section.types.every(sectionType =>
            sectionType.options.some(type => filesDocTypesBySections[section.key]?.has(type))))
          .every(value => value);
      },
      uploadedFileDocTypesBySection () {
        return this.files.reduce((obj, file) => {
          if (!obj[file.section]) {
            obj[file.section] = new Set();
          }
          file.docTypes.forEach(type => obj[file.section].add(type));

          return obj;
        }, {});
      },
      isUploadingOnlySaleOffer () {
        return this.sections.length === 1 && this.getDocType(this.sections[0], 0) === DOCUMENT_TYPE.saleOffer;
      },
      heading () {
        if (this.isUploadingOnlySaleOffer) {
          return this.assetCondition === ASSET_CONDITION.used
            ? this.$t('views.documents.subTitleAssetConditionUsed')
            : this.$t('views.documents.subTitleAssetConditionNew');
        }

        return this.$t('views.documents.subTitle');
      },
    },

    watch: {
      files: {
        deep: true,
        immediate: true,
        handler () {
          this.$emit('files-updated', this.files);
          this.$emit('are-files-set-for-all-mandatory-sections-updated', this.areFilesSetForAllMandatorySections);
        },
      },
      areFilesSetForAllSections: {
        immediate: true,
        handler (value) {
          this.$emit('are-files-set-for-all-sections-updated', value);
        },
      },
    },

    mounted () {
      this.adjustTranslationsLabelsLocally(false);

      this.sections = Object.entries(
        this.$store.getters['processStep/documentsUpload'].requiredDocuments
          .reduce((result, requiredDocument) => {
            const label = this.sectionLabel(requiredDocument);

            if (!result[label]) {
              result[label] = {
                key: `${requiredDocument.name}${this.optionalSectionPostfix(requiredDocument.mandatory)}`,
                options: [],
                mandatory: requiredDocument.mandatory,
              };
            }

            result[label].options.push(requiredDocument.options);

            return result;
          }, {}),
      ).map(([
        sectionLabel,
        { options, key, mandatory },
      ]) => new Section(sectionLabel, options, key, this.fileUploadSettings.fileCountLimitInSection, mandatory));
    },

    beforeDestroy() {
      this.adjustTranslationsLabelsLocally(true);
    },

    methods: {
      documentTypeOptions (values) {
        return this.enumsToOptions(DOCUMENT_TYPE, 'application.documentType')
          .filter(option => values.includes(option.value));
      },
      sectionLabel (requiredDocument) {
        if (this.isRefinancingPage) {
          return `${requiredDocument.name} (${this.refinancingLiabilityTypeTranslation(requiredDocument.description)})`;
        } else {
          return this.sectionHeaderTranslation(requiredDocument);
        }
      },
      refinancingLiabilityTypeTranslation (refinancingLiabilityType) {
        const typeKey = (invert(REFINANCING_LIABILITY_TYPE))[refinancingLiabilityType];

        return this.$t(`application.refinancingLiabilityType.${typeKey}`);
      },
      sectionHeaderTranslation ({ mandatory, name: type = FIXED_SECTIONS.default }) {
        const typeKey = (invert(INCOME_TYPE_IN_FORM))[type];
        if (typeKey) {
          return this.$t(`application.income.typeInForm.options.${typeKey}`);
        }

        return this.$t(`views.documents.sections.${(invert(FIXED_SECTIONS))[type]}${this.optionalSectionPostfix(mandatory)}`);
      },
      optionalSectionPostfix (mandatory) {
        return mandatory === false ? '_optional' : '';
      },
      uploadItemClassProvider (sectionKey, rowIndexInSection) {
        const currentSection = this.sections.filter(section => section.key === sectionKey)[0];
        const currentRow = currentSection.types[rowIndexInSection];
        const indexOfLastRowInSubSection = currentSection.types.map(type => type.value).lastIndexOf(currentRow.value);

        const shouldShowSeparator = rowIndexInSection !== currentSection.types.length - 1 && indexOfLastRowInSubSection === rowIndexInSection;

        return shouldShowSeparator ? 'sub-section-separator' : '';
      },
      shouldShowAddRowButton (sectionKey, rowIndexInSection) {
        const currentSection = this.sections.filter(section => section.key === sectionKey)[0];
        const currentRow = currentSection.types[rowIndexInSection];
        const filesForGroup = this.files
          .filter(file => file.section === sectionKey && file.docTypes && file.docTypes[0] === currentRow.value);
        const rowsForGroup = currentSection.types.filter(type => type.value === currentRow.value);
        const lastGroupRowIndex = currentSection.types.map(type => type.value).lastIndexOf(currentRow.value);
        const hasError = filesForGroup.filter(file => has(file, 'errorMessage')).length > 0;

        return filesForGroup.length === rowsForGroup.length && rowIndexInSection === lastGroupRowIndex && !hasError;
      },
      uniqueFiles (section, fileIndexInSection) {
        const typeValue = section.types[fileIndexInSection].value;
        const docTypesOfFileDuplicatesInSection = this.files
          .filter(file => file.section === section.key)
          .filter(file => file.duplicateOf)
          .reduce((result, file) =>
            this.addToValues(result, file.duplicateOf.key, file.docTypes[0]), {},
          );

        return this.files
          .filter(file =>
            !file.duplicateOf &&
            file.file &&
            (!docTypesOfFileDuplicatesInSection[file.key] || !docTypesOfFileDuplicatesInSection[file.key].includes(typeValue)) &&
            !(file.section === section.key && file.docTypes[0] === typeValue));
      },
      selectExistingFile (_section, fileIndexInSection, fileKey, existingFile) {
        const section = this.sections.find(v => v.key === _section.key);
        const fileDocumentType = this.getDocType(section, fileIndexInSection);
        this.files.push({
          file: existingFile.file,
          section: section.key,
          docTypes: [fileDocumentType],
          key: fileKey,
          duplicateOf: existingFile,
          description: this.documentTypeTranslation(fileDocumentType),
        });
        section.types[fileIndexInSection].useExistingFile = false;
      },
      getDocType (section, fileIndexInSection) {
        return section.types[fileIndexInSection].value;
      },
      documentTypeTranslation (documentType) {
        const typeKey = (invert(DOCUMENT_TYPE))[documentType];

        return this.$t(`application.documentType.options.${typeKey}`);
      },
      onAdd (file) {
        if (file.docTypes) {
          file.description = this.documentTypeTranslation(file.docTypes[0]);
        }
      },
      onRemove (removedFile) {
        this.$nextTick(() => {
          const firstDuplicate = this.files.find(file =>
            file.duplicateOf && file.duplicateOf.key === removedFile.key,
          );

          if (firstDuplicate) {
            firstDuplicate.duplicateOf = null;
            delete firstDuplicate.duplicateOf;
            const otherDuplicates = this.files.filter(file =>
              file.duplicateOf && file.duplicateOf.key === removedFile.key,
            );
            otherDuplicates.forEach(anotherDuplicate => anotherDuplicate.duplicateOf = firstDuplicate);
          }
        });
      },
      cloneType (sectionKey, fileIndexInSection, file) {
        const section = this.sections.find(v => v.key === sectionKey);
        const typeToClone = section.types[fileIndexInSection];
        const clonedType = new SectionType(typeToClone.options);
        clonedType.value = typeToClone.value;
        clonedType.cloned = true;
        section.types.splice(fileIndexInSection + 1, null, clonedType);
        section.fixedFileCount++;
        this.increaseFileKeysAfter(file.key);
      },
      removeType (sectionKey, fileIndexInSection, fileKey) {
        const section = this.sections.find(v => v.key === sectionKey);
        section.types.splice(fileIndexInSection, 1);
        section.fixedFileCount--;
        this.decreaseFileKeysAfter(fileKey);
      },
      removeFileAndType (removeFileFunction, section, fileIndexInSection, file) {
        removeFileFunction(null, file.key);
        if (section.types[fileIndexInSection].cloned) {
          this.removeType(section.key, fileIndexInSection, file.key);
        } else {
          const currentRow = section.types[fileIndexInSection];
          const rowsForSubSection = section.types.filter(type => type.value === currentRow.value);
          if (rowsForSubSection.length > 1) {
            section.types[fileIndexInSection + 1].cloned = false;
            this.removeType(section.key, fileIndexInSection, file.key);
          }
        }
      },
      increaseFileKeysAfter (key) {
        this.files = this.files.map(file => file.key > key
          ? Object.assign(file, { key: file.key + 1 })
          : file,
        );
      },
      decreaseFileKeysAfter (key) {
        this.files = this.files.map(file => file.key > key
          ? Object.assign(file, { key: file.key - 1 })
          : file,
        );
      },
      addToValues (result, key, value) {
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(value);

        return result;
      },
      adjustTranslationsLabelsLocally (reset) {
        if (!this.isProductHousingLoan ||
          !this.$te(`application.documentType.periods.bankStatement.${this.productName}`) ||
          !this.$te('application.documentType.options.bankStatement')
        ) {
          return;
        }

        const message = this.$i18n.getLocaleMessage(this.$i18n.locale);
        message.application.documentType.options.bankStatement =
          replace(
            message.application.documentType.options.bankStatement,
            `@:application.documentType.periods.bankStatement.${reset ? 'HOUSING_LOAN' : 'default'}`,
            `@:application.documentType.periods.bankStatement.${reset ? 'default' : 'HOUSING_LOAN'}`,
          );
        this.$i18n.setLocaleMessage(this.$i18n.locale, message);
      },
    },
  };
</script>

<style lang="scss">
  .documents-upload {
    h3.bb-upload__heading {
      text-align: center !important;
    }
  }
</style>

<style scoped lang="scss">
  .max-w-65 {
    max-width: 65%
  }
</style>
