<template>
  <bb-box class="text-center">
    <p class="strong uppercase color-gray-70 f-tiny bold">
      {{ $t('components.offeredAmountBox.offeredAmount') }}
    </p>
    <p class="strong color-blue h3 m-b-0">
      {{ $n(amount, 'currency') }}
    </p>
  </bb-box>
</template>

<script>
  import { BbBox } from '@bigbank/interface-components';

  export default {
    name: 'offered-amount-box',
    components: { BbBox },
    props: {
      amount: {
        type: Number,
        default: 0,
      },
    },
  };
</script>
