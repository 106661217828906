import { products } from '@bigbank/web-loco';
import router from '../../../router';
import actions from './actions';
import { SET_APPLICATION_MAPPING } from './types';
import { PRODUCT_NAME } from '@/constants';

const state = {
  products: [],
  applicationMapping: null,
};

const mutations = {
  [SET_APPLICATION_MAPPING]: (state, applicationMapping) => {
    state.applicationMapping = applicationMapping;
  },
};

const getters = {
  loanPurposes: state => state.applicationMapping?.loanPurposes,
  productType: state => state.applicationMapping?.productType,
  initialApplicationData: state => ({
    segmentName: state.applicationMapping?.segmentName,
    productName: state.applicationMapping?.productName,
  }),
  applicationMappingId: state => state.applicationMapping?.id,
  isCarFinanceLeaseProduct: (state) => state.applicationMapping?.productName === PRODUCT_NAME.CAR_FINANCE_LEASE,
  isHousingLoanProduct: (state) => state.applicationMapping?.productName === PRODUCT_NAME.HOUSING_LOAN,
};

export const createProductsModule = (client) => {
  const productModule = products.moduleBuilder(client, router);

  return {
    ...productModule,
    mutations: {
      ...productModule.mutations,
      ...mutations,
    },
    actions: {
      ...productModule.actions,
      ...actions,
    },
    getters: {
      ...productModule.getters,
      ...getters,
    },
    state: {
      ...productModule.state,
      ...state,
    },
  };
};
