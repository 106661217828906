const MOBILE_NUMBER_MIN_LENGTH = 11;
const MOBILE_NUMBER_MAX_LENGTH = 16;
export const LT_COUNTRY_CALLING_CODE = '+370';
export const SUPPORTED_COUNTRIES_CALLING_CODES = {
  IE: '+353',
  DK: '+45',
  EE: '+372',
  GB: '+44',
  LV: '+371',
  LT: LT_COUNTRY_CALLING_CODE,
  NO: '+47',
  NL: '+31',
  FR: '+33',
  FI: '+358',
  SE: '+46',
  DE: '+49',
};

const normalizeValue = valueWithCountryCodeSeparator => valueWithCountryCodeSeparator.replace(' ', '');
const isValidForeignMobile = normalizedValue => {
  return FOREIGN_MOBILE_NUMBER_RULE.test(normalizedValue) &&
        normalizedValue.length >= MOBILE_NUMBER_MIN_LENGTH &&
        normalizedValue.length <= MOBILE_NUMBER_MAX_LENGTH;
};
export const isLandlineLT = number => number && LT_LAND_LINE_NUMBER_RULE.test(normalizeValue(number));

const LT_LAND_LINE_NUMBER_RULE = new RegExp(`^\\${ LT_COUNTRY_CALLING_CODE }((3[1478][0-9]{6})|(4[12456][0-9]{6})|([5][0-9]{7}))$`);
const LT_MOBILE_NUMBER_RULE = new RegExp(`^\\${ LT_COUNTRY_CALLING_CODE }([6][0-9]{7})$`);
const FOREIGN_MOBILE_NUMBER_RULE = new RegExp(`^(?!\\${ LT_COUNTRY_CALLING_CODE })(\\+\\d{10,15})$`);

export const phoneNumberRequiredValidator = {
  validate: value => {
    const normalizedValue = normalizeValue(value);
    const prefix = Object.values(SUPPORTED_COUNTRIES_CALLING_CODES).find(prefix => normalizedValue.startsWith(prefix));
    const suffix = normalizedValue.split(prefix)[1];

    return typeof suffix === 'string' && suffix.length > 0;
  },
};

export default {
  validate: value => {
    const normalizedValue = normalizeValue(value);

    if (normalizedValue.startsWith(LT_COUNTRY_CALLING_CODE)) {
      return LT_MOBILE_NUMBER_RULE.test(normalizedValue) || isLandlineLT(normalizedValue);
    }

    if (Object.values(SUPPORTED_COUNTRIES_CALLING_CODES).some(code => code !== LT_COUNTRY_CALLING_CODE && normalizedValue.startsWith(code))) {
      return isValidForeignMobile(normalizedValue);
    }

    return false;
  },
};
