<template>
  <bb-application-page
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="submit"
  >
    <bb-public-header
      slot="header"
      class="text-center"
      :title="$t(`views.spousePersonalDetails.${coApplicantRole}.title`)"
      icon="client-identification"
    />

    <i18n
      class="text-center f-strong m-b-20 color-gray-90 f-small f-gotham-medium"
      path="views.spousePersonalDetails.subTitle"
      tag="p"
    >
      <span class="color-blue f-strong">
        {{ applicant.firstName }} {{ applicant.surname }}
      </span>
    </i18n>
    <bb-separator
      class="m-b-10"
      dashed
    />
    <bb-input
      id="firstNameField"
      v-model="coApplicant.firstName"
      v-validate="{required: true, min: 2, max: 35, name: true, doesNotMatchCoApplicant: [null, coApplicant.surname, applicant.firstName, applicant.surname]}"
      name="first-name"
      :label="$t('application.applicant.firstName.label')"
      :data-vv-as="$t('application.applicant.firstName.label')"
    />
    <bb-input
      id="surnameField"
      v-model="coApplicant.surname"
      v-validate="{required: true, min: 2, max: 35, name: true, doesNotMatchCoApplicant: [coApplicant.firstName, null, applicant.firstName, applicant.surname]}"
      name="surname"
      :label="$t('application.applicant.surname.label')"
      :data-vv-as="$t('application.applicant.surname.label')"
    />
    <bb-masked-input
      id="personalIdentityCodeField"
      v-model="coApplicant.personalIdentityCode"
      v-validate="{required: true, personalCode: true, is_not: applicant.personalIdentityCode}"
      name="personal-identity-code"
      :label="$t('application.applicant.personalIdentityCode.label')"
      :data-vv-as="$t('application.applicant.personalIdentityCode.label')"
      :mask="personalCodeMask"
      :disabled="!!coApplicantData.personalIdentityCode"
      inputmode="numeric"
      pattern="[0-9]*"
    />
    <bb-input
      id="emailField"
      v-model="coApplicant.email"
      v-validate="'required|email'"
      name="email"
      :label="$t('application.applicant.email.label')"
      :data-vv-as="$t('application.applicant.email.label')"
    />
    <phone-input
      :entity="coApplicant"
      @input="phoneNumber = $event"
    />
    <bb-select
      id="idDocumentField"
      v-model="coApplicant.idDocumentType"
      v-validate="'required'"
      :selected="coApplicant.idDocumentType"
      :options="idDocumentOptions"
      :help-text="$t('application.applicant.idDocument.helpText')"
      :label="$t('application.applicant.idDocument.label')"
      :data-vv-as="$t('application.applicant.idDocument.label')"
      name="id-document"
      help-active
    />
    <div class="m-t-30">
      <bb-checkbox
        id="marketingConsentField"
        v-model="coApplicant.marketingConsent"
        background
        name="marketing-consent"
      >
        {{ $t('application.marketingConsent.label') }}
      </bb-checkbox>
      <marketing-consent-modal />
    </div>
    <agree-with-consent-modal
      class="m-t-10"
      :applicant="coApplicant"
    />
    <identification-modal
      v-if="isIdentifyOnline && !isAuthenticatedApplicant"
      ref="identificationModal"
    />
  </bb-application-page>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { BbApplicationPage,
           BbCheckbox,
           BbInput,
           BbMaskedInput,
           BbPublicHeader,
           BbSelect, BbSeparator } from '@bigbank/interface-components';
  import '@bigbank/interface-components/dist/svg/client/identification';
  import enumsToOptionsMixin from '../../mixins/enumsToOptionsMixin';
  import { ID_DOCUMENT, SPOUSE_ID_DOCUMENTS, taskDefinitionKeys as tasks } from '../../constants';
  import MarketingConsentModal from '../../components/MarketingConsentModal';
  import AgreeWithConsentModal from '../../components/AgreeWithConsentModal';
  import PhoneInput from '../../components/PhoneInput';
  import IdentificationModal from '../../components/identification/IdentificationModal';

  export default {
    name: 'spouse-personal-details',
    components: {
      BbPublicHeader,
      BbApplicationPage,
      BbInput,
      BbMaskedInput,
      BbSelect,
      BbCheckbox,
      BbSeparator,
      MarketingConsentModal,
      AgreeWithConsentModal,
      IdentificationModal,
      PhoneInput,
    },
    mixins: [enumsToOptionsMixin],
    data() {
      const coApplicantRole = this.$store.getters['processStep/coApplicantRole'];
      const coApplicantData = this.$store.getters[`processStep/${coApplicantRole}Data`];

      return {
        personalCodeMask: '11111111111',
        coApplicant: {
          firstName: null,
          surname: null,
          personalIdentityCode: null,
          idDocumentType: null,
          marketingConsent: false,
          ...coApplicantData,
        },
        phoneNumber: {},
      };
    },
    computed: {
      ...mapGetters('processStep', [
        'spouseData',
        'coBorrowerData',
        'isLoading',
        'applicant',
        'definitionKey',
        'coApplicantRole',
      ]),
      ...mapGetters('auth', ['isAuthenticatedApplicant']),
      ...mapGetters('products', ['isHousingLoanProduct']),
      coApplicantData() {
        return this[`${this.coApplicantRole}Data`];
      },
      isIdentifyOnline() {
        return this.definitionKey === tasks.IdentifyOnline;
      },
      idDocumentOptions () {
        const documents = this.isHousingLoanProduct ? ID_DOCUMENT : SPOUSE_ID_DOCUMENTS;

        return this.enumsToOptions(documents, 'application.applicant.idDocument');
      },
    },
    created() {
      this.initializeFromApplication();
    },
    mounted() {
      if (this.isIdentifyOnline && this.isAuthenticatedApplicant) {
        this.updateProcessStep({});
      }
    },
    methods: {
      ...mapActions('processStep', [
        'updateProcessStep',
        'updateApplication',
      ]),
      ...mapActions('identification', [
        'getIdentificationOptions',
        'resetIdentification',
      ]),
      ...mapActions('products', ['initializeFromApplication']),
      async submit () {
        const application = {
          [this.coApplicantRole]: {
            ...this.coApplicant,
            mainMobileNo: undefined,
            landlinePhone: undefined,
            ...this.phoneNumber,
            marketingConsent: !!this.coApplicant.marketingConsent,
          },
        };

        if (this.isIdentifyOnline) {
          delete application[this.coApplicantRole].personalIdentityCode;
          await this.updateApplication({ application });
          await this.resetIdentification();
          await this.getIdentificationOptions({ person: this.coApplicantRole });
        } else {
          application[this.coApplicantRole].agreeWithConsent = true;
          application[this.coApplicantRole].agreeToPersonalDataProcessing = true;
          this.updateProcessStep({ application });
        }
      },
    },
  };
</script>
