<template>
  <bb-application-page
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isPageLoading,
      loading: isPageLoading,
      disclaimerText: submitDisclaimer
    }"
    @submit="submit"
  >
    <bb-public-header
      slot="header"
      :title="title"
      icon="client-personal"
      class="text-center"
    />
    <bb-input
      id="firstNameField"
      v-model="applicant.firstName"
      v-validate="'required|min:2|max:35|name'"
      name="first-name"
      :disabled="!!getAuthenticatedUserData.firstName || isUpsale"
      :label="$t('application.applicant.firstName.label')"
      :data-vv-as="$t('application.applicant.firstName.label')"
    />
    <bb-input
      id="surnameField"
      v-model="applicant.surname"
      v-validate="'required|min:2|max:35|name'"
      name="surname"
      :disabled="!!getAuthenticatedUserData.surname || isUpsale"
      :label="$t('application.applicant.surname.label')"
      :data-vv-as="$t('application.applicant.surname.label')"
    />
    <bb-masked-input
      id="personalIdentityCodeField"
      v-model="applicant.personalIdentityCode"
      v-validate="'required|personalCode'"
      :disabled="isIdentifyOnline || !!getAuthenticatedUserData.personalIdentityCode || isUpsale"
      name="personal-identity-code"
      :label="$t('application.applicant.personalIdentityCode.label')"
      :data-vv-as="$t('application.applicant.personalIdentityCode.label')"
      :mask="personalCodeMask"
      inputmode="numeric"
      pattern="[0-9]*"
    />
    <bb-input
      id="emailField"
      v-model="applicant.email"
      v-validate="'required|email'"
      name="email"
      :label="$t('application.applicant.email.label')"
      :data-vv-as="$t('application.applicant.email.label')"
    />
    <phone-input
      :entity="applicant"
      @input="phoneNumber = $event"
    />
    <bb-select
      id="maritalStatusField"
      v-model="applicant.maritalStatus"
      v-validate="'required'"
      :selected="applicant.maritalStatus"
      :options="maritalStatusOptions"
      :label="$t('application.applicant.maritalStatus.label')"
      :data-vv-as="$t('application.applicant.maritalStatus.label')"
      name="marital-status"
    />
    <bb-select
      id="idDocumentField"
      v-model="applicant.idDocumentType"
      v-validate="'required'"
      :selected="applicant.idDocumentType"
      :options="idDocumentOptions"
      :help-text="$t('application.applicant.idDocument.helpText')"
      :label="$t('application.applicant.idDocument.label')"
      :data-vv-as="$t('application.applicant.idDocument.label')"
      name="id-document"
      help-active
    />
    <bb-select
      v-if="loanPurposeVisible"
      id="loanPurposeField"
      v-model="loanPurpose"
      v-validate="'required'"
      :selected="loanPurpose"
      :options="loanPurposeOptions"
      :help-text="$t('application.loanPurpose.helpText')"
      :label="$t('application.loanPurpose.label')"
      :data-vv-as="$t('application.loanPurpose.label')"
      :disabled="isLoanPurposeDisabled"
      name="loan-purpose"
      help-active
    />
    <bb-input
      id="campaignCodeField"
      v-model="campaignCode"
      v-validate="{
        alphaNum: true,
        min: 1,
        max: 256,
        campaignCode: productNameForCampaignCodeValidation,
      }"
      name="campaign-code"
      data-vv-validate-on="change"
      :label="$t('application.campaignCode.label')"
      :data-vv-as="$t('application.campaignCode.label')"
      :disabled="isIdentifyOnline"
    />
    <div
      v-if="showCoBorrower"
      class="m-t-30"
    >
      <bb-checkbox
        id="loanCoBorrowerCheck"
        v-model="involveCoBorrower"
        name="loan-co-borrower"
        background
        :label="$t('application.involveCoBorrower.label')"
        :disabled="isIdentifyOnline"
      />
    </div>
    <div class="m-t-30">
      <bb-checkbox
        id="marketingConsentField"
        v-model="marketingConsent"
        background
        name="marketing-consent"
      >
        {{ $t('application.marketingConsent.label') }}
      </bb-checkbox>
      <marketing-consent-modal />
    </div>
    <agree-with-consent-modal
      class="m-t-10"
      :applicant="applicant"
    />
    <identification-modal
      v-if="isIdentifyOnline && !isAuthenticatedApplicant"
      ref="identificationModal"
    />
    <the-captcha
      v-if="showCaptcha"
      ref="theCaptcha"
      @verify="submit"
      @error="onCaptchaError"
    />
  </bb-application-page>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { BbApplicationPage,
           BbCheckbox,
           BbInput,
           BbMaskedInput,
           BbPublicHeader,
           BbSelect } from '@bigbank/interface-components';
  import enumsToOptionsMixin from '../mixins/enumsToOptionsMixin';
  import personalDetailsMixin from '../mixins/personalDetailsMixin';
  import { SOURCE,
           CHANNEL,
           ID_DOCUMENT,
           MARITAL_STATUS,
           taskDefinitionKeys as tasks,
           APPLICATION_ORIGIN } from '../constants';
  import MarketingConsentModal from '../components/MarketingConsentModal';
  import AgreeWithConsentModal from '../components/AgreeWithConsentModal';
  import PhoneInput from '../components/PhoneInput';
  import IdentificationModal from '../components/identification/IdentificationModal';
  import TheCaptcha from '../components/TheCaptcha';
  import { APP_MAP_BY_PRODUCT_URL_NAME,
           getOptionalProductValuesFromUrlByProductType } from '@/util/applicationMapping';
  import toNumber from 'lodash/toNumber';
  import { PRODUCT_REGISTRY } from '@/constants';
  import { ACCEPTABLE_HCAPTCHA_CLIENT_API_ERROR_CODES } from '@/constants/hCaptcha';

  export default {
    name: 'personal-details',

    components: {
      BbPublicHeader,
      BbApplicationPage,
      BbInput,
      BbMaskedInput,
      BbSelect,
      BbCheckbox,
      MarketingConsentModal,
      AgreeWithConsentModal,
      IdentificationModal,
      PhoneInput,
      TheCaptcha,
    },

    mixins: [
      enumsToOptionsMixin,
      personalDetailsMixin,
    ],

    data () {
      return {
        isCaptchaLoading: false,
        isLoanPurposeDisabled: false,
      };
    },

    computed: {
      ...mapGetters('processStep', [
        'initialSource',
        'isLoading',
        'definitionKey',
        'isUpsale',
      ]),
      ...mapGetters('processStep', {
        applicationSource: 'source',
        applicationChannel: 'channel',
      }),
      ...mapGetters('products', [
        'loanPurposes',
        'productType',
        'isHousingLoanProduct',
      ]),
      ...mapGetters('ui', ['submitDisclaimer']),
      ...mapGetters('auth', [
        'getAuthenticatedUserData',
        'isAuthenticatedApplicant',
      ]),
      isPageLoading() {
        return this.isLoading || this.isCaptchaLoading;
      },
      source() {
        return this.applicationSource || this.initialSource || SOURCE.BIGBANK;
      },
      channel() {
        if (!this.applicationChannel && this.$route?.query?.bbchannel === 'selfservice') {
          return CHANNEL.SELF_SERVICE;
        }

        return this.applicationChannel;
      },
      maritalStatusOptions () {
        return this.enumsToOptions(MARITAL_STATUS, 'application.applicant.maritalStatus');
      },
      idDocumentOptions() {
        return this.enumsToOptions(ID_DOCUMENT, 'application.applicant.idDocument');
      },
      loanPurposeOptions() {
        return this.loanPurposes.map(apiEnum => ({
          value: apiEnum,
          text: this.$t(`application.loanPurpose.options.${apiEnum}`),
        }));
      },
      loanPurposeVisible() {
        return this.loanPurposes?.length > 1;
      },
      isIdentifyOnline() {
        return this.definitionKey === tasks.IdentifyOnline;
      },
      isPartnerLandingPageApplication() {
        return this.$route.query.regCode && this.$route.query.productName;
      },
      title() {
        return (this.source !== SOURCE.BIGBANK && this.definitionKey === tasks.SubmitPersonalDetails)
          ? this.$t('views.personalDetails.alternativeTitle')
          : this.$t('views.personalDetails.title');
      },
      showCoBorrower() {
        return !!this.config?.ENABLE_HOUSING_LOAN_CO_BORROWER && !!this.isHousingLoanProduct;
      },
      showCaptcha() {
        return !!this.config?.ENABLE_HCAPTCHA && !this.definitionKey;
      },
      shouldCoBorrowerBeInvolved() {
        return (!this.isIdentifyOnline && this.showCoBorrower && this.involveCoBorrower) ||
          (this.isHousingLoanProduct && this.applicant?.maritalStatus === MARITAL_STATUS.married);
      },
      productNameForCampaignCodeValidation () {
        return APP_MAP_BY_PRODUCT_URL_NAME[this.$route.query.productName]?.calculatorProductName ||
          PRODUCT_REGISTRY.SMALL_LOAN;
      },
    },

    async created() {
      if (![
        tasks.IdentifyOnline,
        tasks.SubmitPersonalDetails,
      ].includes(this.definitionKey)) {
        await this.$store.dispatch('ui/updateUi', 'SubmitPersonalDetails', { root: true });
        await this.$store.dispatch('products/initializeFromUrl', {}, { root: true });
        await this.prefillLoanPurpose();
        await this.prefillCampaignCode();
      }

      if (tasks.IdentifyOnline === this.definitionKey) {
        this.isLoanPurposeDisabled = true;

        if (this.isAuthenticatedApplicant) {
          this.updateProcessStep({});
        }
      }
    },

    methods: {
      ...mapActions('processStep', [
        'createPublicApplication',
        'updateProcessStep',
        'updateProcessWithCalculatorData',
        'updateApplicationWithCalculatorData',
      ]),
      ...mapActions('identification', [
        'getIdentificationOptions',
        'resetIdentification',
      ]),

      async prefillLoanPurpose () {
        const purpose = this.$route.query?.purpose;

        if (this.loanPurposes.includes(purpose)) {
          this.loanPurpose = purpose;
          await this.$validator.validate('loan-purpose');
          this.isLoanPurposeDisabled = true;
        }
      },

      async prefillCampaignCode () {
        const campaignCode = this.$route.query?.campaignCode;

        if (campaignCode) {
          this.campaignCode = campaignCode;
          await this.$validator.validate('campaign-code');
        }
      },

      async onCaptchaError(errorCode) {
        this.isCaptchaLoading = false;

        if (ACCEPTABLE_HCAPTCHA_CLIENT_API_ERROR_CODES.includes(errorCode)) {
          await this.submit(null, true);
        }
      },

      async submit(hCaptchaToken, ignoreCaptcha) {
        if (this.showCaptcha && !hCaptchaToken && !ignoreCaptcha) {
          this.isCaptchaLoading = true;

          return this.$refs.theCaptcha.execute();
        }

        const application = {
          applicant: {
            firstName: this.applicant.firstName,
            surname: this.applicant.surname,
            email: this.applicant.email,
            idDocumentType: this.applicant.idDocumentType,
            maritalStatus: this.applicant.maritalStatus,
            personalIdentityCode: this.applicant.personalIdentityCode,
            ...this.phoneNumber,
          },
          loanPurpose: this.loanPurpose || this.loanPurposes[0],
          ...+this.$route.query?.renovationCost &&
            { renovationCost: +this.$route.query.renovationCost },
          marketingConsent: !!this.marketingConsent,
          ...(this.shouldCoBorrowerBeInvolved && ({ involveCoBorrower: true })),
          ...(
            (!this.isIdentifyOnline && this.campaignCode && typeof this.campaignCode === 'string')
              ? ({ campaignCode: this.campaignCode.toLowerCase() })
              : ({})
          ),
          ...(this.isPartnerLandingPageApplication && ({
            productName: APP_MAP_BY_PRODUCT_URL_NAME[this.$route.query.productName].productName,
            segmentName: this.$route.query.segmentName,
            partner: { regCode: this.$route.query.regCode },
          })
          ),
        };

        if (this.isIdentifyOnline) {
          await this.updateApplicationWithCalculatorData({ application });
          await this.resetIdentification();
          await this.getIdentificationOptions({ person: 'applicant' });
        } else if (this.definitionKey === tasks.SubmitPersonalDetails) {
          this.updateProcessWithCalculatorData({
            ...application,
            agreeWithConsent: true,
          });
        } else {
          this.createPublicApplication({
            application: {
              ...application,
              ...getOptionalProductValuesFromUrlByProductType({
                productType: this.productType,
                query: this.$route.query,
              }),
              source: {
                source: this.source,
                ...(this.isPartnerLandingPageApplication && { applicationOrigin: APPLICATION_ORIGIN.PARTNER_LANDING_PAGE }),
                ...(this.channel && { channel: this.channel }),
              },
              agreeWithConsent: true,
              ...(this.$route.query?.repaymentScheduleType &&
                { repaymentScheduleType: this.$route.query?.repaymentScheduleType }),
              ...(this.$route.query?.specialScheduleAmount &&
                { specialScheduleAmount: toNumber(this.$route.query?.specialScheduleAmount) }),
              ...(this.$route.query?.specialSchedulePeriod &&
                { specialSchedulePeriod: toNumber(this.$route.query?.specialSchedulePeriod) }),
            },
            hCaptchaToken,
          });
        }
      },
    },
  };
</script>
