<template>
  <bb-input
    id="bankAccountField"
    ref="input"
    v-model="iban"
    v-validate="'required|IBAN'"
    data-vv-validate-on="input|blur"
    name="bank-account-number"
    class="iban-input"
    :label="$t('application.applicant.bankAccountNumber.label')"
    :data-vv-as="$t('application.applicant.bankAccountNumber.label')"
    :formatter="formatter"
    :value="value"
    autocomplete="off"
    maxlength="256"
    @input="onInput"
  />
</template>

<script>
  import { BbInput } from '@bigbank/interface-components';

  const LT_IBAN_LENGTH = 20;

  export default {
    name: 'iban-input',

    components: { BbInput },

    inject: ['$validator'],

    props: { value: String },

    data() {
      return { iban: null };
    },

    mounted() {
      this.iban = this.value;
    },

    methods: {
      formatter (value) {
        if (typeof value !== 'string') {
          return value;
        }

        let formattedValue = value.replace(/ /g, '').toUpperCase();
        formattedValue = formattedValue.length > LT_IBAN_LENGTH
          ? formattedValue.slice(0, -1)
          : formattedValue;

        formattedValue = formattedValue.replace(/[^\dA-Z]/g, '');
        this.$refs.input.$refs.input.value = formattedValue; // TODO: Remove when WEB-2686 is released.

        return formattedValue;
      },
      onInput (e) {
        this.$emit('input', e);
      },
    },
  };
</script>
