<template>
  <bb-container class="f-align-center f-small">
    <bb-row>
      <bb-col>
        <p class="f-gotham-bold f-color-blue">
          {{ $t('components.mobileVerification.enterPin') }}
        </p>
      </bb-col>
    </bb-row>

    <failed-verification
      v-if="isError"
    />
    <template v-else>
      <bb-row class="m-t-20">
        <bb-col>
          <bb-icon
            :name="isSmartId ? 'client-smart-id' : 'client-mobile-id'"
            size="48"
            fill="navy-90"
          />
        </bb-col>
      </bb-row>
      <bb-row class="m-t-20">
        <bb-col>
          <h3
            v-if="challengeId"
            class="f-strong f-color-blue"
          >
            {{ challengeId }}
          </h3>
          <div
            v-else
            class="d-flex justify-content-center m-b-10"
          >
            <bb-spinner small />
          </div>
        </bb-col>
      </bb-row>

      <bb-row>
        <bb-col>
          <p class="f-color-gray-70">
            {{ isSmartId ? $t('components.mobileVerification.smartId') : $t('components.mobileVerification.mobileId') }}
          </p>
        </bb-col>
      </bb-row>
    </template>

    <bb-row class="m-t-20">
      <bb-col>
        <bb-button
          id="cancel-mobile-verification"
          name="cancel-mobile-verification"
          display="link"
          color="gray"
          @click="cancel"
        >
          {{ $t('components.mobileVerification.cancel') }}
        </bb-button>
      </bb-col>
    </bb-row>
  </bb-container>
</template>

<script>
  import { VERIFICATION_TYPES } from '@/constants/verification';
  import '@bigbank/interface-components/dist/svg/client/smart-id';
  import '@bigbank/interface-components/dist/svg/client/mobile-id';
  import { mapGetters } from 'vuex';
  import { BbButton,
           BbCol,
           BbContainer,
           BbIcon,
           BbRow, BbSpinner } from '@bigbank/interface-components';
  import FailedVerification from './FailedVerification';

  export default {
    name: 'mobile-verification',
    components: {
      BbButton,
      BbIcon,
      BbContainer,
      BbRow,
      BbCol,
      BbSpinner,
      FailedVerification,
    },
    data() {
      return { VERIFICATION_TYPES };
    },
    computed: {
      ...mapGetters('mobileVerification', [
        'challengeId',
        'isError',
        'isSmartId',
      ]),
    },
    methods: {
      cancel() {
        this.$emit('cancel');
      },
    },
  };
</script>
