var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('bb-input',{directives:[{name:"validate",rawName:"v-validate",value:({
      required: true,
      min_value: _vm.getMinGoodsPrice(_vm.realEstateDataForm.renovationCost),
      goodsPriceMaxValue: true
    }),expression:"{\n      required: true,\n      min_value: getMinGoodsPrice(realEstateDataForm.renovationCost),\n      goodsPriceMaxValue: true\n    }"}],attrs:{"name":"goods-price","type":"number","locale":_vm.$i18n.locale,"add-on":"€","currency":"","label":_vm.$t('views.submitRealEstateInfo.goodsPrice'),"data-vv-as":_vm.$t('views.submitRealEstateInfo.validation.goodsPricePlusRenovationCost'),"value":_vm.realEstateDataForm.goodsPrice,"data-vv-validate-on":"input|change"},on:{"input":function($event){return _vm.$emit('input', $event)},"change":function($event){_vm.realEstateDataForm.goodsPrice = Number($event); _vm.$validator.validate('self-financing-amount')}}}),(_vm.isProductHousingLoan)?_c('div',{staticClass:"m-t-30"},[_c('bb-checkbox',{attrs:{"id":"showRenovationCostCheck","name":"show-renovation-cost","background":"","label":_vm.$t('views.submitRealEstateInfo.showRenovationCost')},on:{"change":_vm.changedShowRenovationCost},model:{value:(_vm.realEstateDataForm.showRenovationCost),callback:function ($$v) {_vm.$set(_vm.realEstateDataForm, "showRenovationCost", $$v)},expression:"realEstateDataForm.showRenovationCost"}},[(_vm.realEstateDataForm.showRenovationCost)?_c('bb-input',{directives:[{name:"validate",rawName:"v-validate",value:({
          required: true,
          min_value: 0,
          max_value: _vm.goodsPriceMax
        }),expression:"{\n          required: true,\n          min_value: 0,\n          max_value: goodsPriceMax\n        }"}],attrs:{"slot":"conditional","type":"number","locale":_vm.$i18n.locale,"add-on":"€","currency":"","name":"renovation-cost","label":_vm.$t('views.submitRealEstateInfo.renovationCost'),"data-vv-as":_vm.$t('views.submitRealEstateInfo.renovationCost'),"value":_vm.realEstateDataForm.renovationCost,"data-vv-validate-on":"input|change"},on:{"input":function($event){_vm.realEstateDataForm.renovationCost = $event ? Number($event) : $event; _vm.$validator.validate('goods-price')},"change":function($event){_vm.realEstateDataForm.renovationCost = $event ? Number($event) : $event; _vm.$validator.validate('goods-price')}},slot:"conditional"}):_vm._e()],1)],1):_vm._e(),_c('bb-input',{directives:[{name:"validate",rawName:"v-validate",value:({
      required: true,
      min_value: _vm.getMinSelfFinancingAmountFromPriceByProduct(_vm.realEstateDataForm.goodsPrice, _vm.realEstateDataForm.renovationCost),
      max_value: _vm.getMaxSelfFinancingAmountFromPriceByProduct(_vm.realEstateDataForm.goodsPrice)
    }),expression:"{\n      required: true,\n      min_value: getMinSelfFinancingAmountFromPriceByProduct(realEstateDataForm.goodsPrice, realEstateDataForm.renovationCost),\n      max_value: getMaxSelfFinancingAmountFromPriceByProduct(realEstateDataForm.goodsPrice)\n    }"}],attrs:{"type":"number","locale":_vm.$i18n.locale,"add-on":"€","currency":"","name":"self-financing-amount","label":_vm.$t('views.submitRealEstateInfo.selfFinancingAmount'),"data-vv-as":_vm.$t('views.submitRealEstateInfo.selfFinancingAmount'),"value":_vm.realEstateDataForm.selfFinancingAmount,"data-vv-validate-on":"input|change"},on:{"input":function($event){_vm.realEstateDataForm.selfFinancingAmount = Number($event)},"change":function($event){_vm.realEstateDataForm.selfFinancingAmount = Number($event)}}}),_c('bb-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|commonSymbols|min:2|max:256'),expression:"'required|commonSymbols|min:2|max:256'"}],attrs:{"type":"text","name":"downpayment-origin-description","help-active":"","label":_vm.$t('views.submitRealEstateInfo.downpaymentOriginDescription'),"help-text":_vm.$t('views.submitRealEstateInfo.downpaymentOriginDescriptionHelpText'),"data-vv-as":_vm.$t('views.submitRealEstateInfo.downpaymentOriginDescription')},model:{value:(_vm.realEstateDataForm.downpaymentOriginDescription),callback:function ($$v) {_vm.$set(_vm.realEstateDataForm, "downpaymentOriginDescription", $$v)},expression:"realEstateDataForm.downpaymentOriginDescription"}}),_c('bb-input',{attrs:{"type":"number","locale":_vm.$i18n.locale,"add-on":"€","currency":"","disabled":"","name":"initial-loan-amount","label":_vm.$t('views.submitRealEstateInfo.initialLoanAmount'),"value":_vm.calculatedInitialLoanAmount}}),_c('bb-input',{directives:[{name:"validate",rawName:"v-validate",value:({
      required: true,
      numeric: true,
      min_value: _vm.productMinPeriod,
      max_value: _vm.productMaxPeriod
    }),expression:"{\n      required: true,\n      numeric: true,\n      min_value: productMinPeriod,\n      max_value: productMaxPeriod\n    }"}],attrs:{"type":"number","name":"initial-loan-period","label":_vm.$t('views.submitRealEstateInfo.initialLoanPeriod'),"add-on":_vm.$t('views.submitRealEstateInfo.initialLoanPeriodUnit'),"data-vv-as":_vm.$t('views.submitRealEstateInfo.initialLoanPeriod'),"value":_vm.realEstateDataForm.initialLoanPeriod},on:{"input":function($event){_vm.realEstateDataForm.initialLoanPeriod = Number($event)},"change":function($event){_vm.realEstateDataForm.initialLoanPeriod = Number($event)}}}),_c('bb-textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required|commonSymbols|min:2|max:1000'),expression:"'required|commonSymbols|min:2|max:1000'"}],attrs:{"name":"additional-info-text","label":_vm.$t('views.submitRealEstateInfo.additionalInfoText'),"data-vv-as":_vm.$t('views.submitRealEstateInfo.additionalInfoValidationField'),"rows":"5"},model:{value:(_vm.realEstateDataForm.additionalInfoText),callback:function ($$v) {_vm.$set(_vm.realEstateDataForm, "additionalInfoText", $$v)},expression:"realEstateDataForm.additionalInfoText"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }