import merge from 'lodash/merge';

export const headerFlavors = {
  NO_HEADER: 'noHeader',
  PROGRESS: 'progress',
  NO_PROGRESS_BAR: 'noProgressBar',
  PROGRESS_CALCULATOR: 'progressCalculator',
};

export const footerFlavors = {
  NO_FOOTER: 'noFooter',
  DEFAULT: 'default',
};

export const defaultUiState = {
  backgroundColor: 'white',
  headerFlavor: headerFlavors.NO_PROGRESS_BAR,
  footerFlavor: footerFlavors.DEFAULT,
  stepsFlavor: 'default',
  progress: {
    page: {
      current: 3,
      max: 3,
    },
    step: { current: 0 },
  },
};

const iFrameState = merge({},
  defaultUiState,
  {
    headerFlavor: headerFlavors.NO_HEADER,
    footerFlavor: footerFlavors.NO_FOOTER,
  },
);

const reviewOfferPageState = merge({},
  defaultUiState,
  { progress: { step: { current: 1 }}},
);

const errorPageState = {
  backgroundColor: 'gray-10',
  headerFlavor: headerFlavors.NO_PROGRESS_BAR,
  stepsFlavor: 'default',
  progress: {
    page: {
      current: 3,
      max: 3,
    },
    step: { current: 0 },
  },
};

const firstPageState = merge({},
  defaultUiState,
  {
    headerFlavor: headerFlavors.PROGRESS_CALCULATOR,
    progress: { page: { current: 1 }},
  },
);

const submitSpousePersonalDetailsState = merge({},
  defaultUiState,
  {
    headerFlavor: headerFlavors.PROGRESS,
    progress: {
      page: {
        current: 1,
        max: 3,
      },
    },
  },
);

export const uiStates = {
  createApplication: firstPageState,
  SubmitPersonalDetails: firstPageState,
  IdentifyOnline: firstPageState,
  SelectProduct: merge({},
    defaultUiState,
    {
      headerFlavor: headerFlavors.PROGRESS,
      progress: { page: { current: 1 }},
    },
  ),
  SubmitIncomesAndLiabilities: merge({},
    defaultUiState,
    {
      headerFlavor: headerFlavors.PROGRESS_CALCULATOR,
      progress: { page: { current: 2 }},
    },
  ),
  SpouseIncomesAndLiabilities: merge({},
    defaultUiState,
    {
      headerFlavor: headerFlavors.PROGRESS_CALCULATOR,
      progress: { page: { current: 3 }},
    },
  ),
  SubmitCoBorrowerIncomesAndLiabilities: merge({},
    defaultUiState,
    {
      headerFlavor: headerFlavors.PROGRESS_CALCULATOR,
      progress: { page: { current: 3 }},
    },
  ),
  SubmitBankDetails: merge({},
    defaultUiState,
    { progress: { step: { current: 2 }}},
  ),
  SubmitBankAccountNumber: merge({},
    defaultUiState,
    { progress: { step: { current: 2 }}},
  ),
  SubmitAddress: merge({},
    defaultUiState,
    { progress: { step: { current: 2 }}},
  ),
  SubmitBankDetailsAndAddress: merge({},
    defaultUiState,
    { progress: { step: { current: 2 }}},
  ),
  SubmitCoborrowerAddressAndFinancialData: merge({},
    defaultUiState,
    {
      headerFlavor: headerFlavors.PROGRESS,
      progress: { step: { current: 2 }},
    },
  ),

  ReviewApproved: reviewOfferPageState,
  ReviewApprovedUpsale: reviewOfferPageState,
  ReviewApprovedFinal: reviewOfferPageState,
  ReviewApprovedUpsaleFinal: reviewOfferPageState,
  ReadConditionalPositiveOffer: reviewOfferPageState,
  ReviewConditionalNegative: merge({},
    defaultUiState,
    { progress: { step: { current: 2 }}},
  ),
  ReviewRejectedBringCoBorrower: merge({},
    defaultUiState,
    { progress: { step: { current: 2 }}},
  ),

  UploadDocuments: merge({},
    defaultUiState,
    {
      progress: {
        step: { current: 2 },
        page: { current: 2 },
      },
    },
  ),

  UploadSpouseDocuments: merge({},
    defaultUiState,
    {
      progress: {
        step: { current: 2 },
        page: { current: 2 },
      },
    },
  ),

  UploadEmploymentDocuments: merge({},
    defaultUiState,
    {
      progress: {
        step: { current: 2 },
        page: { current: 2 },
      },
    },
  ),

  UploadRefinancingDocuments: merge({},
    defaultUiState,
    {
      progress: {
        step: { current: 2 },
        page: { current: 2 },
      },
    },
  ),

  UploadLeasingDocuments: merge({},
    defaultUiState,
    {
      progress: {
        step: { current: 2 },
        page: { current: 2 },
      },
    },
  ),

  UploadRealEstateDocuments: merge({},
    defaultUiState,
    {
      progress: {
        step: { current: 2 },
        page: { current: 2 },
      },
    },
  ),

  WhatsNextPageBankLink: defaultUiState,
  ReadNegativeDecision: defaultUiState,
  ShowContractSigningNegativePage: defaultUiState,
  ViewAndSignContractContentLoader: defaultUiState,
  ViewAndSignContract: merge({},
    defaultUiState,
    { progress: { step: { current: 3 }}},
  ),
  SubmitSpouseAddress: merge({},
    defaultUiState,
    {
      headerFlavor: headerFlavors.PROGRESS,
      progress: {
        page: {
          current: 2,
          max: 3,
        },
      },
    },
  ),
  spouseIdentification: submitSpousePersonalDetailsState,
  SubmitSpousePersonalData: submitSpousePersonalDetailsState,
  SubmitSpouseDetails: merge({},
    defaultUiState,
    { progress: { step: { current: 2 }}},
  ),
  SubmitSpouseFinanceData: merge({},
    defaultUiState,
    {
      headerFlavor: headerFlavors.PROGRESS,
      progress: {
        page: {
          current: 3,
          max: 3,
        },
      },
    },
  ),
  start: defaultUiState,
  completeSpouseBankLinkIdentification: defaultUiState,
  completeLoginServiceIdentification: defaultUiState,
  completeOnlineSigning: defaultUiState,
  completeRemoteIdentification: defaultUiState,
  DuplicateApplicationThankYouPage: defaultUiState,
  ReadUndecidedDecision: defaultUiState,
  UnexpectedDecisionOnContractGenerationThankYouPage: defaultUiState,
  SpouseIdentifiedAndDataSubmittedThankYouPage: defaultUiState,
  AdditionalDataSubmittedThankYouPage: defaultUiState,
  DocumentUploadSkippedThankYouPage: defaultUiState,
  DocumentsUploadedThankYouPage: defaultUiState,
  UploadedRefinancingDocumentsThankYouPage: defaultUiState,
  EmploymentDocumentsUploadedThankYouPage: defaultUiState,
  SpouseFinanceDataSubmittedThankYouPage: defaultUiState,
  SpouseDocumentsUploadedThankYouPage: defaultUiState,
  LeasingDocumentsUploadedThankYouPage: defaultUiState,
  CallCustomerThankYouPage: defaultUiState,
  WaitForHousingLoanDecisionThankYouPage: defaultUiState,
  WaitForHousingLoanDecisionThankYouPageConditionalPositive: defaultUiState,
  ChooseContinueOption: defaultUiState,
  ChooseContinueOptionAfterReData: defaultUiState,
  HousingLoanDocumentsUploadedThankYouPage: defaultUiState,
  SubmitRealEstateInfo: merge({},
    defaultUiState,
    {
      headerFlavor: headerFlavors.PROGRESS,
      progress: {
        page: {
          current: 2,
          max: 3,
        },
      },
    },
  ),
  ReadHousingLoanRejectedPage: defaultUiState,
  login: defaultUiState,
  maintenance: errorPageState,
  sessionExpired: errorPageState,
  error500: errorPageState,
  error404: errorPageState,
  error451: {
    ...errorPageState,
    backgroundColor: 'white',
  },
  noTask: errorPageState,
  unauthorized: errorPageState,
  autoplius: iFrameState,
  diginet: iFrameState,
  noProcess: errorPageState,
  ApplicationSubmittedThankYou: defaultUiState,
  ConsentSignedThankYouPage: defaultUiState,
  ProvideBasViaOpenBanking: defaultUiState,
  ProvideSpouseBasViaOpenBanking: defaultUiState,
};
