import actions from './actions';
import { RESET_STATE, SET_CHALLENGE_ID, SET_ERROR, SET_VERIFICATION_TYPE } from './types';
import { VERIFICATION_TYPES } from '@/constants/verification';

const state = {
  challengeId: '',
  error: null,
  verificationType: null,
};

const getters = {
  challengeId: state => state.challengeId,
  isSmartId: state => state.verificationType === VERIFICATION_TYPES.smart_id,
  isMobileId: state => state.verificationType === VERIFICATION_TYPES.mobile_id,
  isError: state => state.error !== null,
  error: state => state.error,
};

const mutations = {
  [SET_CHALLENGE_ID]: (state, challengeId) => {
    state.challengeId = challengeId;
  },
  [SET_VERIFICATION_TYPE]: (state, verificationType) => {
    state.verificationType = verificationType;
  },
  [SET_ERROR]: (state, error) => {
    state.error = error;
  },
  [RESET_STATE]: (state) => {
    state.challengeId = '';
    state.error = null;
    state.verificationType = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
