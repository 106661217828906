<template>
  <bb-modal
    id="error-modal"
    center-vertically
    :visible="visible"
    flavor="user"
    @close="$emit('close')"
  >
    <div class="align-center">
      <div class=" f-color-red f-max-pro-demi-bold h4">
        {{ $t('views.provideBasViaOpenBanking.errorModal.title') }}
      </div>
      <div class="m-b-25 color-gray-80 f-small f-strong">
        {{ $t('views.provideBasViaOpenBanking.errorModal.description') }}
      </div>
      <bb-button
        color="blue"
        @click="$emit('close')"
      >
        {{ $t('views.provideBasViaOpenBanking.errorModal.buttonText') }}
      </bb-button>
    </div>
  </bb-modal>
</template>

<script>
  import { BbButton, BbModal } from '@bigbank/interface-components';

  export default {
    name: 'error-modal',

    components: {
      BbModal,
      BbButton,
    },

    props: { visible: Boolean },
  };
</script>
