<template>
  <div class="signing-methods">
    <h2 class="h4 f-small color-blue align-center m-b-25">
      {{ $t('components.signingMethods.title') }}
    </h2>
    <div v-if="!hasAvailableMethods">
      <p class="header-text f-medium small align-center color-gray-80 m-b-10">
        {{ $t('components.signingMethods.noMethods') }}
      </p>
    </div>
    <div v-else>
      <p class="header-text f-medium small align-center color-gray-80">
        {{ $t('components.signingMethods.chooseSigningMethod') }}
      </p>

      <bb-row :class="{'justify-content-center': showSingleMethod}">
        <bb-col
          v-for="allowedMethod in allowedMethods"
          :key="allowedMethod.type"
          class="m-t-20"
          col="24"
        >
          <bb-button
            :id="allowedMethod.type"
            display="image"
            class="w-100 p-y-5"
            @click="onClickMethod(allowedMethod.type)"
          >
            <img
              :src="allowedMethod.img"
              :alt="allowedMethod.name"
              class="h-100"
            >
          </bb-button>
        </bb-col>
      </bb-row>

      <bb-row
        v-if="showRemoteIdentification"
        :class="{'justify-content-center': showSingleMethod}"
      >
        <bb-col class="col-24 m-t-20 m-b-10">
          <p class="header-text small f-medium align-center color-gray-80 m-b-20">
            {{ $t('components.signingMethods.identity.info') }}
          </p>
          <bb-button
            :id="VERIFICATION_TYPES.remote_identification"
            inverted
            color="gray"
            class="w-100 identify-now"
            @click="onClickMethod(VERIFICATION_TYPES.remote_identification)"
          >
            {{ $t('components.signingMethods.identity.identify') }}
          </bb-button>
        </bb-col>
        <bb-col
          v-for="disabledMethod in disabledMethods"
          :key="disabledMethod.type"
          class="m-t-20 disabled-method"
          sm="12"
        >
          <bb-button
            :id="disabledMethod.type"
            disabled
            display="image"
            class="w-100 p-y-5"
          >
            <img
              :src="disabledMethod.img"
              :alt="disabledMethod.name"
              class="h-100"
            >
          </bb-button>
        </bb-col>
      </bb-row>
    </div>

    <i18n
      path="components.signingMethods.callUs"
      tag="p"
      class="align-center f-smallest f-medium color-gray-50 m-t-30"
    >
      <bb-link
        class="f-nowrap"
        color="mint"
        :to="`tel:${ contact.phone }`"
      >
        <span>{{ contact.phone }}</span>
      </bb-link>
    </i18n>
  </div>
</template>

<script>
  import { BbButton, BbCol, BbLink, BbRow } from '@bigbank/interface-components';
  import { VERIFICATION_TYPES } from '@/constants/verification';
  import { contact } from '@/constants';
  import { mapGetters } from 'vuex';

  export default {
    name: 'signing-methods',

    components: {
      BbLink,
      BbRow,
      BbCol,
      BbButton,
    },

    data() {
      return {
        VERIFICATION_TYPES,
        contact,
      };
    },

    computed: {
      ...mapGetters('signing', [
        'signingMethods',
        'showRemoteIdentification',
      ]),

      allowedMethods() {
        return this.signingMethods
          .filter(method => method.disabled === false)
          .sort((a, b) => a.order < b.order ? -1 : 1);
      },
      disabledMethods() {
        return this.signingMethods
          .filter(method => method.disabled === true)
          .sort((a, b) => a.order < b.order ? -1 : 1);
      },
      hasAvailableMethods() {
        return !!this.signingMethods.length || this.showRemoteIdentification;
      },
      showSingleMethod() {
        return this.signingMethods && this.signingMethods.length === 1;
      },
    },

    methods: {
      onClickMethod(type) {
        this.$emit('click', type);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .disabled-method {
    opacity: 0.5;
    .btn--disabled {
      background-color: #fff;
    }
  }
</style>
