<template>
  <bb-offer-thank-you-page
    ref="offer"
    class="offer-negative-decision"
    :form-props="{ submitText }"
    @submit="navigateNow"
  >
    <template slot="header">
      <bb-hero
        class="w-100"
        bleed="all"
        :title="$t('views.offerNegativeDecision.title')"
      />
    </template>
    <template
      v-for="(explanation, explanationKey) in explanations"
    >
      <template
        v-if="showAsList(explanation)"
      >
        <p
          :key="explanationKey"
          class="smallest color-gray-90 m-t-25 m-b-15"
        >
          {{ $t('views.offerNegativeDecision.reasons') }}
        </p>
        <bb-list-item
          v-for="(reason, reasonKey) in explanation"
          :key="`${explanationKey}-${reasonKey}`"
          bullet
          condensed
          class="m-y-10"
          :title="$t(`${prefix}.${reason}`)"
          :title-uppercase="false"
        />
      </template>
      <p
        v-else
        :key="explanationKey"
        class="smallest color-gray-90"
      >
        {{ $t(`${prefix}.${explanation}`) }}
      </p>
    </template>
    <i18n
      v-if="showFAQ"
      tag="p"
      class="smallest color-gray-90 m-t-20"
      path="views.offerNegativeDecision.moreInfo"
    >
      <template #faqLink>
        <bb-link
          class="f-nowrap"
          color="blue"
          :to="NEGATIVE_DECISION_FAQ_URL"
        >
          <span>{{ faqLinkLabel }}</span>
        </bb-link>
      </template>
    </i18n>
  </bb-offer-thank-you-page>
</template>

<script>
  import { BbHero, BbLink, BbListItem, BbOfferThankYouPage } from '@bigbank/interface-components';
  import offerRejectedReasonsMixin from '../mixins/offerRejectedReasonsMixin';
  import sourceMixin from '../mixins/sourceMixin';
  import { mapGetters } from 'vuex';
  import { NEGATIVE_DECISION_FAQ_URL } from '../constants';
  import partnerApiMixin from '../mixins/partnerApiMixin';

  export default {
    name: 'offer-negative-decision',
    components: {
      BbHero,
      BbLink,
      BbListItem,
      BbOfferThankYouPage,
    },
    mixins: [
      sourceMixin,
      offerRejectedReasonsMixin,
      partnerApiMixin,
    ],
    data() {
      return {
        prefix: 'views.offerNegativeDecision',
        NEGATIVE_DECISION_FAQ_URL,
      };
    },
    computed: {
      ...mapGetters('processStep', ['offerRejectedReason']),
      faqLinkLabel() {
        return this.$t('views.offerNegativeDecision.faqLinkLabel');
      },
      submitText () {
        return this.shouldRedirectToPartnerApi
          ? this.partnerApiThankYouButtonText
          : this.sourceBackToButtonText;
      },
    },
    mounted () {
      this.navigateToPartnerApi();
    },
    methods: {
      showAsList(explanation) {
        return Array.isArray(explanation);
      },
      navigateNow () {
        return this.shouldRedirectToPartnerApi
          ? this.navigateToPartnerApi({ immediate: true })
          : this.navigateToSource();
      },
    },
  };
</script>

<style lang="scss">
  .offer-negative-decision {
    .bb-hero__title, .bb-form {
      max-width: 425px;
    }

    .bb-hero__title {
      line-height: 28px;
    }

    .bb-form {
      margin-left: auto;
      margin-right: auto;

      .bb-card {
        padding-top: 25px;
        padding-bottom: 50px;

        & > p {
          line-height: 16px;
        }
      }
    }

    @media (max-width: 375px) {
      .bb-hero__title {
        max-width: 326px;
        font-size: 18px;
        line-height: 22px;
      }

      .bb-form {
        max-width: 337px;
      }

      .bb-card {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
</style>
