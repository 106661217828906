<template>
  <div class="offer-options-loader m-y-60">
    <div class="d-flex align-items-center justify-content-center">
      <bb-spinner />
    </div>
    <p class="text-center f-small f-medium color-navy m-t-35">
      {{ $t('views.offer.recalculating') }}
    </p>
  </div>
</template>

<script>
  import { BbSpinner } from '@bigbank/interface-components';

  export default {
    name: 'offer-options-loader',

    components: { BbSpinner },
  };
</script>
