import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('processStep', ['isLoading']),

    formProps () {
      return {
        disabled: this.isFormDisabled,
        errorText: this.$t('form.errorText'),
        loading: this.isLoading,
        submitText: this.$t('form.continue'),
      };
    },

    isFormValid () {
      return !this.$validator.errors.any();
    },

    isFormDisabled() {
      return this.isLoading || !this.isFormValid;
    },
  },
};
