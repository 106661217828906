<template>
  <div class="text-center m-t-10">
    <bb-button
      id="marketingReadMore"
      class="smallest"
      color="blue"
      display="link"
      @click="open"
    >
      {{ $t('form.readMore') }}
    </bb-button>
    <bb-modal
      id="marketingConsentModal"
      class="text-left"
      flavor="user"
      :visible="showModal"
      @close="close"
    >
      <i18n
        path="application.marketingConsent.modal"
        tag="p"
      >
        <bb-link
          color="blue"
          :to="`mailto:${ contactEmail }`"
        >
          {{ contactEmail }}
        </bb-link>
      </i18n>
    </bb-modal>
  </div>
</template>

<script>
  import { contact } from '@/constants';
  import { BbLink } from '@bigbank/interface-components';
  import { modalMixin } from '@/mixins/modalMixin';
  import contactEmailMixin from '@/mixins/contactEmailMixin';

  export default {
    name: 'marketing-consent-modal',

    components: { BbLink },

    mixins: [
      modalMixin,
      contactEmailMixin,
    ],

    data() {
      return { contact };
    },
  };
</script>

