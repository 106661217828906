import actions from './actions';
import { signing } from '@bigbank/web-loco';
import { SET_LOADING,
  SET_SIGNING_LIST,
  SET_REMOTE_IDENTIFICATION_STATUS,
  SET_REMOTE_IDENTIFICATION_OPTION } from './types';
import { MOBILE_ID, SMART_ID, SUPPORTED_AUTH_AND_SIGNING_METHODS } from '@/constants/verification';

const state = {
  loading: false,
  remoteIdentification: {
    show: false,
    status: undefined,
  },
  mobileIdMethod: MOBILE_ID,
  smartIdMethod: SMART_ID,
  bankLinkMethods: SUPPORTED_AUTH_AND_SIGNING_METHODS,
};

const mutations = {
  [SET_LOADING] (state, loading) {
    state.loading = loading;
  },
  [SET_REMOTE_IDENTIFICATION_OPTION] (state, showRemoteIdentification) {
    state.remoteIdentification.show = showRemoteIdentification;
  },
  [SET_REMOTE_IDENTIFICATION_STATUS] (state, identificationStatus) {
    state.remoteIdentification.status = identificationStatus;
  },
  [SET_SIGNING_LIST] (state, signingOptions) {
    signingOptions.forEach(option => {
      if (option.type === SMART_ID.type) {
        state.smartIdMethod.disabled = option.disabled;
        state.smartIdMethod.url = option.url;
        state.smartIdMethod.signingDataSent = true;
      } else if (option.type === MOBILE_ID.type) {
        state.mobileIdMethod.disabled = option.disabled;
        state.mobileIdMethod.url = option.url;
      }
    });

    state.bankLinkMethods = signingOptions
      .filter(option => SUPPORTED_AUTH_AND_SIGNING_METHODS.find(method => method.type === option.type))
      .map(option => {
        return {
          ...SUPPORTED_AUTH_AND_SIGNING_METHODS.find(method => method.type === option.type),
          url: option.url,
          disabled: option.disabled,
          bic: option.bic,
        };
      });
  },
};

const getters = {
  isLoading: (state) => state.loading,
  showRemoteIdentification: (state) => state.remoteIdentification?.show,
  identificationStatus: (state) => state.remoteIdentification?.status,
  hasAvailableMethods: (state, getters) => !!getters.signingMethods.length,
  signingMethods: (state) => {
    const methods = [];
    if (!state.mobileIdMethod.disabled) {
      methods.push(state.mobileIdMethod);
    }
    if (state.smartIdMethod.signingDataSent) {
      methods.push(state.smartIdMethod);
    }
    state.bankLinkMethods
      .forEach(method => methods.push(method));

    return methods;
  },
};

export const createSigningModule = (client) => {
  const signingModule = signing.moduleBuilder(client);

  return {
    namespaced: true,
    state: {
      ...signingModule.state,
      ...state,
    },
    mutations: {
      ...signingModule.mutations,
      ...mutations,
    },
    actions: {
      ...signingModule.actions,
      ...actions,
    },
    getters: {
      ...signingModule.getters,
      ...getters,
    },
  };
};
