<template>
  <section class="bg-blue">
    <bb-steps-overview id="stepOverview">
      <bb-step-icon
        v-for="(step, index) in applicationSteps"
        :id="step.icon"
        :key="step.text"
        :text="step.text"
        :done="index < currentStep"
        :icon="step.icon"
        :inactive="index > currentStep"
      />
    </bb-steps-overview>
  </section>
</template>

<script>
  import { BbStepsOverview, BbStepIcon } from '@bigbank/interface-components';
  import { mapGetters } from 'vuex';
  import '@bigbank/interface-components/dist/svg/client/personal';
  import '@bigbank/interface-components/dist/svg/client/offer';
  import '@bigbank/interface-components/dist/svg/client/contact';
  import '@bigbank/interface-components/dist/svg/client/sign';

  export default {
    name: 'steps-overview',
    components: {
      BbStepsOverview,
      BbStepIcon,
    },
    computed: {
      ...mapGetters('ui', [
        'stepsFlavor',
        'currentStep',
      ]),
      applicationSteps() {
        const steps = {
          default: [
            {
              text: this.$t('steps.personal'),
              icon: 'client-personal',
            },
            {
              text: this.$t('steps.offer'),
              icon: 'client-offer',
            },
            {
              text: this.$t('steps.details'),
              icon: 'client-contact',
            },
            {
              text: this.$t('steps.sign'),
              icon: 'client-sign',
            },
          ],
        };

        return steps[this.stepsFlavor];
      },
    },
  };
</script>
