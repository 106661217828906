<template>
  <bb-application-page
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: submitDisabled,
      loading: isLoading,
    }"
    @submit="submit"
  >
    <template #header>
      <bb-public-header
        :title="$t('views.provideBasViaOpenBanking.title')"
        class="text-center"
      />
    </template>
    <error-modal
      :visible="showErrorModal"
      @close="showErrorModal = false"
    />
    <p class="align-center f-gotham-medium f-small">
      {{ $t('views.provideBasViaOpenBanking.description') }}
    </p>
    <bb-container class="border-gray-30 m-t-30">
      <bb-row
        v-if="institutionsListLoading"
        class="justify-content-center"
      >
        <bb-spinner />
      </bb-row>
      <template v-else>
        <p class="f-gotham-medium f-small m-t-15">
          {{ $t('views.provideBasViaOpenBanking.chooseBanksDisclaimer') }}
        </p>
        <template v-if="showLinkedAccountsBlock">
          <p class="f-color-gray-90 f-gotham-medium m-t-30">
            {{ $t('views.provideBasViaOpenBanking.linkedAccounts') }}
          </p>
          <bank-link-row
            v-for="(institution, idx) in enrolledInstitutions"
            :key="`${institution.id}_enrolled_${idx}`"
            class="m-x-20"
            :institution="institution"
            enrolled
          />
        </template>
        <p class="f-color-gray-90 f-gotham-medium m-t-30">
          {{ chooseBankTitle }}
        </p>
        <bank-link-row
          v-for="institution in availableInstitutions"
          :key="institution.id"
          :loading="isInstitutionLoading(institution.id)"
          :disabled="isInstitutionDisabled(institution.id)"
          :institution="institution"
          @select-institution="selectInstitution"
        />
      </template>
    </bb-container>
    <div
      v-if="showManualBasUploadBlock"
      class="container border-radius-md border-gray-30 m-t-30"
    >
      <p class="f-gotham-medium f-color-gray-90 ">
        {{ $t('views.provideBasViaOpenBanking.manualUploadTitle') }}
      </p>
      <p class="f-gotham-medium f-small m-t-15">
        {{ $t('views.provideBasViaOpenBanking.manualUploadDisclaimer') }}
      </p>
      <bb-button
        class="m-t-15"
        color="blue"
        display="link"
        :label="$t('views.provideBasViaOpenBanking.manualUploadLink')"
        @click="continueToManualUpload"
      />
    </div>
  </bb-application-page>
</template>

<script>
  import { BbApplicationPage,
           BbButton,
           BbContainer,
           BbPublicHeader,
           BbRow,
           BbSpinner } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';
  import BankLinkRow from '@/components/uploadBankStatement/BankLinkRow';
  import ErrorModal from '@/components/uploadBankStatement/ErrorModal';
  import LtLoanOriginationClient from '@/api/LtLoanOriginationClient';

  export default {
    name: 'provide-bas-via-open-banking',

    components: {
      ErrorModal,
      BankLinkRow,
      BbApplicationPage,
      BbContainer,
      BbPublicHeader,
      BbButton,
      BbSpinner,
      BbRow,
    },

    data () {
      return {
        inProgressEnrollmentInstitutionId: undefined,
        showErrorModal: false,
      };
    },

    computed: {
      ...mapGetters('processStep', ['isLoading']),
      ...mapGetters('psd2', [
        'institutionsListLoading',
        'availableInstitutions',
        'enrolledInstitutions',
      ]),
      ...mapGetters('ui', ['language']),

      showLinkedAccountsBlock () {
        return this.enrolledInstitutions?.length > 0;
      },

      submitDisabled () {
        return this.isLoading || this.enrolledInstitutions?.length < 1;
      },

      showManualBasUploadBlock() {
        return this.config?.ENABLE_MANUAL_BAS_UPLOAD;
      },

      chooseBankTitle () {
        return this.enrolledInstitutions?.length > 0 ? this.$t('views.provideBasViaOpenBanking.linkAnotherAccount') : this.$t('views.provideBasViaOpenBanking.chooseBankTitle');
      },
    },

    created() {
      this.getInstitutions();
    },

    methods: {
      ...mapActions('processStep', ['updateProcessStep']),
      ...mapActions('psd2', ['getInstitutions']),

      async selectInstitution (institutionId) {
        try {
          this.inProgressEnrollmentInstitutionId = institutionId;
          const response = await LtLoanOriginationClient.selectInstitution({
            institutionId,
            userLanguage: this.language,
          });

          const { redirectUrl } = response.data;
          window.location.href = redirectUrl;
        } catch (error) {
          this.showErrorModal = true;
        } finally {
          this.inProgressEnrollmentInstitutionId = undefined;
        }
      },

      isInstitutionLoading (institutionId) {
        return this.inProgressEnrollmentInstitutionId === institutionId;
      },

      isInstitutionDisabled (institutionId) {
        return this.inProgressEnrollmentInstitutionId !== undefined &&
          !this.isInstitutionLoading(institutionId);
      },

      continueToManualUpload() {
        return this.updateProcessStep({
          variables: {
            uploadBasManually: {
              type: 'Boolean',
              value: true,
            },
          },
        });
      },

      submit () {
        return this.updateProcessStep({
          variables: {
            uploadBasManually: {
              type: 'Boolean',
              value: false,
            },
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .container {
    border-width: 2px;
    border-radius: 10px;
    border-style: solid;
    padding: 30px 20px;
  }
</style>
