<template>
  <bb-row
    class="crest m-t-10"
    no-gutters
    justify="start"
    align="center"
  >
    <bb-col class="align-left">
      <bb-logo
        fluid
        class="crest__logo"
        alt="Bigbank.lt"
        :logo="logo"
      />
    </bb-col>
    <bb-col class="align-right">
      <div class="crest__contact-phone f-medium f-smallest lh-18 color-navy align-right">
        {{ contactPhone }}
      </div>
    </bb-col>
  </bb-row>
</template>

<script>
  import { BbRow, BbCol, BbLogo } from '@bigbank/interface-components';

  export default {
    name: 'crest',

    components: {
      BbCol,
      BbLogo,
      BbRow,
    },

    props: {
      logo: String,
      contactPhone: String,
    },
  };
</script>

<style lang="scss">
  .crest {
    .bb-col:first-of-type {
      height: 30px;

      @media(max-width: 375px) {
        height: 24px;
      }

      @media(max-width: 320px) {
        height: 20px;
      }
    }
  }
</style>
