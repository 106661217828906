import actions from './actions';
import { SET_LOADING, SET_INSTITUTIONS } from './types';
import { PSD2_ACCOUNT_DATA_STATUS_SSN_ERROR } from '@/constants/application';

const mapEnrolledInstitutionAccounts = enrolledInstitutions => {
  return enrolledInstitutions?.flatMap(institution => {
    return institution?.accounts?.map(account => ({
      id: institution.id,
      name: institution.name,
      logo: institution.logo,
      iban: account.iban,
      error: account.dataStatus === PSD2_ACCOUNT_DATA_STATUS_SSN_ERROR,
    }));
  });
};

const filterUniqueIbanAndBanks = ({ id, iban }, index, array) =>
  array.findIndex(inst => inst.iban === iban && inst.id === id) === index;

const state = {
  loading: false,
  institutions: {},
};

const mutations = {
  [SET_LOADING] (state, loading) {
    state.loading = loading;
  },
  [SET_INSTITUTIONS] (state, institutions) {
    state.institutions = institutions;
  },
};

const getters = {
  institutionsListLoading: (state) => state.loading,
  institutions: (state) => state.institutions,
  availableInstitutions: (state) => state.institutions.availableInstitutions,
  enrolledInstitutions: (state) => mapEnrolledInstitutionAccounts(state.institutions.enrolledInstitutions)
    ?.filter(filterUniqueIbanAndBanks),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
