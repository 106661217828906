<template>
  <the-frame :config="config" />
</template>

<script>
  import TheFrame from '@/components/partner/TheFrame';
  import logoBigBankDark from '@/assets/img/bigbank-dark@2x.png';
  import { CONTACT_PHONE_DIGINET } from '@/constants';

  export default {
    name: 'diginet',

    components: { TheFrame },

    data() {
      return {
        config: {
          logo: logoBigBankDark,
          contactPhone: CONTACT_PHONE_DIGINET,
          perks: [
            this.$t('views.partner.common.perks.quickLoanSolution'),
            this.$t('views.partner.common.perks.withoutInitialContribution'),
            this.$t('views.partner.common.perks.withoutCollateral'),
          ],
          showCaptcha: !!window.config?.ENABLE_HCAPTCHA,
        },
      };
    },
  };
</script>
