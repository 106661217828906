import { LT_COUNTRY_CALLING_CODE,
  SUPPORTED_COUNTRIES_CALLING_CODES } from './validators/PhoneNumberValidator';

export const splitPhoneNumber = (mainMobileNo) => {
  mainMobileNo = mainMobileNo.replace(' ', '');
  if (!mainMobileNo) {
    return {
      prefix:  LT_COUNTRY_CALLING_CODE,
      suffix: '',
    };
  }

  const prefix = Object.values(SUPPORTED_COUNTRIES_CALLING_CODES).find(code => {
    const code2 = `\\${code}`;
    const regex = new RegExp(String.raw`${code2}`);

    return mainMobileNo.match(regex);
  });

  return {
    prefix,
    suffix: mainMobileNo.replace(prefix, ''),
  };
};
