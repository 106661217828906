import LtLoanOriginationClient from '@/api/LtLoanOriginationClient';
import { SET_LOADING,
  SET_REMOTE_IDENTIFICATION_OPTION,
  SET_SIGNING_LIST,
  SET_REMOTE_IDENTIFICATION_STATUS } from './types';
import router from '@/router';
import { IDENTIFICATION_STATUS, pages } from '@/constants';
import { VERIFICATION_TYPES } from '@/constants/verification';
import omit from 'lodash/omit';
import { DSS_ERROR } from '@/constants/Errors';

const getCompleteRemoteIdentificationUrl = () => {
  const href = router.resolve({
    name: pages.completeRemoteIdentification,
    query: router.currentRoute.query,
  }).href;

  return `${window.location.origin}${href}`;
};

const getCompleteOnlineSigningUrl = () => {
  const href = router.resolve({
    name: pages.completeOnlineSigning,
    query: omit(router.currentRoute.query, ['errorCode']),
  }).href;

  return `${window.location.origin}${href}`;
};

/**
 * @private
 * */
const _getSigningList = async (store) => {
  const externalId = store.rootGetters['processStep/externalId'];
  const redirectUrl = store.getters.showRemoteIdentification ? getCompleteRemoteIdentificationUrl() : getCompleteOnlineSigningUrl();
  const body = {
    reference: externalId,
    redirectSuccessUrl: redirectUrl,
    redirectFailUrl: redirectUrl,
  };

  const { data } = await LtLoanOriginationClient.getAutomatedSigningList(externalId, body);
  store.commit(SET_SIGNING_LIST, data);
};

/**
 * @private
 * */
const _getRemoteIdentification = async store => {
  const externalId = store.rootGetters['processStep/externalId'];
  const { data: { showRemoteIdentification }} = await LtLoanOriginationClient.checkRemoteIdentification(externalId);
  store.commit(SET_REMOTE_IDENTIFICATION_OPTION, showRemoteIdentification);
};

/**
 * @private
 * */
const _initiateBankLinkSigning = async (store, type) => {
  const { bic, url } = store.state.bankLinkMethods.filter(bank => bank.type === type)[0];
  const redirectUrl = store.rootGetters['environment/isPaymentAuthenticationAndSigningEnabled']
    ? (await LtLoanOriginationClient.initiateSigning({ bic })).data.redirectUrl
    : url;

  window.location.replace(redirectUrl);
};

/**
 * @private
 * */
const _processSigningStatus = async (store) => {
  if (!store.rootGetters['environment/isPaymentAuthenticationAndSigningEnabled']) {
    return;
  }

  const { status } = await LtLoanOriginationClient.processSigningStatus();
  if (status === 200) {
    await store.dispatch('processStep/continueProcess', store.rootGetters['processStep/externalId'], { root: true });
  }

  if (status === 205) {
    await _reloadPageWithError(DSS_ERROR.BANKLINK_PAYMENT_FAILED);
  }
};

/**
 * @private
 * */
const _reloadPageWithError = async errorCode => {
  await router.replace({
    path: router.currentRoute.path,
    query: {
      ...router.currentRoute.query,
      errorCode,
    },
  });
  window.location.reload();
};

/**
 * @todo Independent of signing - should be moved to a separate module.
 * */
const initiateRemoteIdentification = async (store) => {
  try {
    store.commit(SET_LOADING, true);
    const externalId = store.rootGetters['processStep/externalId'];
    store.commit(SET_REMOTE_IDENTIFICATION_STATUS, undefined);
    const { data: { redirectUrl }} = await LtLoanOriginationClient.initiateRemoteIdentification(externalId);
    window.location.replace(redirectUrl);
  } catch (error) {
    store.commit(SET_REMOTE_IDENTIFICATION_STATUS, IDENTIFICATION_STATUS.TECHNICAL_ERROR);
    store.commit(SET_LOADING, false);
  }
};

/**
 * @todo Independent of signing - should be moved to a separate module.
 * */
const completeRemoteIdentification = async (store, { externalId, handleResponse }) => {
  try {
    const { data: { status }} = await LtLoanOriginationClient.completeRemoteIdentification(externalId);
    if (handleResponse) {
      store.commit(SET_REMOTE_IDENTIFICATION_STATUS, status || IDENTIFICATION_STATUS.NO_STATUS);
      await store.dispatch('processStep/continueProcess', externalId, { root: true });
    }
  } catch (error) {
    await store.dispatch('processStep/handleError', error, { root: true });
  }
};

const initializeSigning = async (store) => {
  store.commit(SET_LOADING, true);

  try {
    await store.dispatch('environment/fetchAllFeatures', undefined, { root: true });
    await _getRemoteIdentification(store);
    if (store.getters.showRemoteIdentification && !store.rootGetters['processStep/isApplicantIdentified'] && !store.getters.identificationStatus) {
      await completeRemoteIdentification(store, { externalId: store.rootGetters['processStep/externalId'] });
    }
    await _processSigningStatus(store);
    await _getSigningList(store);
  } catch (error) {
    await store.dispatch('processStep/handleError', error, { root: true });
  } finally {
    store.commit(SET_LOADING, false);
  }
};

const startSigning = async (store, chosenType) => {
  store.commit(SET_LOADING, true);

  try {
    switch (chosenType) {
      case VERIFICATION_TYPES.mobile_id:
      case VERIFICATION_TYPES.smart_id:
        await store.dispatch('mobileVerification/initiateSigning', chosenType, { root: true });
        break;
      case VERIFICATION_TYPES.banklink_swedbank:
      case VERIFICATION_TYPES.banklink_seb:
      case VERIFICATION_TYPES.banklink_luminor:
      case VERIFICATION_TYPES.banklink_dnb:
      case VERIFICATION_TYPES.banklink_urbo:
      case VERIFICATION_TYPES.banklink_sb:
      case VERIFICATION_TYPES.banklink_citadele:
        await _initiateBankLinkSigning(store, chosenType);
        break;
      default:
        console.error(`Unexpected signing type: ${chosenType}`); // Goes to Kibana APM!
    }
  } catch (error) {
    await store.dispatch('processStep/handleError', error, { root: true });
  }
};

const completeOnlineSigning = async (store) => {
  try {
    const { externalId } = router.currentRoute.query;
    await LtLoanOriginationClient.completeOnlineSigning(externalId);
    await store.dispatch('processStep/continueProcess', externalId, { root: true });
  } catch (error) {
    await store.dispatch('processStep/handleError', error, { root: true });
  }
};

export default {
  // Used on /Start page and are not bound to any loader/spinner:
  completeOnlineSigning,
  completeRemoteIdentification,
  // UI is bound to a loader/spinner:
  initializeSigning,
  initiateRemoteIdentification,
  startSigning,
};
