<template>
  <bb-application-page
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="submit"
  >
    <bb-public-header
      slot="header"
      class="text-center"
      icon="client-details"
      :title="$t('views.submitRealEstateInfo.title')"
    />
    <real-estate-data-form
      @realEstateData="applicationRealEstateData = $event"
    />
  </bb-application-page>
</template>

<script>
  import { BbApplicationPage, BbPublicHeader } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';
  import RealEstateDataForm from '@/components/RealEstateDataForm';

  export default {
    name: 'submit-real-estate-info',

    components: {
      BbApplicationPage,
      BbPublicHeader,
      RealEstateDataForm,
    },

    data() { return { applicationRealEstateData: null }; },

    computed: {
      ...mapGetters('processStep', [
        'isLoading',
        'isProductHousingLoan',
        'isLoanPurposeCombiningLoans',
      ]),

      areCustomerDownPaymentFieldsVisible () {
        return !(this.isProductHousingLoan && this.isLoanPurposeCombiningLoans);
      },
    },

    methods: {
      ...mapActions('processStep', ['updateProcessStep']),

      getRealEstateApplicationData () {
        const applicationUpdate = {
          goodsPrice: this.applicationRealEstateData?.goodsPrice,
          selfFinancingAmount: 0,
          initialLoanAmount: this.applicationRealEstateData?.initialLoanAmount,
          initialLoanPeriod: this.applicationRealEstateData?.initialLoanPeriod,
          collaterals: [
            ...(this.applicationRealEstateData?.collaterals ? this.applicationRealEstateData.collaterals.filter(collateral => !collateral.main) : []),
            {
              main: true,
              additionalInfoText: this.applicationRealEstateData?.additionalInfoText,
            },
          ],
        };

        if (this.areCustomerDownPaymentFieldsVisible) {
          applicationUpdate.downpaymentOriginDescription = this.applicationRealEstateData?.downpaymentOriginDescription;
          applicationUpdate.selfFinancingAmount = this.applicationRealEstateData?.selfFinancingAmount;
        }

        if (this.applicationRealEstateData?.showRenovationCost) {
          applicationUpdate.renovationCost = this.applicationRealEstateData?.renovationCost;
          applicationUpdate.goodsPrice = this.applicationRealEstateData?.goodsPrice;
        } else if (this.initialRenovationCost) {
          applicationUpdate.renovationCost = 0;
        }

        return applicationUpdate;
      },

      async submit() {
        this.updateProcessStep({ application: this.getRealEstateApplicationData() });
      },
    },
  };
</script>
