<template>
  <bb-modal
    id="login-modal"
    center-vertically
    flavor="user"
    visible
    hide-close
    public-size="m"
    class="login"
  >
    <p class="header-text align-center color-blue m-b-10">
      {{ $t('components.login.title') }}
    </p>

    <p class="header-text color-gray-70 align-center color-blue m-b-10 m-t-25 m-x-10">
      {{ $t('components.login.description') }}
    </p>

    <bb-row>
      <bb-col>
        <bb-form
          id="submit"
          static-submit
          :submit-text="$t('components.login.submit')"
          :error-text="$t('form.errorText')"
          :loading="isLoading"
          :disabled="isLoading"
          @submit="login({personalCodeLastDigits, role})"
        >
          <bb-banner
            :visible="hasErrors"
            type="error"
            class="m-b-25 m-t-20"
            permanent
          >
            {{ errorText }}
          </bb-banner>
          <bb-input
            id="personal-code-field"
            v-model="personalCodeLastDigits"
            v-validate="'required|digits:4'"
            data-vv-validate-on="input|blur|change"
            name="personal-code"
            :label="$t('components.login.label')"
            :data-vv-as="$t('components.login.label')"
            autocomplete="off"
          />
          <p class="header-text color-gray-70 align-center color-blue m-b-100 m-t-40 m-x-10">
            {{ $t('components.login.explanation') }}
          </p>
        </bb-form>
      </bb-col>
    </bb-row>
  </bb-modal>
</template>

<script>
  import { BbModal, BbRow, BbCol, BbInput, BbForm, BbBanner } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'login',

    components: {
      BbModal,
      BbRow,
      BbCol,
      BbInput,
      BbForm,
      BbBanner,
    },

    props: {
      role: {
        type: String,
        required: true,
      },
    },

    data() {
      return { personalCodeLastDigits: null };
    },

    computed: {
      ...mapGetters('login', [
        'isLoading',
        'hasError',
        'hasLoginError',
      ]),
      ...mapGetters('processStep', ['webShopRedirectUri']),
      hasErrors () {
        return this.hasError || this.hasLoginError;
      },
      errorText () {
        if (this.hasError) {
          return this.$t('components.login.error');
        } else if (this.hasLoginError) {
          return this.$t('components.login.errorLogin');
        }

        return '';
      },
    },

    async mounted () {
      if (this.$route.query?.redirect_uri && this.$route.query.redirect_uri !== this.webShopRedirectUri) {
        await this.updateApplicationWithPartnerApiRedirectUrl({ webShopRedirectUri: this.$route.query.redirect_uri });
      }
    },

    methods: {
      ...mapActions('login', ['login']),
      ...mapActions('processStep', ['updateApplicationWithPartnerApiRedirectUrl']),
    },

  };
</script>
