<template>
  <bb-offer-thank-you-page
    ref="offer"
    class="offer"
    :form-props="{
      errorText: $t('form.errorText'),
      submitText: $t('form.continue'),
      disabled: isSubmitDisabled,
      loading: isLoading,
      restrictEnterSubmitting: isCardFlipped(),
    }"
    :edit-button-label="$t('views.offer.tryCustomPlan')"
    @cardFlip="onCalculatorOpen"
    @submit="submit"
  >
    <template slot="header">
      <bb-hero
        class="w-100"
        bleed="all"
        :title="$t('views.offer.title')"
        :title-line-two="$t('views.offer.titleLineTwo')"
      >
        <steps-overview />
      </bb-hero>
    </template>

    <offer-options
      v-if="!loading"
      :class="{ 'm-b-30': showEditButton }"
      :options="options"
      :selected-option="selectedIndex"
      @change="setActiveOption"
    />

    <offer-options-loader
      v-else
    />

    <template slot="form-extras">
      <offer-warning
        v-if="showSelfFinancingAmountWarning"
      />
      <offer-details
        v-if="!loading"
        :option="getSelectedOption"
        :goods-price="goodsPrice"
        :self-financing-amount="selfFinancingAmount"
        :hide-self-financing-amount="showSelfFinancingAmountWarning"
      />
    </template>

    <template
      v-if="showEditButton"
      slot="card-back-side"
    >
      <offer-calculator
        ref="offerCalculator"
        :price="goodsPrice"
        :self-financing-amount="selfFinancingAmount"
        @submit="onCalculatorSubmit"
      />
    </template>
  </bb-offer-thank-you-page>
</template>

<script>
  import { BbHero, BbOfferThankYouPage } from '@bigbank/interface-components';
  import { mapState, mapGetters, mapActions } from 'vuex';
  import OfferOptions from '../components/offer/OfferOptions';
  import OfferDetails from '../components/offer/OfferDetails';
  import OfferCalculator from '../components/offer/OfferCalculator';
  import OfferOptionsLoader from '../components/offer/OfferOptionsLoader';
  import OfferWarning from '../components/offer/OfferWarning';
  import StepsOverview from '../components/StepsOverview';
  import { taskDefinitionKeys, OFFER_OPTION_CUSTOM } from '@/constants';
  import partnerApiMixin from '../mixins/partnerApiMixin';
  import isNumber from 'lodash/isNumber';

  export default {
    name: 'offer',

    components: {
      BbHero,
      BbOfferThankYouPage,
      OfferCalculator,
      OfferDetails,
      OfferOptions,
      StepsOverview,
      OfferOptionsLoader,
      OfferWarning,
    },

    mixins: [partnerApiMixin],

    data() {
      return {
        goodsPrice: null,
        selfFinancingAmount: null,
      };
    },

    computed: {
      ...mapGetters('processStep', [
        'isLoading',
        'offer',
        'definitionKey',
        'leasingProductDetails',
        'isProductCarFinanceLease',
      ]),
      ...mapGetters('offer', ['getSelectedOption']),
      ...mapState('offer', [
        'loading',
        'selectedIndex',
        'options',
      ]),
      isSubmitDisabled() {
        return this.isLoading || this.loading;
      },
      showEditButton() {
        return !this.loading && this.getSelectedOption?.type !== OFFER_OPTION_CUSTOM && !this.isPartnerApiHpFlow;
      },
      isReadConditionalPositiveOffer() {
        return this.definitionKey === taskDefinitionKeys.ReadConditionalPositiveOffer;
      },
      showSelfFinancingAmountWarning() {
        return !this.loading &&
          this.isProductCarFinanceLease &&
          isNumber(this.offer?.minSelfFinancingAmount) &&
          this.offer.minSelfFinancingAmount > this.selfFinancingAmount;
      },
    },

    mounted() {
      this.init();
    },

    methods: {
      ...mapActions('offer', [
        'initializeOptions',
        'setActiveOption',
      ]),
      ...mapActions('processStep', ['updateProcessStep']),

      isCardFlipped() {
        return !!this.$refs.offer?.$refs?.card?.flipped;
      },

      onCalculatorOpen() {
        this.$refs.offerCalculator.setInitialValues();
      },

      onCalculatorSubmit({ amount, period, price, selfFinancingAmount }) {
        this.$refs.offer.$refs.card.flipped = false;
        this.init({
          amount,
          period,
          price,
          selfFinancingAmount,
        });
      },

      init({ amount, period, price, selfFinancingAmount } = {}) {
        if (this.isProductCarFinanceLease) {
          this.goodsPrice = price ? price : this.leasingProductDetails?.goodsPrice;
          this.selfFinancingAmount = isNumber(selfFinancingAmount) ? selfFinancingAmount : this.leasingProductDetails?.selfFinancingAmount;

          return this.initializeOptions({
            amount,
            period,
          });
        }

        const customOptions = [];

        if (this.isReadConditionalPositiveOffer) {
          customOptions.push({
            type: OFFER_OPTION_CUSTOM,
            key: 'addSpouse',
          });
        }

        this.initializeOptions({
          amount,
          period,
          customOptions,
        });
      },

      submit() {
        const { offeredAmount, offeredPeriod } = this.offer;
        const { amount: chosenAmount, period: chosenPeriod, type } = this.getSelectedOption;

        if (type === OFFER_OPTION_CUSTOM) {
          return this.updateProcessStep({ application: { involveSpouse: true }});
        }

        if (chosenAmount === offeredAmount && chosenPeriod === offeredPeriod) {
          return this.updateProcessStep({
            application: {
              ...this.isReadConditionalPositiveOffer && { involveSpouse: false },
              offer: { offerAccepted: true },
            },
          });
        }

        return this.updateProcessStep({
          application: {
            ...this.isReadConditionalPositiveOffer && { involveSpouse: false },
            offerChange: {
              loanAmount: chosenAmount,
              loanPeriod: chosenPeriod,
            },
            ...this.goodsPrice && { goodsPrice: this.goodsPrice },
            ...isNumber(this.selfFinancingAmount) && { selfFinancingAmount: this.selfFinancingAmount },
          },
        });
      },
    },
  };
</script>

<style lang="scss">
  @media (max-height: 760px) {
    .offer {
      .bb-container {
        .bb-card__flip-stage {
          top: 5vh !important;
        }

        .bb-card__back-side {
          max-height: 95vh;
          overflow-y: scroll;
        }
      }
    }
  }

  @media (max-height: 667px) {
    .offer {
      .bb-container {
        .bb-card__flip-stage {
          top: 20px !important;
        }

        .bb-card__back-side {
          max-height: 93vh;
          overflow-y: scroll;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .offer {
      .bb-container {
        .bb-card__flip-stage {
          left: calc((100% - 425px) / 2) !important;
        }
      }
    }
  }

  @media (max-width: 475px) {
    .offer {
      .bb-container {
        .bb-card__flip-stage {
          left: 5% !important;
        }
      }
    }
  }
</style>
