<template>
  <loading />
</template>

<script>
  import Loading from '../components/Loading';
  import { mapActions } from 'vuex';

  export default {
    name: 'old-continue',
    components: { Loading },
    created() {
      this.continueProcess(this.$route.params.externalId);
    },
    methods: { ...mapActions('processStep', ['continueProcess']) },
  };
</script>
