import { render, staticRenderFns } from "./SubmitCoborrowerAddressAndFinancialData.vue?vue&type=template&id=772c9718&"
import script from "./SubmitCoborrowerAddressAndFinancialData.vue?vue&type=script&lang=js&"
export * from "./SubmitCoborrowerAddressAndFinancialData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports