import { getFallbackWebLoanApplicationUrl, getMigratedSteps, isFeatureRedirectionToFallbackUrlEnabled } from '@/config';

export const isRedirectionRequired = ({ routeName, skipCheckingRouteName } = {}) => {
  const fallbackWebLoanApplicationUrl = getFallbackWebLoanApplicationUrl();
  const isStepAlreadyMigrated = getMigratedSteps().includes(routeName);

  return !!(
    isFeatureRedirectionToFallbackUrlEnabled() &&
    fallbackWebLoanApplicationUrl &&
    (skipCheckingRouteName || isStepAlreadyMigrated)
  );
};

export const redirectToFallbackWebLoanApplicationUrl = (fullPath) => {
  const fallbackWebLoanApplicationUrl = getFallbackWebLoanApplicationUrl();
  const params = fullPath.substring(fullPath.indexOf('?'));

  window.location.href = `${fallbackWebLoanApplicationUrl}${params}`;
};
