<template>
  <header class="the-header">
    <bb-navbar
      sticky
      flavor="public"
      :progress-bar="progressBar"
      :edit-amount="editAmount"
      :close-button="isAuthenticatedApplicant && !isPartnerApiFlow"
      @editAmountClick="showCalculator = true"
      @closeClick="logout"
    >
      <bb-logo
        :logo="headerLogo"
        to="https://www.bigbank.lt/"
      />
    </bb-navbar>
    <bb-overlay
      v-if="isCalculatorFlavor"
      :visible="showCalculator"
      fluid
      @close="showCalculator = false"
    >
      <calculator
        v-if="showCalculator"
        @close="showCalculator = false"
      />
    </bb-overlay>
  </header>
</template>

<script>
  import { BbNavbar, BbLogo, BbOverlay } from '@bigbank/interface-components';
  import defaultLogo from '@bigbank/interface-components/dist/img/bigbank-dark@2x.png';
  import autopliusLogo from '../assets/img/logo_Autoplius.png';
  import { mapActions, mapGetters } from 'vuex';
  import Calculator from './Calculator';
  import { THEME } from '@/constants';
  import partnerApiMixin from '../mixins/partnerApiMixin';

  export default {
    name: 'the-header',

    components: {
      Calculator,
      BbNavbar,
      BbLogo,
      BbOverlay,
    },

    mixins: [partnerApiMixin],

    data() {
      return {
        showCalculator: false,
        themeLogos: { [THEME.AUTOPLIUS]: autopliusLogo },
      };
    },

    computed: {
      ...mapGetters('ui', [
        'isProgressFlavor',
        'isCalculatorFlavor',
        'maxPage',
        'currentPage',
        'theme',
      ]),
      ...mapGetters('auth', ['isAuthenticatedApplicant']),
      ...mapGetters('products', [
        'getProductData',
        'isCarFinanceLeaseProduct',
        'isHousingLoanProduct',
      ]),
      progressBar() {
        return this.isProgressFlavor
          ? {
            max: this.maxPage,
            value: this.currentPage,
            color: 'blue',
          }
          : null;
      },
      isCalculatorVisible() {
        return this.isCalculatorFlavor &&
          !this.isPartnerApiHpFlow &&
          !this.isCarFinanceLeaseProduct &&
          !this.isHousingLoanProduct;
      },
      editAmount() {
        if (this.isCalculatorVisible) {
          return {
            amount: `${this.getProductData.amount} €`,
            title: this.$t('header.loanAmount'),
          };
        }

        return undefined;
      },
      headerLogo() {
        return this.themeLogos[this.theme] || defaultLogo;
      },
    },

    methods: { ...mapActions('auth', ['logout']) },
  };
</script>

