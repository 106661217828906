<template>
  <bb-modal
    :visible="show"
    flavor="admin"
    hide-close
    size="x2"
    static-overlay
  >
    <template #heading>
      {{ $t('views.signContract.warning.title') }}
    </template>
    <p>{{ $t('views.signContract.warning.description') }}</p>
    <template #footer>
      <bb-button
        :label="$t('views.signContract.warning.submit')"
        display="block"
        corner="sharpMobileOnly"
        class="m-s-a"
        :disabled="isLoading"
        :loading="isLoading"
        @click="onConfirm"
      />
    </template>
  </bb-modal>
</template>

<script>
  import { BbModal, BbButton } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'fraud-warning-modal',

    components: {
      BbModal,
      BbButton,
    },

    computed: {
      ...mapGetters('processStep', [
        'isLoading',
        'variables',
      ]),

      show() {
        return !this.variables?.fraudWarningModalSeen;
      },
    },

    methods: {
      ...mapActions('processStep', ['hideFraudWarning']),

      async onConfirm() {
        await this.hideFraudWarning();
      },
    },
  };
</script>
