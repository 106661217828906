import { LoanOriginationClient } from '@bigbank/web-loco';
import router from '@/router';
import { pages, taskDefinitionKeys } from '@/constants';
import LtLoanOriginationClient from '@/api/LtLoanOriginationClient';
import { SET_LOADING } from '../signing/types';
import { SET_AUTHENTICATION_METHODS } from './index';
import AuthenticationRequest from '@/dtos/AuthenticationRequest';
import consentTextBuilder from '@/util/consentTextBuilder';
import omit from 'lodash/omit';
import { DSS_ERROR } from '@/constants/Errors';

const createUrl = name => {
  const href = router.resolve({
    name,
    query: omit(router.currentRoute.query, ['errorCode']),
  }).href;

  return `${window.location.origin}${href}`;
};

const LOGIN_SERVICE_METHOD = {
  smart_id: 'smartid',
  mobile_id: 'mobileid',
  bigbank_id: 'customer-id',
};

const login = ({ dispatch }, type) => {
  dispatch('auth/login', {
    method: LOGIN_SERVICE_METHOD[type],
    originalUrl: createUrl(pages.completeLoginServiceIdentification),
  }, { root: true });
};

const authenticate = async ({ commit, dispatch, getters, rootGetters }, type) => {
  const bankLink = getters.bankLinkMethods.find(method => method.type === type);

  try {
    commit(SET_LOADING, true);

    if (!rootGetters['environment/isPaymentAuthenticationAndSigningEnabled']) {
      window.location.href = bankLink.url;

      return;
    }

    const { data: { redirectUrl }} = await LtLoanOriginationClient.initiateAuthentication({
      bic: bankLink.bic,
      consent: consentTextBuilder(rootGetters[`processStep/${rootGetters[`processStep/activePersonRole`]}`]),
    });

    window.location.href = redirectUrl;
  } catch (error) {
    // TODO Error handling coming with https://backlog.big.local/browse/LOVE-1857.
    console.error(error);

    await dispatch('processStep/handleError', error, { root: true });
  }
};

const completeLoginServiceIdentification = (store, externalId) => {
  return LoanOriginationClient
    .updateProcessStep(externalId, {
      application: {},
      task: { definitionKey: 'IdentifyOnline' },
    }).then(({ data }) => store.dispatch('processStep/updateProcess', data, { root: true }))
    .catch(error => store.dispatch('processStep/handleError', error, { root: true }));
};

const _getAuthenticationMethodsAndStatus = async (store, person) => {
  const identification = new AuthenticationRequest(
    store.rootGetters['processStep/externalId'],
    store.rootGetters[`processStep/${person}`],
    createUrl(pages.start),
    createUrl(taskDefinitionKeys.IdentifyOnline),
  );

  const endpoint = store.rootGetters['environment/isPaymentAuthenticationAndSigningEnabled']
    ? 'getAuthenticationMethods'
    : 'getIdentificationMethods';

  const { status, data } = await LtLoanOriginationClient[endpoint](identification);

  // Note: Authentication already completed, continue with a next step. This is relevant only to the legacy DSM "identification" solution.
  if (status === 204) {
    return store.dispatch('processStep/continueProcess', store.rootGetters['processStep/externalId'], { root: true });
  }

  store.commit(SET_AUTHENTICATION_METHODS, data);
};

const getIdentificationOptions = async (store, { person }) => {
  store.commit(SET_LOADING, true);

  try {
    await store.dispatch('environment/fetchAllFeatures', undefined, { root: true });
    await _processAuthenticationStatus(store);
    await _getAuthenticationMethodsAndStatus(store, person);
  } catch (error) {
    store.dispatch('processStep/handleError', error, { root: true });
  } finally {
    store.commit(SET_LOADING, false);
  }
};

const resetIdentification = (store) => {
  store.commit(SET_LOADING, true);
  const externalId = store.rootGetters['processStep/externalId'];

  return LtLoanOriginationClient.resetIdentification(externalId)
    .catch(error => store.dispatch('processStep/handleError', error, { root: true }))
    .finally(() => store.commit(SET_LOADING, false));
};

const _reloadPageWithError = async errorCode => {
  await router.replace({
    path: router.currentRoute.path,
    query: {
      ...router.currentRoute.query,
      errorCode,
    },
  });
  window.location.reload();
};

const _processAuthenticationStatus = async (store) => {
  if (!store.rootGetters['environment/isPaymentAuthenticationAndSigningEnabled']) {
    return;
  }

  const { status } = await LtLoanOriginationClient.processAuthenticationStatus();
  if (status === 200) {
    await store.dispatch('processStep/continueProcess', store.rootGetters['processStep/externalId'], { root: true });
  }

  // Note: Relies on legacy DSM being used, therefore refactor this to a simple Vuex approach only when:
  // 1) DSM is removed completely (and errorCode in URL query is redundant)
  // 2) and NeoPayLT signing completed (LOVE-1836 & LOVE-1838)
  // 3) and custom UI messages have to be shown for different DSS statuses provided by CSI API.
  if (status === 205) {
    await _reloadPageWithError(DSS_ERROR.BANKLINK_PAYMENT_FAILED);
  }
};

export default {
  login,
  authenticate,
  getIdentificationOptions,
  completeLoginServiceIdentification,
  resetIdentification,
};
