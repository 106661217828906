export default {
  /**
   * allowed symbols: @ % & ( ) ; : . , _ \ " ' ! ? ~ < > | # = + - (and space symbol)
   * allowed latin letters: a b c d e f g h i j k l m n o p q r st u v w x y z (+ UPPERCASE)
   * allowed LT letters: ą č ę ė į š ų ū ž (+ UPPERCASE)
   * allowed all digits
   *
   * @param value {string}
   * @return {boolean}
   */
  validate: value => {
    return /^[@%&\s();:.,_\\/"'!?~<>|#=+\-a-zA-Z0-9ąčęėįšųūžĄČĘĖĮŠŲŪŽ]+$/.test(value);
  },
};
