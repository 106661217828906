// error codes taken from https://docs.hcaptcha.com/configuration#hcaptcha-container-configuration
const hCaptchaClientApiErrorCode = Object.freeze({
  RATE_LIMITED: 'rate-limited',
  NETWORK_ERROR: 'network-error',
  INVALID_DATA: 'invalid-data',
  CHALLENGE_ERROR: 'challenge-error',
  CHALLENGE_CLOSED: 'challenge-closed',
  CHALLENGE_EXPIRED: 'challenge-expired',
  MISSING_CAPTCHA: 'missing-captcha',
  INVALID_CAPTCHA_ID: 'invalid-captcha-id',
  INTERNAL_ERROR: 'internal-error',
});

export const ACCEPTABLE_HCAPTCHA_CLIENT_API_ERROR_CODES = Object.freeze([
  hCaptchaClientApiErrorCode.NETWORK_ERROR,
  hCaptchaClientApiErrorCode.INVALID_DATA,
]);
