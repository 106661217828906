<template>
  <bb-row
    class="perks p-x-15 m-y-15"
    justify="center"
    align="baseline"
  >
    <bb-col
      v-for="(perk, index) in perks"
      :key="index"
      col="8"
    >
      <bb-row align="start">
        <bb-col
          col="4"
          class="p-l-0"
        >
          <bb-icon
            name="ui-check-circle"
            size="18"
            :fill="color"
          />
        </bb-col>
        <bb-col
          col="20"
          class="p-x-0 m-t-5"
        >
          <div class="perks__text f-medium color-gray-90">
            {{ perk }}
          </div>
        </bb-col>
      </bb-row>
    </bb-col>
  </bb-row>
</template>

<script>
  import '@bigbank/interface-components/dist/svg/ui/check-circle';
  import { BbRow, BbCol, BbIcon } from '@bigbank/interface-components';
  import { mapGetters } from 'vuex';
  import { THEME } from '../../../constants';

  export default {
    name: 'perks',

    components: {
      BbRow,
      BbCol,
      BbIcon,
    },

    props: {
      perks: {
        type: Array,
        default: () => [],
      },
    },

    computed: {
      ...mapGetters('ui', ['theme']),
      color() {
        return this.theme === THEME.AUTOPLIUS ? 'blue' : 'green';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'node_modules/@bigbank/interface-components/dist/src/scss/variables/_typography';

  .perks {
    &__text {
      line-height: 16px;
      font-size: $tiny-font-size;
    }

    @media(max-width: 375px) {
      &__text {
        padding-left: 5px;
      }
    }

    @media(max-width: 320px) {
      &__text {
        line-height: 14px;
        font-size: $mobile-smallest-font-size;
      }
    }
  }
</style>
