<template>
  <div class="the-frame wrapper m-a p-b-20">
    <bb-container>
      <crest
        :logo="config.logo"
        :contact-phone="config.contactPhone"
      />
      <perks
        :perks="config.perks"
      />
      <indicators
        :show-calculator="showCalculator"
        @input="showCalculator = $event"
      />
    </bb-container>
    <bb-form
      id="iframe-form"
      class="iframe-form p-b-15"
      :class="{'bg-gray-10': showCalculator}"
      :loading="isLoading"
      :disabled="isDisabled"
      :error-text="$t('form.errorText')"
      :submit-text="submitText"
      :disclaimer-text="config.disclaimerText"
      static-submit
      @submit="submit"
    >
      <partner-calculator
        v-show="showCalculator"
        ref="calculator"
        @close="showCalculator = !showCalculator"
      />
      <section
        v-if="!showCalculator"
        class="applicant-details"
      >
        <bb-input
          id="firstNameField"
          v-model="applicant.firstName"
          v-validate="'required|min:2|max:35|name'"
          name="first-name"
          :disabled="!!getAuthenticatedUserData.firstName"
          :label="$t('application.applicant.firstName.label')"
          :data-vv-as="$t('application.applicant.firstName.label')"
        />
        <bb-input
          id="surnameField"
          v-model="applicant.surname"
          v-validate="'required|min:2|max:35|name'"
          name="surname"
          :disabled="!!getAuthenticatedUserData.surname"
          :label="$t('application.applicant.surname.label')"
          :data-vv-as="$t('application.applicant.surname.label')"
        />
        <bb-masked-input
          id="personalIdentityCodeField"
          v-model="applicant.personalIdentityCode"
          v-validate="'required|personalCode'"
          :disabled="isIdentifyOnline || !!getAuthenticatedUserData.personalIdentityCode"
          name="personal-identity-code"
          :label="$t('application.applicant.personalIdentityCode.label')"
          :data-vv-as="$t('application.applicant.personalIdentityCode.label')"
          :mask="personalCodeMask"
          inputmode="numeric"
          pattern="[0-9]*"
        />
        <bb-input
          id="emailField"
          v-model="applicant.email"
          v-validate="'required|email'"
          name="email"
          :label="$t('application.applicant.email.label')"
          :data-vv-as="$t('application.applicant.email.label')"
        />
        <phone-input
          :entity="applicant"
          @input="phoneNumber = $event"
        />
        <bb-select
          v-if="loanPurposeVisible"
          id="loanPurposeField"
          v-model="loanPurpose"
          v-validate="'required'"
          :selected="loanPurpose"
          :options="loanPurposeOptions"
          :help-text="$t('application.loanPurpose.helpText')"
          :label="$t('application.loanPurpose.label')"
          :data-vv-as="$t('application.loanPurpose.label')"
          name="loan-purpose"
          help-active
        />
        <div class="m-t-30">
          <bb-checkbox
            id="thirdPartyConsentField"
            v-model="thirdPartyConsent"
            v-validate="'required'"
            :data-vv-as="$t('application.thirdPartyConsent.label')"
            name="third-party-consent"
            class="nm-t-10"
          >
            <span class="f-smallest">
              {{ $t('views.partner.common.thirdPartyConsent') }}
            </span>
            <bb-link
              :to="homePageUrl"
              target="_blank"
              class="f-smallest"
            >
              {{ $t('views.partner.common.homeUrlName') }}
            </bb-link>
          </bb-checkbox>
        </div>
      </section>
      <the-captcha
        v-if="config.showCaptcha"
        ref="theCaptcha"
        @verify="submit"
        @error="onCaptchaError"
      />
    </bb-form>
  </div>
</template>

<script>
  import { BbCheckbox,
           BbContainer,
           BbForm,
           BbInput,
           BbLink,
           BbMaskedInput,
           BbSelect } from '@bigbank/interface-components';
  import Crest from './components/Crest';
  import Perks from './components/Perks';
  import Indicators from './components/Indicators';
  import PartnerCalculator from './components/PartnerCalculator';
  import PhoneInput from '@/components/PhoneInput';
  import TheCaptcha from '@/components/TheCaptcha';
  import enumsToOptionsMixin from '@/mixins/enumsToOptionsMixin';
  import personalDetailsMixin from '@/mixins/personalDetailsMixin';
  import { HOMEPAGE_URL,
           ID_DOCUMENT,
           LOAN_PURPOSE,
           LOAN_PURPOSES_FOR_REAL_ESTATE,
           MARITAL_STATUS,
           PRODUCT_TYPE,
           taskDefinitionKeys as tasks } from '@/constants';
  import { mapActions, mapGetters } from 'vuex';
  import { ACCEPTABLE_HCAPTCHA_CLIENT_API_ERROR_CODES } from '@/constants/hCaptcha';

  export default {
    name: 'the-frame',

    components: {
      BbCheckbox,
      BbContainer,
      BbForm,
      BbInput,
      BbLink,
      BbMaskedInput,
      BbSelect,
      Crest,
      PartnerCalculator,
      Indicators,
      Perks,
      PhoneInput,
      TheCaptcha,
    },

    mixins: [
      enumsToOptionsMixin,
      personalDetailsMixin,
    ],

    props: {
      config: {
        type: Object,
        default: () => ({
          logo: String,
          contactPhone: String,
          disclaimerText: String,
          showCaptcha: Boolean,
        }),
      },
    },

    data() {
      return {
        phoneNumber: {},
        showCalculator: false,
        thirdPartyConsent: undefined,
        homePageUrl: HOMEPAGE_URL,
      };
    },

    computed: {
      ...mapGetters('products', ['productType']),
      ...mapGetters('processStep', [
        'initialSource',
        'isLoading',
        'definitionKey',
      ]),
      ...mapGetters('products', ['loanPurposes']),
      ...mapGetters('auth', ['getAuthenticatedUserData']),
      maritalStatusOptions() {
        return this.enumsToOptions(MARITAL_STATUS, 'application.applicant.maritalStatus');
      },
      idDocumentOptions() {
        return this.enumsToOptions(ID_DOCUMENT, 'application.applicant.idDocument');
      },
      loanPurposeOptions() {
        const options = this.productType === PRODUCT_TYPE.RENOVATION_LOAN_LT01
          ? [LOAN_PURPOSE.RENOVATING_HOME].concat(LOAN_PURPOSES_FOR_REAL_ESTATE)
          : this.loanPurposes;

        return options?.map(apiEnum => ({
          value: apiEnum,
          text: this.$t(`application.loanPurpose.options.${apiEnum}`),
        }));
      },
      loanPurposeVisible() {
        return this.loanPurposeOptions?.length > 1;
      },
      isIdentifyOnline() {
        return this.definitionKey === tasks.IdentifyOnline;
      },
      isDisabled() {
        return !this.showCalculator && this.isLoading;
      },
      submitText() {
        return this.showCalculator ? this.$t('views.partner.common.applyCalculator') : this.$t('form.continue');
      },
    },

    async created() {
      await this.$store.dispatch('ui/updateUi', this.$route.name, { root: true });
      await this.$store.dispatch('products/initializeFromUrl', {}, { root: true });
    },

    methods: {
      ...mapActions('products', ['adjustProductDataAndApplicationMappingByLoanPurpose']),
      ...mapActions('processStep', ['createPublicApplication']),
      async onCaptchaError(errorCode) {
        if (ACCEPTABLE_HCAPTCHA_CLIENT_API_ERROR_CODES.includes(errorCode)) {
          await this.submit(null, true);
        }
      },
      async submit(hCaptchaToken, ignoreCaptcha) {
        if (this.showCalculator) {
          this.showCalculator = false;

          return this.$refs.calculator.submit();
        }

        if (this.config.showCaptcha && !hCaptchaToken && !ignoreCaptcha) {
          return this.$refs.theCaptcha.execute();
        }

        const application = {
          applicant: {
            firstName: this.applicant.firstName,
            surname: this.applicant.surname,
            personalIdentityCode: this.applicant.personalIdentityCode,
            email: this.applicant.email,
            ...this.phoneNumber,
          },
          loanPurpose: this.loanPurpose || this.loanPurposes[0],
          thirdPartyConsent: true,
          source: { source: this.initialSource },
        };

        await this.adjustProductDataAndApplicationMappingByLoanPurpose();
        this.createPublicApplication({
          application,
          hCaptchaToken,
          variables: {
            submittedFromIframe: {
              type: 'Boolean',
              value: true,
            },
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .the-frame {
    width: 380px;

    .bb-container {
      padding-left: 0;
      padding-right: 0;
    }

    .iframe-form {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  @media (max-width: 375px) {
    .the-frame {
      .bb-container {
        padding-left: 15px;
        padding-right: 15px;
      }

      .iframe-form {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
</style>

<style lang="scss">
  @import 'node_modules/@bigbank/interface-components/dist/src/scss/variables/colors';

  .the-frame {
    .bb-form {
      padding-left: 15px;
      padding-right: 15px;

      &__disclaimer-text {
        color: $blue;
        font-size: 11px;
        line-height: 16px;
      }
    }
  }
</style>
