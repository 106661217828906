<template>
  <div class="bank-link-error align-center">
    <div class=" f-color-red f-max-pro-demi-bold h4">
      {{ $t('components.bankLinkErrorHandler.title') }}
    </div>
    <div class="m-b-25 color-gray-80 f-small f-strong">
      {{ message }}
    </div>
    <bb-button
      color="blue"
      @click="$emit('cancel')"
    >
      {{ $t('components.bankLinkErrorHandler.buttonText') }}
    </bb-button>
  </div>
</template>

<script>
  import { BbButton } from '@bigbank/interface-components';
  import { DSS_ERROR } from '@/constants/Errors';

  export default {
    name: 'bank-link-error',
    components: { BbButton },
    props: {
      errorCode: {
        type: String,
        required: true,
      },
      action: {
        type: String,
        required: true,
      },
    },
    computed: {
      message() {
        if (DSS_ERROR.BANKLINK_NAME_MISMATCH === this.errorCode) {
          return this.$t(`components.bankLinkErrorHandler.${this.action}.nameMismatchMessage`);
        }

        return this.$t(`components.bankLinkErrorHandler.${this.action}.generalMessage`);
      },
    },
  };
</script>

