<template>
  <article class="loading m-t-100 align-center">
    <bb-row
      class="m-b-60"
      justify="center"
    >
      <bb-col col="auto">
        <bb-spinner />
      </bb-col>
    </bb-row>
    <div v-if="showText">
      <p class="m-b-15 small color-navy">
        {{ $t('components.loading.title') }}
      </p>
      <p class="m-b-100 small color-navy">
        {{ $t('components.loading.subTitle') }}
      </p>
    </div>
    <slot></slot>
  </article>
</template>

<script>
  import { BbRow,
           BbCol,
           BbSpinner } from '@bigbank/interface-components';

  export default {
    name: 'loading',
    components: {
      BbCol,
      BbRow,
      BbSpinner,
    },
    props: {
      showText: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>
