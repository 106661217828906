import VueI18n from 'vue-i18n';
import Vue from 'vue';
import numberFormats from './numberFormat';
import en from '../../translations/en.json';
import lt from '../../translations/lt.json';

Vue.use(VueI18n);

const messages = Object.assign({
  en,
  lt,
});

const fallbackLocale = 'lt';
const locale = window.config?.DEFAULT_LANGUAGE || fallbackLocale;

const i18n = new VueI18n({
  locale,
  messages,
  numberFormats,
  fallbackLocale,
  silentTranslationWarn: true,
});

export default i18n;
