<template>
  <bb-application-page
    :form-props="formProps"
    @submit="submit"
  >
    <bb-public-header
      slot="header"
      :title="$t('views.spouseAddress.title')"
      icon="client-contact"
    />

    <address-details @update="updateAddressAndMarketingConsent" />
  </bb-application-page>
</template>

<script>
  import { BbApplicationPage,
           BbPublicHeader } from '@bigbank/interface-components';
  import { mapActions, mapGetters } from 'vuex';
  import countiesMixin from '@/mixins/countiesMixin';
  import formMixin from '@/mixins/formMixin';
  import AddressDetails from '@/components/AddressDetails';

  export default {
    name: 'spouse-address-page',

    components: {
      AddressDetails,
      BbApplicationPage,
      BbPublicHeader,
    },

    mixins: [
      countiesMixin,
      formMixin,
    ],

    data() {
      return { address: undefined };
    },

    computed: { ...mapGetters('processStep', ['coApplicantRole']) },

    methods: {
      ...mapActions('processStep', ['updateProcessStep']),

      updateAddressAndMarketingConsent({ address }) {
        this.address = address;
      },

      submit() {
        this.updateProcessStep({ application: { [this.coApplicantRole]: { contactAddress: { ...this.address }}}});
      },
    },
  };
</script>
