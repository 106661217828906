<template>
  <div class="signing-modal">
    <bb-modal
      center-vertically
      :visible="showModal"
      :public-size="isBankLinkType ? 'l' : 'm'"
      flavor="user"
      @close="closeReset"
    >
      <bank-link-error
        v-if="bankLinkError"
        :error-code="bankLinkError"
        action="signing"
        @cancel="bankLinkError = null"
      />
      <remote-identification-error
        v-else-if="showIdentificationError"
        :status="identificationStatus"
        @cancel="cancelChosenType(); hideIdentificationError = true"
      />
      <loading v-else-if="isSigningLoading || isProcessStepLoading" />
      <whats-next v-else-if="showWhatsNextPage" />

      <signing-methods
        v-else-if="chosenType === null"
        @click="onClickMethod"
      />

      <mobile-verification
        v-else-if="isMobileType"
        @cancel="cancelChosenType"
      />

      <!--      Disabled until LOVE-1970.-->
      <!--      <bank-instructions-->
      <!--        v-else-if="isBankLinkType"-->
      <!--        :method="chosenType"-->
      <!--        @click="onClickContinueBankLink"-->
      <!--        @cancel="cancelChosenType"-->
      <!--      />-->
    </bb-modal>
  </div>
</template>

<script>
  import { BbModal } from '@bigbank/interface-components';
  import { modalMixin } from '@/mixins/modalMixin';
  import SigningMethods from './SigningMethods';
  import { mapActions, mapGetters } from 'vuex';
  import Loading from '../Loading';
  import { BANKLINK_TYPE, VERIFICATION_TYPES } from '@/constants/verification';
  import MobileVerification from './MobileVerification';
  import WhatsNext from './WhatsNext';
  import { taskDefinitionKeys, IDENTIFICATION_STATUS } from '@/constants';
  // import BankInstructions from './BankInstructions';
  import sourceMixin from '../../mixins/sourceMixin';
  import BankLinkError from '../../components/identification/BankLinkError';
  import RemoteIdentificationError from '../../components/identification/RemoteIdentificationError';
  import partnerApiMixin from '../../mixins/partnerApiMixin';

  export default {
    name: 'signing-modal',

    components: {
      BankLinkError,
      // BankInstructions,
      BbModal,
      SigningMethods,
      Loading,
      MobileVerification,
      WhatsNext,
      RemoteIdentificationError,
    },

    mixins: [
      modalMixin,
      sourceMixin,
      partnerApiMixin,
    ],

    data() {
      return {
        chosenType: null,
        VERIFICATION_TYPES,
        bankLinkError: null,
        hideIdentificationError: false,
      };
    },

    computed: {
      ...mapGetters('signing', ['identificationStatus']),
      ...mapGetters('processStep', [
        'definitionKey',
        'isApplicantIdentified',
      ]),
      ...mapGetters('signing', { isSigningLoading: 'isLoading' }),
      ...mapGetters('processStep', { isProcessStepLoading: 'isLoading' }),
      showWhatsNextPage() {
        return taskDefinitionKeys.WhatsNextPage === this.definitionKey;
      },
      showIdentificationError() {
        return !this.hideIdentificationError &&
          this.identificationStatus &&
          this.identificationStatus !== IDENTIFICATION_STATUS.COMPLETED_OK;
      },
      isMobileType() {
        return [
          VERIFICATION_TYPES.mobile_id,
          VERIFICATION_TYPES.smart_id,
        ].includes(this.chosenType);
      },
      isBankLinkType () {
        return this.chosenType?.startsWith(BANKLINK_TYPE);
      },
    },

    async created() {
      if (this.showWhatsNextPage) {
        this.open();
        setTimeout(() => this.closeReset(), 3000);

        return;
      }
      if (this.$route?.query?.errorCode) {
        this.bankLinkError = this.$route.query.errorCode;
        delete this.$route.query.errorCode;

        this.open();
      }
      if (this.identificationStatus) {
        this.open();
      }
    },

    methods: {
      ...mapActions('signing', [
        'initiateRemoteIdentification',
        'startSigning',
      ]),
      async onClickMethod(type) {
        this.chosenType = type;
        if (type === VERIFICATION_TYPES.remote_identification) {
          this.hideIdentificationError = false;

          return await this.initiateRemoteIdentification();
        }

        await this.startSigning(type);
      },
      // onClickContinueBankLink() {
      //   this.startSigning(this.chosenType);
      // },
      cancelChosenType() {
        this.chosenType = null;
      },
      closeReset() {
        if (this.showWhatsNextPage) {
          return this.shouldRedirectToPartnerApi
            ? this.navigateToPartnerApi()
            : this.navigateToSource();
        }

        this.close();
        setTimeout(() => {
          if (this.bankLinkError) {
            this.bankLinkError = null;
          }
          if (this.showIdentificationError) {
            this.hideIdentificationError = true;
          }
          this.cancelChosenType();
        }, 300);
      },
    },
  };
</script>
