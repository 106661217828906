import { contact,
  CONTACT_PHONE_AUTOPLIUS,
  CONTACT_PHONE_DIGINET,
  AUTOPLIUS_URL,
  SOURCE } from '@/constants';
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('processStep', ['source']),
    sourcePhoneNumber() {
      switch (this.source) {
        case SOURCE.AUTOPLIUS:
          return CONTACT_PHONE_AUTOPLIUS;

        case SOURCE.DIGINET:
          return CONTACT_PHONE_DIGINET;

        default:
          return contact.phone;
      }
    },
    sourceBackToButtonText() {
      return this.$t('form.backToSource', { source: this.$t(`application.source.${this.source}`) });
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    navigateToSource() {
      if (this.source === SOURCE.AUTOPLIUS) {
        this.logout();
        window.location.href = AUTOPLIUS_URL;
      } else {
        this.logout();
        window.location.href = window.config.SELF_SERVICE_URL;
      }
    },
  },
};
