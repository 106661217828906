<template>
  <bb-offer-thank-you-page
    ref="thank-you"
    :form-props="{ submitText: $t('views.thankYou.back') }"
    @submit="navigateToSource"
  >
    <template slot="header">
      <bb-hero
        class="w-100"
        bleed="all"
        :title="$t('views.thankYou.title')"
      />
    </template>

    <div class="color-gray-70 small f-gotham-medium">
      {{ thankYou }}
    </div>

    <i18n
      class="color-gray-70 m-t-20 small f-gotham-medium"
      :path="willContactPath"
      tag="p"
    >
      <bb-link
        class="f-nowrap"
        color="mint"
        :to="`tel:${ contact.phone }`"
      >
        <span>{{ contact.phone }}</span>
      </bb-link>
    </i18n>

    <i18n
      class="color-gray-70 m-t-20 small f-gotham-medium"
      :path="contacts"
      tag="p"
    >
      <bb-link
        class="f-nowrap"
        color="mint"
        :to="`tel:${ sourcePhoneNumber }`"
      >
        <span>{{ sourcePhoneNumber }}</span>
      </bb-link>

      <bb-link
        :to="`mailto:${contactEmail}`"
        class="smallest color--green"
        no-underline
      >
        {{ contactEmail }}
      </bb-link>
    </i18n>
  </bb-offer-thank-you-page>
</template>

<script>
  import { BbHero, BbLink, BbOfferThankYouPage } from '@bigbank/interface-components';
  import { mapGetters } from 'vuex';
  import { contact, PRODUCT_NAME } from '@/constants';
  import sourceMixin from '@/mixins/sourceMixin';
  import contactEmailMixin from '@/mixins/contactEmailMixin';

  export default {
    name: 'thank-you',

    components: {
      BbOfferThankYouPage,
      BbHero,
      BbLink,
    },

    mixins: [
      sourceMixin,
      contactEmailMixin,
    ],

    data() {
      return { contact };
    },

    computed: {
      ...mapGetters('processStep', [
        'definitionKey',
        'isProductHousingLoan',
      ]),
      translationPath() {
        return this.definitionKey;
      },
      thankYou() {
        if (
          this.isProductHousingLoan &&
          this.$te(`views.thankYou.${this.translationPath}.${PRODUCT_NAME.HOUSING_LOAN}.thankYou`)
        ) {
          return this.$t(`views.thankYou.${this.translationPath}.${PRODUCT_NAME.HOUSING_LOAN}.thankYou`);
        }

        if (this.$te(`views.thankYou.${this.translationPath}.thankYou`)) {
          this.$t(`views.thankYou.${this.translationPath}.thankYou`);
        }

        return this.$t(`views.thankYou.thankYou`);
      },
      willContactPath() {
        if (
          this.isProductHousingLoan &&
          this.$te(`views.thankYou.${this.translationPath}.${PRODUCT_NAME.HOUSING_LOAN}.willContact`)
        ) {
          return `views.thankYou.${this.translationPath}.${PRODUCT_NAME.HOUSING_LOAN}.willContact`;
        }

        if (this.$te(`views.thankYou.${this.translationPath}.willContact`)) {
          return `views.thankYou.${this.translationPath}.willContact`;
        }

        return 'views.thankYou.willContact';
      },
      contacts() {
        if (
          this.isProductHousingLoan &&
          this.$te(`views.thankYou.${this.translationPath}.${PRODUCT_NAME.HOUSING_LOAN}.contacts`)
        ) {
          return `views.thankYou.${this.translationPath}.${PRODUCT_NAME.HOUSING_LOAN}.contacts`;
        }

        if (this.$te(`views.thankYou.${this.translationPath}.contacts`)) {
          return `views.thankYou.${this.translationPath}.contacts`;
        }

        return 'views.thankYou.contacts';
      },
    },
  };
</script>

