import { ui } from '@bigbank/web-loco';
import { mutations } from './mutations';
import { getters } from './getters';
import { defaultUiState } from './state';

const uiModule = ui.moduleBuilder({ language: 'lt' });

export default {
  ...uiModule,
  state: {
    ...uiModule.state,
    ...defaultUiState,
  },
  getters: {
    ...uiModule.getters,
    ...getters,
  },
  mutations: {
    ...uiModule.mutations,
    ...mutations,
  },
};
