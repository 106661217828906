import { DECISION_REASONS_RULES as RULES } from '../constants';

const getExplanationsByRule = ruleName => {
  switch (ruleName) {
    case RULES.FREQUENT_POSITIVE_DECISION:
      return ['duplicateApplication'];

    case RULES.CUSTOMER_MAX_AGE:
    case RULES.MANUALLY_REJECTED:
    case RULES.CUSTOMER_IS_WANTED:
    case RULES.NO_PERSONAL_ID_MATCH:
    case RULES.INCORRECT_NAME_PROVIDED:
    case RULES.ACTIVE_NEGATIVE_DECISION:
    case RULES.CREDIT_RESTRICTED_BY_THIRD_PARTY:
    case RULES.FOREIGN_INCOME_AND_RESIDENCE:
    case RULES.SCORE_UNDER_MIN_NON_EXISTING_CUSTOMER:
    case RULES.CUSTOMER_MAX_LOAN_SUM_BELOW_LIMIT:
    case RULES.SPOUSE_CUSTOMER_MAX_LOAN_SUM_BELOW_LIMIT:
    case RULES.REFINANCING_HIGH_RISK:
    case RULES.RECENT_LOAN_FROM_BIGBANK:
      return [
        'verdict',
        'weHope',
      ];
    case RULES.ADDRESS_ABROAD:
      return [
        'verdict',
        ['noLoanForExternalResidents'],
        'weHope',
      ];
    case RULES.PERSONS_REGISTRY_ERROR:
      return [
        'verdict',
        ['restrictedData'],
        'weHope',
      ];

    case RULES.INVALID_DOCUMENT:
      return [
        'verdict',
        ['invalidIdentityDocument'],
        'weHope',
      ];

    case RULES.CUSTOMER_MIN_AGE:
      return [
        'verdict',
        'weHope',
      ];

    case RULES.CUSTOMER_HAS_WRITE_OFF_LOAN_IN_BIGBANK:
    case RULES.FORMER_CUSTOMER_COURT_BAILIFF_PROCEEDING:
    case RULES.FORMER_CUSTOMER_COLLECTION_PROCEEDING:
    case RULES.EXISTING_LOAN_IN_DEBT_COLLECTION:
    case RULES.NEGATIVE_CREDIT_STATUS_OVER_LIMIT:
      return [
        'verdict',
        ['weakFinancialPerformance'],
        'weHope',
      ];

    case RULES.FORMER_CUSTOMER_SOFT_COLLECTION_PROCEEDING:
      return [
        'verdict',
        ['weakFinancialPerformanceToBigbankWithRepeat'],
        'weHope',
      ];

    case RULES.EXISTING_LOAN_IN_DEBT:
      return [
        'verdict',
        ['weakFinancialPerformanceToBigbank'],
        'weHope',
      ];

    case RULES.REQUIRED_SPOUSE_NOT_INCLUDED:
      return [
        'verdict',
        ['fillAnewAndAddSpouse'],
        'weHope',
      ];

    case RULES.CUSTOMER_INCOME_CONFIRMATION_NEEDED:
      return [
        'verdict',
        ['invalidOrMissingDocuments'],
        'weHope',
      ];

    case RULES.REMAINING_PRINCIPAL_IN_BIGBANK_OVER_LIMIT:
    case RULES.JOINT_REMAINING_PRINCIPAL_IN_BIGBANK_OVER_LIMIT:
      return [
        'verdict',
        ['overBurdened'],
        'weHope',
      ];

    case RULES.SENIORITY_TOO_SHORT:
      return [
        'verdict',
        ['insufficientWorkExperience'],
        'weHope',
      ];

    case RULES.JOINT_NET_INCOME_UNDER_MIN:
    case RULES.NET_INCOME_UNDER_MIN_INDIVIDUAL:
      return [
        'verdict',
        ['insufficientFamilyIncome'],
        'weHope',
      ];

    case RULES.COMPANY_PERIOD_TOO_SHORT:
    case RULES.SENIORITY_SELF_EMPLOYED_TOO_SHORT:
      return [
        'verdict',
        ['periodOfActivityTooShort'],
        'weHope',
      ];

    case RULES.ACTIVE_EXTERNAL_SIGNIFICANT_DEBT_FINANCIAL:
    case RULES.ACTIVE_EXTERNAL_OVERDUE_DEBT_OVER_LIMIT:
      return [
        'verdict',
        ['irresponsibleFinancialAttitude'],
        'weHope',
      ];

    case RULES.ACTIVE_EXTERNAL_SIGNIFICANT_DEBT_NON_FINANCIAL:
      return [
        'verdict',
        ['nonFinancialDebt'],
        'weHope',
      ];

    case RULES.CUSTOMER_DTI_OVER_LIMIT:
      return [
        'verdict',
        ['incomeLiabilityRatioImbalance'],
        'weHope',
      ];

    case RULES.JOINT_DTI_OVER_LIMIT:
      return [
        'verdict',
        ['insufficientFamilyIncomeNotAdvisedForFamily'],
        'weHope',
      ];

    case RULES.JOINT_RESERVE_NEGATIVE:
    case RULES.CUSTOMER_RESERVE_NEGATIVE:
      return [
        'verdict',
        ['notAdvised'],
        'weHope',
      ];

    case RULES.APPLICATION_REQUIRES_SPOUSE:
    case RULES.HOUSEHOLD_REQUIRES_SPOUSE:
      return [
        'verdict',
        'fillAnewAndAddSpouse',
      ];

    case RULES.REFINANCING_PRODUCT_NOT_POSSIBLE:
      return [
        'verdict',
        ['impossibleToRefinance'],
        'weHope',
      ];

    case RULES.CUSTOMER_IS_DEAD:
    case RULES.CUSTOMER_IS_SANCTIONED:
    case RULES.CUSTOMER_IS_BLOCKED:
    case RULES.CUSTOMER_IS_INCAPABLE:
    case RULES.TEMPORARY_LIVING_PERMIT:
    default:
      return ['verdict'];
  }
};

export default {
  computed: {
    explanations() {
      return getExplanationsByRule(this.offerRejectedReason);
    },
    showFAQ() {
      return ![
        RULES.FREQUENT_POSITIVE_DECISION,
        RULES.TEMPORARY_LIVING_PERMIT,
      ].includes(this.offerRejectedReason);
    },
  },
};
