import { BbButton, BbModal } from '@bigbank/interface-components';

export const modalMixin = {
  components: {
    BbButton,
    BbModal,
  },
  data() {
    return { showModal: false };
  },
  methods: {
    close() {
      this.showModal = false;
    },
    open() {
      this.showModal = true;
    },
  },
};
