<template>
  <loading />
</template>

<script>
  import Loading from '../components/Loading';
  import { mapActions } from 'vuex';

  export default {
    name: 'contract-page-loader',
    components: { Loading },
    mounted() {
      this.updateProcessStep({});
    },
    methods: { ...mapActions('processStep', ['updateProcessStep']) },
  };
</script>
