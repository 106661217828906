const { getCookie } = require('@bigbank/web-loco/src/util/cookie');

export default {
  install: (Vue, { id }) => {
    if (!id) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('id', 'GTM');
    script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});` +
      `var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
      `'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');` +
      `n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);` +
      `})(window,document,'script','dataLayer','${id}');`;

    const nonce = getCookie('resourceCheck');
    if (nonce) {
      script.setAttribute('nonce', nonce);
    }

    document.body.appendChild(script);
  },
};
