<template>
  <div
    id="app"
    class="app-container"
    :class="classes"
  >
    <the-header
      v-if="!isIFrameFlavor"
    />
    <main class="p-b-20">
      <!-- force component re-render when API request finishes -->
      <!-- can't rely on route change as a task might route back to same task with new data -->
      <router-view :key="lastUpdated" />
      <session-expiration
        :button-label="$t('sessionExpiration.button')"
        :description="$t('sessionExpiration.description')"
        :title="$t('sessionExpiration.title')"
      />
    </main>
    <development-helper v-if="config.ENABLE_DEV_HELPER" />
    <the-footer
      v-if="!isIFrameFlavor"
    />
    <back-navigation-modal
      :description="$t('backButtonModal.description')"
      :positive-button-text="$t('backButtonModal.positiveButtonText')"
    />
  </div>
</template>

<script>
  import { BackNavigationModal, SessionExpiration } from '@bigbank/web-loco';
  import { mapActions, mapGetters } from 'vuex';
  import TheHeader from './components/TheHeader';
  import TheFooter from './components/TheFooter';
  import extend from './util/validators/extendValidation';
  import DevelopmentHelper from './development/DevelopmentHelper';
  import { isFeatureFlagServiceEnabled } from './config';

  export default {
    name: 'app',

    components: {
      SessionExpiration,
      TheHeader,
      TheFooter,
      DevelopmentHelper,
      BackNavigationModal,
    },

    computed: {
      ...mapGetters('processStep', ['lastUpdated']),
      ...mapGetters('ui', [
        'isIFrameFlavor',
        'backgroundColor',
        'theme',
      ]),
      classes() {
        return {
          [`bg-${this.backgroundColor}`]: this.backgroundColor,
          [this.theme]: this.theme,
        };
      },
    },

    async created() {
      extend(this.$validator);

      if (isFeatureFlagServiceEnabled()) {
        await this.initFeatures();
      }

      await this.initializeSsoSession();
      await this.appStarted();
      await this.initializeHeartbeat();
    },

    methods: {
      ...mapActions('environment', { initFeatures: 'fetchAllFeatures' }),
      ...mapActions('processStep', ['appStarted']),
      ...mapActions('auth', [
        'initializeSsoSession',
        'initializeHeartbeat',
      ]),
    },
  };
</script>

<style lang="scss">
  @import './assets/styles/autoplius';

  main {
    flex-grow: 1;
  }
  .app-container {
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
</style>
