<template>
  <div :class="containerClass">
    <bb-error-page v-bind="content" />
  </div>
</template>

<script>
  import { BbErrorPage } from '@bigbank/interface-components';
  import '@bigbank/interface-components/dist/svg/illustration/error500';
  import '@bigbank/interface-components/dist/svg/illustration/error404';
  import '@bigbank/interface-components/dist/svg/illustration/maintenance';
  import '@bigbank/interface-components/dist/svg/illustration/no-account';
  import '@bigbank/interface-components/dist/svg/illustration/session-expired';
  import { pages } from '@/constants';

  const FALLBACK_ERROR_TYPE = pages.error500;

  export default {
    name: 'error-page',

    components: { BbErrorPage },

    props: {
      errorType: {
        type: String,
        required: true,
        validator(type) {
          return [
            pages.error404,
            pages.error451,
            pages.error500,
            pages.maintenance,
            pages.noTask,
            pages.noProcess,
            pages.sessionExpired,
            pages.unauthorized,
          ].includes(type);
        },
      },
    },

    computed: {
      content() {
        return {
          class: this.errorClass,
          icon: this.icon,
          title: this.$te(`views.${this.errorType}.title`)
            ? this.$t(`views.${this.errorType}.title`)
            : this.$t(`views.${FALLBACK_ERROR_TYPE}.title`),
          description: this.$te(`views.${this.errorType}.description`)
            ? this.$t(`views.${this.errorType}.description`)
            : this.$t(`views.${FALLBACK_ERROR_TYPE}.description`),
          buttonText: this.buttonText,
          buttonPath: this.errorType !== pages.error451
            ? window.config.SELF_SERVICE_URL
            : null,
        };
      },

      buttonText() {
        if (this.errorType === pages.error451) {
          return null;
        }

        return this.$te(`views.${this.errorType}.buttonText`)
          ? this.$t(`views.${this.errorType}.buttonText`)
          : this.$t(`views.${FALLBACK_ERROR_TYPE}.buttonText`);
      },

      icon() {
        switch (this.errorType) {
          case pages.noTask:
          case pages.noProcess:
          case pages.unauthorized:
            return 'illustration-no-account';

          case pages.error451:
            return 'illustration-session-expired';

          case pages.error500:
            return 'illustration-error500';

          case pages.maintenance:
            return 'illustration-maintenance';

          case pages.sessionExpired:
            return 'illustration-session-expired';

          case pages.error404:
          default:
            return 'illustration-error404';
        }
      },

      containerClass() {
        if (this.errorType === pages.error451) {
          return 'text-center bg-white wrapper--x1 m-x-a p-x-30';
        }

        return 'text-center bg-gray-10 p-30';
      },

      errorClass() {
        if (this.errorType === pages.error451) {
          return 'p-x-30';
        }

        return '';
      },
    },
  };
</script>
