import Vue from 'vue';
import Router from 'vue-router';
import { sync } from 'vuex-router-sync';
import VeeValidate from 'vee-validate';
import '@bigbank/interface-components/dist/lib/esm.css';

import validationMessagesEn from 'vee-validate/dist/locale/en';
import validationMessagesLt from 'vee-validate/dist/locale/lt';

import { apm } from '@bigbank/web-loco';
import App from './App.vue';
import router from './router';
import store from './store/store';
import i18n from './locales/i18n';
import PortalVue from 'portal-vue';
import VueGtm from 'vue-gtm';
import GtmScriptTag from './plugins/gtm/GtmScriptTag';
import './plugins/gtag/Init';
import trackPageViews from './plugins/gtm/GtmMiddleware';

Vue.config.productionTip = false;

Vue.use(VeeValidate, {
  events: 'blur|change',
  i18nRootKey: 'validations',
  i18n,
  dictionary: {
    en: validationMessagesEn,
    lt: validationMessagesLt,
  },
});

Vue.use(PortalVue);

Vue.prototype.config = window.config;

Vue.use(Router);
sync(store, router);
apm.startApm(router, window.config.APM_ENABLED, window.config.ENVIRONMENT);

if (window.config.GTM_CLIENT_ID) {
  Vue.use(GtmScriptTag, { id: window.config.GTM_CLIENT_ID });
  Vue.use(VueGtm, {
    id: window.config.GTM_CLIENT_ID,
    enabled: true,
    loadScript: false, // disabled to override script tag generation with GtmScriptTag plugin
    debug: process.env.NODE_ENV !== 'production',
  });
  trackPageViews(Vue, router, store);
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
