<template>
  <bb-row
    class="indicators p-10 bg-gray-10"
    align="stretch"
  >
    <bb-col
      v-for="(indicator, index) in indicators"
      :key="index"
      col="7"
      class="p-t-0 p-r-5"
    >
      <label class="indicators__label d-block f-medium color-navy">
        {{ indicator.text }}
      </label>
      <div class="indicators__value f-strong color-navy">
        {{ indicator.value }}
      </div>
    </bb-col>
    <bb-col
      col="3"
      class="p-l-10 p-r-0 text-center"
    >
      <bb-button
        v-show="!showCalculator"
        name="hide-calculator"
        display="square"
        icon="ui-pencil-circle-outline"
        icon-size="36"
        color="mint"
        no-background
        @click="$emit('input', true)"
      />
      <bb-button
        v-show="showCalculator"
        name="show-calculator"
        display="square"
        icon="ui-chevron-up"
        icon-size="36"
        color="gray"
        no-background
        @click="$emit('input', false)"
      />
      <div
        v-show="!showCalculator"
        class="indicators__button-label f-medium color-navy"
      >
        {{ $t('views.partner.common.revealCalculator') }}
      </div>
    </bb-col>
  </bb-row>
</template>

<script>
  import '@bigbank/interface-components/dist/svg/ui/pencil-circle-outline';
  import '@bigbank/interface-components/dist/svg/ui/chevron-up';
  import { BbButton, BbRow, BbCol } from '@bigbank/interface-components';
  import { mapState } from 'vuex';

  export default {
    name: 'indicators',

    components: {
      BbButton,
      BbRow,
      BbCol,
    },

    props: { showCalculator: Boolean },

    computed: {
      ...mapState('products', ['product']),
      indicators() {
        return [
          {
            text: this.$t('views.partner.common.indicators.amount'),
            value: this.$n(this.product.amount, 'currency-no-decimal'),
          },
          {
            text: this.$t('views.partner.common.indicators.period'),
            value: `${this.$n(this.product.period)} ${this.$t('components.calculator.period.unit')}`,
          },
          {
            text: this.$t('views.partner.common.indicators.monthlyPayment'),
            value: this.$n(this.product.monthlyPayment, 'currency'),
          },
        ];
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'node_modules/@bigbank/interface-components/dist/src/scss/variables/_typography';

  .indicators {
    .bb-col {
      padding-left: 10px;
    }

    .bb-col:not(:last-of-type) {
      border-right: 1px dashed $gray-50;
    }

    .bb-col:first-of-type, .bb-col:last-of-type {
      padding-left: 5px;
    }

    &__label {
      line-height: 26px;
      font-size: $smallest-font-size;
    }

    &__value {
      line-height: 24px;
      font-size: $default-font-size;
    }

    &__button-label {
      line-height: 0;
      font-size: $tiny-font-size;
    }

    @media(max-width: 375px) {
      &__label {
        line-height: 16px;
        font-size: $mobile-smallest-font-size;
      }

      &__value {
        font-size: $mobile-default-font-size;
      }

      .bb-button {
        width: 35px;
        margin-top: -5px;
        margin-left: -1px;
      }

      &__button-label {
        font-size: $mobile-smallest-font-size;
      }
    }

    @media(max-width: 320px) {
      &__label {
        line-height: 14px;
        font-size: $mobile-smallest-font-size;
      }

      .bb-button {
        margin-top: -3px;
        margin-left: -4px;
      }
    }
  }
</style>
