export default {
  data() {
    const personalDetails = this.$store.getters['processStep/personalDetails'];
    const authenticatedUserData = this.$store.getters['auth/getAuthenticatedUserData'];

    return {
      personalCodeMask: '11111111111',
      ...personalDetails,
      applicant: {
        ...personalDetails.applicant,
        firstName: personalDetails.applicant.firstName ?? authenticatedUserData.firstName,
        surname: personalDetails.applicant.surname ?? authenticatedUserData.surname,
        personalIdentityCode: personalDetails.applicant.personalIdentityCode ?? authenticatedUserData.personalIdentityCode,
      },
      phoneNumber: {},
      involveCoBorrower: personalDetails.involveCoBorrower,
      campaignCode: personalDetails.campaignCode,
    };
  },
};
