<template>
  <div>
    <bb-row class="m-t-20">
      <bb-col>
        <bb-icon
          name="ui-alert-circle-outline"
          size="48"
          fill="gray-50"
        />
      </bb-col>
    </bb-row>
    <bb-row class="m-t-20">
      <bb-col>
        <div class=" f-color-red f-small">
          <p
            v-for="(paragraph, index) in messages"
            :key="index"
            :class="[{'m-t-20': index > 0}]"
          >
            {{ paragraph }}
          </p>
        </div>
      </bb-col>
    </bb-row>
  </div>
</template>

<script>
  import '@bigbank/interface-components/dist/svg/ui/alert-circle-outline';
  import { BbCol, BbIcon, BbRow } from '@bigbank/interface-components';
  import { mapGetters } from 'vuex';
  import { DSM_ERROR, DSS_ERROR, SK_ERROR } from '../../constants/Errors';

  export const DSM_ERROR_M_ID_DEFAULT = 'components.failedVerification.mobileId.default';

  const getMobileIdError = verificationError => {
    let code;
    try {
      code = verificationError.response.data.error_code || verificationError.response.data.code;
    } catch (_) {
      return DSM_ERROR_M_ID_DEFAULT;
    }

    switch (code) {
      case SK_ERROR.SK_301:
      case SK_ERROR.NOT_CLIENT:
        return 'components.failedVerification.mobileId.userNotMID';

      case SK_ERROR.SK_302:
      case SK_ERROR.SK_303:
      case SK_ERROR.SK_304:
      case SK_ERROR.SK_305:
      case SK_ERROR.SK_AUTH_REVOKED_CERTIFICATE:
      case SK_ERROR.SK_SIGN_REVOKED_CERTIFICATE:
      case SK_ERROR.INVALID_CERTIFICATE_LEVEL:
      case SK_ERROR.INACTIVE_CERTIFICATE:
      case SK_ERROR.INVALID_CERTIFICATE:
      case SK_ERROR.CERTIFICATE_PERSONAL_IDENTITY_CODE_MISMATCHING:
      case SK_ERROR.SIGNER_CERTIFICATE_REVOKED:
      case SK_ERROR.SIGNER_CERTIFICATE_UNKNOWN:
        return 'components.failedVerification.mobileId.certificateError';

      case SK_ERROR.SK_AUTH_USER_CANCEL:
        return 'components.failedVerification.mobileId.userCancelAuth';

      case SK_ERROR.SK_SIGN_USER_CANCEL:
      case SK_ERROR.USER_CANCELLED:
        return 'components.failedVerification.mobileId.userCancelSign';

      case SK_ERROR.SK_AUTH_PHONE_ABSENT:
      case SK_ERROR.SK_SIGN_PHONE_ABSENT:
      case SK_ERROR.SK_AUTH_SENDING_ERROR:
      case SK_ERROR.SK_SIGN_SENDING_ERROR:
      case SK_ERROR.SK_AUTH_SIM_ERROR:
      case SK_ERROR.SK_SIGN_SIM_ERROR:
      case SK_ERROR.DEVICE_ERROR:
        return 'components.failedVerification.mobileId.userPhoneError';

      default:
        return DSM_ERROR_M_ID_DEFAULT;
    }
  };

  export default {
    name: 'failed-verification',
    components: {
      BbCol,
      BbRow,
      BbIcon,
    },
    data() {
      return { failureType: 'signing' };
    },
    computed: {
      ...mapGetters('mobileVerification', [
        'error',
        'isSmartId',
      ]),
      mobileIdError () {
        return [this.$t(getMobileIdError(this.error))];
      },
      smartIdError() {
        if (this.isErrorPresent([
          DSM_ERROR.SMART_ID_NO_ACCOUNT,
          DSS_ERROR.SMART_ID_NOT_CLIENT,
        ])) {
          return [
            this.$t('components.failedVerification.smartId.noAccount.reason'),
            this.$t(`components.failedVerification.smartId.noAccount.action.${this.failureType}`),
          ];
        }
        if (this.isErrorPresent(
          [
            DSM_ERROR.SMART_ID_BASIC,
            DSS_ERROR.SMART_ID_INVALID_CERTIFICATE_LEVEL,
            DSS_ERROR.SMART_ID_INVALID_CERTIFICATE,
          ])) {
          return [
            this.$t(`components.failedVerification.smartId.basic.reason.${this.failureType}`),
            this.$t('components.failedVerification.smartId.basic.action'),
          ];
        }
        if (this.isErrorPresent([
          DSS_ERROR.SMART_ID_TIMEOUT,
          DSS_ERROR.SMART_ID_USER_CANCELLED,
        ])) {
          return [
            this.$t('components.failedVerification.smartId.cancel.reason'),
            this.$t('components.failedVerification.smartId.cancel.action'),
          ];
        }

        if (this.isErrorPresent([
          DSM_ERROR.SMART_ID_VIEW_APP_OR_SELF_SERVICE,
          DSM_ERROR.SMART_ID_UNSUPPORTED_CLIENT,
          DSM_ERROR.SMART_ID_MAINTENANCE,
        ])) {
          return [
            this.$t(`components.failedVerification.smartId.techIssues.reason.${this.failureType}`),
            this.$t(`components.failedVerification.smartId.techIssues.action.${this.failureType}`),
          ];
        }

        return [this.$t('components.failedVerification.smartId.default')];
      },
      messages() {
        return this.isSmartId ? this.smartIdError : this.mobileIdError;
      },
    },
    methods: {
      isErrorPresent(errors) {
        if (!this.error || !this.error.response || !this.error.response.data) {
          return false;
        }

        try {
          const dsmError = this.error.response.data.error_code;
          const bffError = this.error.response.data.errors &&
            this.error.response.data.errors[0] &&
            this.error.response.data.errors[0].code;

          return errors.includes(dsmError || bffError);
        } catch (_) {
          return false;
        }
      },
    },
  };
</script>
