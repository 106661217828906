import Vue from 'vue';
import Vuex from 'vuex';
import { loan,
  sessionExpiration,
  contract,
  offer,
  LoanOriginationClient } from '@bigbank/web-loco';
import ui from './modules/ui';
import { createSigningModule } from './modules/signing';
import identification from './modules/identification';
import mobileVerification from './modules/mobileVerification';
import { createProcessStepModule } from './modules/processStep';
import { createProductsModule } from './modules/products';
import { DEFAULT_MONTHLY_PAYMENT_DAY_UNTIL_PROVIDED,
  MIN_ALLOWED_EXTRA_OPTION_PERIOD,
  taskDefinitionKeys } from '@/constants';
import { createAuthModule } from './modules/auth';
import { createEnvironmentModule } from './modules/environment';
import login from './modules/login';
import psd2 from './modules/psd2';

Vue.use(Vuex);

const client = LoanOriginationClient;

const store = new Vuex.Store({
  modules: {
    environment: createEnvironmentModule(client),
    identification,
    login,
    mobileVerification,
    psd2,
    ui,
    auth: createAuthModule(client),
    contract: contract.moduleBuilder(client),
    loan: loan.moduleBuilder(client),
    offer: offer.moduleBuilder({
      defaultMonthlyPaymentDay: DEFAULT_MONTHLY_PAYMENT_DAY_UNTIL_PROVIDED,
      showMinPeriodForExtraOptionTasks: [
        taskDefinitionKeys.ReviewApprovedUpsale,
        taskDefinitionKeys.ReviewApprovedUpsaleFinal,
      ],
      minAllowedExtraOptionPeriod: MIN_ALLOWED_EXTRA_OPTION_PERIOD,
    }),
    processStep: createProcessStepModule(client),
    products: createProductsModule(client),
    sessionExpiration: sessionExpiration.moduleBuilder(client),
    signing: createSigningModule(client),
  },
});

client.syncStore(store);

export default store;
