import { environment } from '@bigbank/web-loco';

export const createEnvironmentModule = (client) => {
  const environmentModule = environment.moduleBuilder(client);

  return {
    ...environmentModule,
    getters: {
      ...environmentModule.getters,
      isPaymentAuthenticationAndSigningEnabled: (state, getters) => !!getters.allFeatures?.PaymentSigningWithNeopayLT,
    },
  };
};

