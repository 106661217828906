<template>
  <bb-offer-thank-you-page
    :form-props="formProps"
    @submit="submit"
  >
    <template slot="header">
      <bb-hero
        :title="$t('views.addressPage.title')"
        class="w-100"
        bleed="all"
      >
        <steps-overview />
      </bb-hero>
    </template>

    <address-details @update="updateAddressAndMarketingConsent" />
  </bb-offer-thank-you-page>
</template>

<script>
  import { BbHero,
           BbOfferThankYouPage } from '@bigbank/interface-components';
  import { mapActions } from 'vuex';
  import StepsOverview from '@/components/StepsOverview';
  import AddressDetails from '@/components/AddressDetails';
  import formMixin from '@/mixins/formMixin';

  export default {
    name: 'address-page',

    components: {
      AddressDetails,
      StepsOverview,
      BbHero,
      BbOfferThankYouPage,
    },

    mixins: [formMixin],

    data() {
      return {
        address: undefined,
        marketingConsent: undefined,
      };
    },

    methods: {
      ...mapActions('processStep', ['updateProcessStep']),

      updateAddressAndMarketingConsent({ address, marketingConsent }) {
        this.address = address;
        this.marketingConsent = marketingConsent;
      },

      submit() {
        this.updateProcessStep(
          {
            application: {
              applicant: { contactAddress: { ...this.address }},
              marketingConsent: this.marketingConsent,
            },
          },
        );
      },
    },
  };
</script>
