<template>
  <bb-col class="m-t-30 f-align-center">
    <div class="nm-x-45">
      <bb-separator dashed />
    </div>
    <bb-button
      id="backToPsd2Button"
      class="m-t-20"
      color="blue"
      display="link"
      :disabled="loading"
      :label="$t('documentUpload.backToPsd2')"
      @click="goBackToPsd2"
    />
  </bb-col>
</template>

<script>
  import { BbButton,
           BbCol,
           BbSeparator } from '@bigbank/interface-components';
  import { mapActions } from 'vuex';

  export default {
    name: 'back-to-psd2-task',

    components: {
      BbButton,
      BbCol,
      BbSeparator,
    },

    props: { loading: Boolean },

    methods: {
      ...mapActions('processStep', ['updateProcessStep']),

      goBackToPsd2 () {
        this.$emit('goBackEnabled', true);
        this.updateProcessStep({
          application: {},
          variables: {
            provideAdditionalPsd2Documents: {
              type: 'Boolean',
              value: true,
            },
          },
        });
      },
    },
  };
</script>
