'use strict';

export default class Tracking {

  constructor({
    bbcampaign,
    bbchannel,
    bbkeyword,
    bbmedium,
    bbsource,
  }) {
    this.bbcampaign = extractFirstValueIfArray(bbcampaign);
    this.bbchannel = extractFirstValueIfArray(bbchannel);
    this.bbkeyword = extractFirstValueIfArray(bbkeyword);
    this.bbmedium = extractFirstValueIfArray(bbmedium);
    this.bbsource = extractFirstValueIfArray(bbsource);
  }
}

function extractFirstValueIfArray(value) {
  return Array.isArray(value) ? value[0] : value;
}
