<template>
  <section class="the-captcha m-t-20 align-center">
    <vue-hcaptcha
      ref="hcaptcha"
      :language="config.DEFAULT_LANGUAGE"
      :sitekey="config.HCAPTCHA_SITE_KEY"
      size="invisible"
      @verify="$emit('verify', $event)"
      @error="$emit('error', $event)"
    />
  </section>
</template>

<script>
  import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

  export default {
    name: 'the-captcha',

    components: { VueHcaptcha },

    methods: {
      execute() {
        return this.$refs.hcaptcha.execute();
      },
    },
  };
</script>
