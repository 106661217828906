<template>
  <bb-container class="f-align-center">
    <bb-row>
      <bb-col>
        <bb-icon
          name="ui-check-circle"
          size="48"
          fill="green"
        />
      </bb-col>
    </bb-row>
    <bb-row class="m-t-20">
      <bb-col>
        <h4 class="f-color-navy bold">
          {{ $t('components.whatsNext.title') }}
        </h4>
      </bb-col>
    </bb-row>
    <bb-row class="m-t-10">
      <bb-col>
        <p class="f-color-gray-70 f-small">
          {{ redirectText }}
        </p>
      </bb-col>
    </bb-row>
  </bb-container>
</template>

<script>
  import '@bigbank/interface-components/dist/svg/ui/check-circle';
  import { BbCol, BbContainer, BbIcon, BbRow } from '@bigbank/interface-components';
  import partnerApiMixin from '../../mixins/partnerApiMixin';

  export default {
    name: 'whats-next',
    components: {
      BbCol,
      BbRow,
      BbIcon,
      BbContainer,
    },
    mixins: [partnerApiMixin],
    computed: {
      redirectText () {
        return this.shouldRedirectToPartnerApi
          ? this.$t('components.whatsNext.backToMerchant')
          : this.$t('components.whatsNext.text');
      },
    },
  };
</script>
