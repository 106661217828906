const formatStringAmount = function (amountStringOrNumber) {
  return typeof amountStringOrNumber === 'string' ? amountStringOrNumber.replace(',', '.') : amountStringOrNumber;
};

export default {
  name: 'amount',
  validator: {
    validate: (value) => {
      value = formatStringAmount(value);

      const rule = /^(([1-9]\d{0,4})|([0.])(?![0\d]))(((\d{0,2}){1})?((\.\d{0,2}){1})?(?!,))$/;

      if (!rule.test(value)) {
        return false;
      }

      const asDecimal = Number.parseFloat(value);

      return !Number.isNaN(asDecimal) &&
        Number.isFinite(asDecimal) &&
        asDecimal >= 0 &&
        asDecimal <= 100000;
    },
  },
};
