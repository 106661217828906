<template>
  <section class="offer-options">
    <bb-detailed-radio
      name="offer-options"
      :options="radioOptions"
      :value="selectedOption"
      @change="$emit('change', $event)"
    />
    <p
      v-if="showBottomDescription"
      class="color-gray-80 f-medium f-smallest text-center m-t-15 nm-b-15"
    >
      {{ $t('views.offer.weCanOfferExtra') }}
    </p>
  </section>
</template>

<script>
  import { BbDetailedRadio } from '@bigbank/interface-components';
  import { offer } from '@bigbank/web-loco';
  import { OFFER_OPTION_CUSTOM, OFFER_OPTION_DEFAULT, OFFER_OPTION_MAX } from '../../constants';
  import partnerApiMixin from '../../mixins/partnerApiMixin';
  import { mapGetters } from 'vuex';

  export default {
    name: 'offer-options',

    components: { BbDetailedRadio },

    mixins: [partnerApiMixin],

    props: {
      options: {
        type: Array,
        default: () => [],
      },
      selectedOption: {
        type: Number,
        default: undefined,
      },
    },

    computed: {
      ...mapGetters('processStep', [
        'offer',
        'isProductCarFinanceLease',
      ]),
      baseOptions() {
        return this.isPartnerApiHpFlow
          ? this.partnerApiOptions
          : this.options.filter(option => option.type !== OFFER_OPTION_CUSTOM);
      },
      partnerApiOptions () {
        const defaultOptions = this.options.filter(option => option.type === OFFER_OPTION_DEFAULT);
        const maxOptions = this.options.filter(option => option.type === OFFER_OPTION_MAX);

        return defaultOptions.length ? defaultOptions : maxOptions;
      },
      customOptions() {
        return this.isPartnerApiHpFlow ? [] : this.options.filter(option => option.type === OFFER_OPTION_CUSTOM);
      },
      radioOptions() {
        const baseOptions = this.baseOptions.map((option, index) => ({
          ...index === 0 && { description: this.$t('views.offer.weCanOffer') },
          ...index === 1 && { description: this.$t('views.offer.weCanOfferExtra') },
          leftColumn: {
            label: this.$n(option.amount, 'currency-decimal'),
            ...this.baseOptions.length !== 1 && { sublabel: this.$t('views.offer.multiOptionPeriod', { period: option.period }) },
          },
          rightColumn: {
            label: this.$t('views.offer.monthlyPayment'),
            amount: this.$n(option.monthlyPayment, 'currency-decimal'),
          },
          special: {
            enabled: option.type === offer.constants.OFFER_OPTION_MAX,
            label: this.$t('views.offer.maxAmountSpecial'),
          },
          ...this.baseOptions.length === 1 && {
            extra: {
              ticket: {
                left: {
                  label: this.$t('views.offer.periodLabel'),
                  content: this.$t('views.offer.multiOptionPeriod', { period: option.period }),
                },
                right: {
                  label: this.$t('views.offer.interestRateLabel'),
                  content: this.$n(option.interestRate * 0.01, 'percent-decimal'),
                },
              },
            },
          },
        }));

        const customOptions = this.customOptions.map((option) => ({
          leftColumn: {
            label: this.$t(`components.offerOptions.${option.key}.label`),
            sublabel: this.$t(`components.offerOptions.${option.key}.sublabel`),
          },
          extra: { description: this.$t(`components.offerOptions.${option.key}.description`) },
        }));

        return [
          ...baseOptions,
          ...customOptions,
        ].map((option, index) => ({
          ...option,
          value: index,
        }));
      },
      showBottomDescription() {
        return !!(this.baseOptions?.length === 1 &&
          this.isProductCarFinanceLease &&
        Number(this.offer?.customerMaxAmount) > this.baseOptions[0].amount);
      },
    },
  };
</script>
