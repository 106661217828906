import merge from 'lodash/merge';
import { ui } from '@bigbank/web-loco';
import { uiStates } from './state';

export const mutations = {
  [ui.types.UI_UPDATED]: (state, { routeName, ...optionalValues }) => {
    const ui = uiStates[routeName];
    if (!ui) console.error(`No UI state defined for ${routeName}`);
    state = merge(state, ui, { ...optionalValues });
  },
};
